/* Copyright (C) arrowthemes, http://www.gnu.org/licenses/gpl.html GNU/GPL */

/* ========================================================================
   Component: Base
 ========================================================================== */
/*
 * 1. Normalize default `font-family` and set `font-size` to support `rem` units
 * 2. Prevents iOS text size adjust after orientation change, without disabling user zoom
 * 3. Style
 */
/* @import 'https://fonts.googleapis.com/css?family=Varela'; */
/* @import 'https://fonts.googleapis.com/css?family=Exo+2:300,500,600'; */
html {
  /* 1 */
  font: 400 16px / 32px 'Varela';
  /* 2 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  /* 3 */
  background: #ffffff;
  color: #2a455d;
}
/*
 * Removes default margin.
 */
body {
  margin: 0;
}
/* Links
 ========================================================================== */
/*
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent;
}
/*
 * Improve readability of focused elements when they are also in an active/hover state.
 */
a:active,
a:hover {
  outline: 0;
}
/*
 * Style
 */
a,
.uk-link {
  color: #258bce;
  text-decoration: none;
  cursor: pointer;
}
a:hover,
.uk-link:hover {
  color: rgba(37, 139, 206, 0.8);
  text-decoration: underline;
}
/* Text-level semantics
 ========================================================================== */
/*
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}
/*
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}
/*
 * 1. Address odd `em`-unit font size rendering in all browsers.
 * 2. Consolas has a better baseline in running text compared to `Courier`
 */
:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  /* 1 */
  font-size: 16px;
  /* 2 */
  font-family: Consolas, monospace, serif;
  /* 3 */
  color: #258bce;
  white-space: nowrap;
}
:not(pre) > code i,
:not(pre) > kbd i,
:not(pre) > samp i {
  font-style: normal;
}
/*
 * Emphasize
 */
em {
  color: #dd0055;
}
/*
 * Insert
 */
ins {
  background: #ffffaa;
  color: #444444;
  text-decoration: none;
}
/*
 * Mark
 * Note: Addresses styling not present in IE 8/9.
 */
mark {
  background: #ffffaa;
  color: #444444;
}
/*
 * Quote
 */
q {
  font-style: italic;
}
/*
 * Addresses inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}
/*
 * Prevents `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
/* Embedded content
 ========================================================================== */
/*
 * Remove the gap between embedded content and the bottom of their containers.
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}
/*
 * Responsiveness
 * 1. Sets a maximum width relative to the parent and auto scales the height
 * 2. Corrects `max-width` behavior if padding and border are used
 */
audio,
canvas,
img,
svg,
video {
  /* 1 */
  max-width: 100%;
  height: auto;
  /* 2 */
  box-sizing: border-box;
}
/*
 * Preserve original dimensions
 */
.uk-img-preserve,
.uk-img-preserve audio,
.uk-img-preserve canvas,
.uk-img-preserve img,
.uk-img-preserve svg,
.uk-img-preserve video {
  max-width: none;
}
/*
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0;
}
/*
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}
/* Block elements
 ========================================================================== */
/*
 * Reset margin
 */
blockquote,
figure {
  margin: 0;
}
/*
 * Margins
 */
p,
ul,
ol,
dl,
blockquote,
pre,
address,
fieldset,
figure {
  margin: 0 0 20px 0;
}
* + p,
* + ul,
* + ol,
* + dl,
* + blockquote,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 20px;
}
/* Headings
 ========================================================================== */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 20px 0;
  font-family: 'Exo 2';
  font-weight: 500;
  color: #2a455d;
  text-transform: none;
}
/*
 * Margins
 */
* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 30px;
}
/*
 * Sizes
 */
h1,
.uk-h1 {
  font-size: 42px;
  line-height: 48px;
}
h2,
.uk-h2 {
  font-size: 32px;
  line-height: 36px;
}
h3,
.uk-h3 {
  font-size: 24px;
  line-height: 32px;
}
h4,
.uk-h4 {
  font-size: 18px;
  line-height: 32px;
}
h5,
.uk-h5 {
  font-size: 16px;
  line-height: 22px;
}
h6,
.uk-h6 {
  font-size: 14px;
  line-height: 18px;
}
/* Lists
 ========================================================================== */
ul,
ol {
  padding-left: 30px;
}
/*
 * Reset margin for nested lists
 */
ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0;
}
/* Description lists
 ========================================================================== */
dt {
  font-weight: bold;
}
dd {
  margin-left: 0;
}
/* Horizontal rules
 ========================================================================== */
/*
 * 1. Address differences between Firefox and other browsers.
 * 2. Style
 */
hr {
  /* 1 */
  box-sizing: content-box;
  height: 0;
  /* 2 */
  margin: 20px 0;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Address
 ========================================================================== */
address {
  font-style: normal;
}
/* Blockquotes
 ========================================================================== */
blockquote {
  padding-left: 15px;
  border-left: 5px solid #eef4ff;
  font-size: 32px;
  line-height: 48px;
  font-style: italic;
}
/* Preformatted text
 ========================================================================== */
/*
 * 1. Contain overflow in all browsers.
 */
pre {
  padding: 10px;
  background: rgba(0, 0, 0, 0);
  font: 16px / 30px Consolas, monospace, serif;
  color: rgba(42, 69, 93, 0.6);
  -moz-tab-size: 4;
  tab-size: 4;
  /* 1 */
  overflow: auto;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  white-space: pre-wrap;
  word-break: break-word;
  box-shadow: inset 4px 0 rgba(0, 0, 0, 0.05);
}
pre i {
  font-style: normal;
}
/* Selection pseudo-element
 ========================================================================== */
::-moz-selection {
  background: rgba(37, 139, 206, 0.75);
  color: #ffffff;
  text-shadow: none;
}
::selection {
  background: rgba(37, 139, 206, 0.75);
  color: #ffffff;
  text-shadow: none;
}
/* HTML5 elements
 ========================================================================== */
/*
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block;
}
/*
 * Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}
/*
 * Prevent displaying `audio` without controls in Chrome, Safari and Opera
 */
audio:not([controls]) {
  display: none;
}
/*
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none;
}
/* Iframe
 ========================================================================== */
iframe {
  border: 0;
}
/* Fix viewport for IE10 snap mode
 ========================================================================== */
@media screen and (max-width: 400px) {
  @-ms-viewport {
    width: device-width;
  }
}
blockquote {
  font-family: 'Exo 2';
  font-style: normal;
  border: 0 none;
  padding-left: 0;
  position: relative;
  margin: 0;
}
blockquote > p {
  line-height: 48px;
  font-weight: 300;
  letter-spacing: -0.5px;
  font-size: 24px;
}
blockquote > p strong {
  font-weight: normal;
  color: #258bce;
}
blockquote small {
  color: #a8aeb6;
}
blockquote .author,
blockquote .jobtitle {
  font-weight: normal;
  line-height: 38px;
  display: block;
}
blockquote .author {
  font-size: 24px;
  margin-top: 15px;
}
blockquote .author:empty {
  display: none;
}
blockquote .jobtitle {
  font-size: 18px;
}
.tm-quote {
  line-height: 48px;
}
.tm-testimonial-avatar {
  border: 1px solid #258bce;
  box-shadow: 0px 4px 0 #258bce;
  border-width: 1px 1px 0 1px;
  border-top-color: #dadada;
  border-right-color: #dadada;
  border-radius: 4px;
  padding: 3px;
}
.tm-testimonial strong {
  font-weight: normal;
  color: #258bce;
}
.tm-testimonial .author,
.tm-testimonial .jobtitle {
  padding-left: 80px;
}
.quote-icon {
  font-size: 126px;
  position: absolute;
  left: 100px;
  bottom: 30px;
  color: rgba(42, 69, 93, 0.05);
}
@media (max-width: 900px) {
  blockquote p {
    font-size: 20px;
    line-height: 2;
  }
  .tm-testimonial .author,
  .tm-testimonial .jobtitle {
    padding-left: 0;
  }
}
hr {
  border-color: rgba(37, 139, 206, 0.3);
  border-style: dashed;
}
.tooltip hr {
  display: none;
}
/* ========================================================================
   Component: Grid
 ========================================================================== */
/*
 * 1. Makes grid more robust so that it can be used with other block elements like lists
 */
.uk-grid {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
 * DEPRECATED
 * Micro clearfix
 * Can't use `table` because it creates a 1px gap when it becomes a flex item, only in Webkit
 */
.uk-grid:before,
.uk-grid:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-grid:after {
  clear: both;
}
/*
 * Grid cell
 * 1. Space is allocated solely based on content dimensions
 * 2. Makes grid more robust so that it can be used with other block elements
 * 3. DEPRECATED Using `float` to support IE9
 */
.uk-grid > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  /* 2 */
  margin: 0;
  /* 3 */
  float: left;
}
/*
 * Remove margin from the last-child
 */
.uk-grid > * > :last-child {
  margin-bottom: 0;
}
/* Grid gutter
 ========================================================================== */
/*
 * Default gutter
 */
/* Horizontal */
.uk-grid {
  margin-left: -30px;
}
.uk-grid > * {
  padding-left: 30px;
}
/* Vertical */
.uk-grid + .uk-grid,
.uk-grid-margin,
.uk-grid > * > .uk-panel + .uk-panel {
  margin-top: 30px;
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  /* Horizontal */
  .uk-grid {
    margin-left: -40px;
  }
  .uk-grid > * {
    padding-left: 40px;
  }
  /* Vertical */
  .uk-grid + .uk-grid,
  .uk-grid-margin,
  .uk-grid > * > .uk-panel + .uk-panel {
    margin-top: 40px;
  }
}
/*
 * Collapse gutter
 */
/* Horizontal */
.uk-grid-collapse {
  margin-left: 0;
}
.uk-grid-collapse > * {
  padding-left: 0;
}
/* Vertical */
.uk-grid-collapse + .uk-grid-collapse,
.uk-grid-collapse > .uk-grid-margin,
.uk-grid-collapse > * > .uk-panel + .uk-panel {
  margin-top: 0;
}
/*
 * Small gutter
 */
/* Horizontal */
.uk-grid-small {
  margin-left: -10px;
}
.uk-grid-small > * {
  padding-left: 10px;
}
/* Vertical */
.uk-grid-small + .uk-grid-small,
.uk-grid-small > .uk-grid-margin,
.uk-grid-small > * > .uk-panel + .uk-panel {
  margin-top: 10px;
}
/*
 * Medium gutter
 */
/* Horizontal */
.uk-grid-medium {
  margin-left: -30px;
}
.uk-grid-medium > * {
  padding-left: 30px;
}
/* Vertical */
.uk-grid-medium + .uk-grid-medium,
.uk-grid-medium > .uk-grid-margin,
.uk-grid-medium > * > .uk-panel + .uk-panel {
  margin-top: 30px;
}
/*
 * Large gutter
 */
/* Large screen and bigger */
@media (min-width: 960px) {
  /* Horizontal */
  .uk-grid-large {
    margin-left: -40px;
  }
  .uk-grid-large > * {
    padding-left: 40px;
  }
  /* Vertical */
  .uk-grid-large + .uk-grid-large,
  .uk-grid-large-margin,
  .uk-grid-large > * > .uk-panel + .uk-panel {
    margin-top: 40px;
  }
}
/* Extra Large screens */
@media (min-width: 1220px) {
  /* Horizontal */
  .uk-grid-large {
    margin-left: -50px;
  }
  .uk-grid-large > * {
    padding-left: 50px;
  }
  /* Vertical */
  .uk-grid-large + .uk-grid-large,
  .uk-grid-large-margin,
  .uk-grid-large > * > .uk-panel + .uk-panel {
    margin-top: 50px;
  }
}
/* Modifier: `uk-grid-divider`
 ========================================================================== */
/*
 * Horizontal divider
 * Only works with the default gutter. Does not work with gutter collapse, small or large.
 * Does not work with `uk-push-*`, `uk-pull-*` and not if the columns float into the next row.
 */
.uk-grid-divider:not(:empty) {
  margin-left: -30px;
  margin-right: -30px;
}
.uk-grid-divider > * {
  padding-left: 30px;
  padding-right: 30px;
}
.uk-grid-divider > [class*='uk-width-1-']:not(.uk-width-1-1):nth-child(n+2),
.uk-grid-divider > [class*='uk-width-2-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-3-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-4-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-5-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-6-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-7-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-8-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-9-']:nth-child(n+2) {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-grid-divider > [class*='uk-width-medium-']:not(.uk-width-medium-1-1):nth-child(n+2) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-grid-divider > [class*='uk-width-large-']:not(.uk-width-large-1-1):nth-child(n+2) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  /*
     * Large gutter
     */
  .uk-grid-divider:not(:empty) {
    margin-left: -40px;
    margin-right: -40px;
  }
  .uk-grid-divider > * {
    padding-left: 40px;
    padding-right: 40px;
  }
  .uk-grid-divider:empty {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
/*
 * Vertical divider
 */
.uk-grid-divider:empty {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Match panels in grids
 ========================================================================== */
/*
 * 1. Behave like a block element
 */
.uk-grid-match > * {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  /* 1 */
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uk-grid-match > * > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  box-sizing: border-box;
  width: 100%;
}
/* Even grid cell widths
 ========================================================================== */
[class*='uk-grid-width'] > * {
  box-sizing: border-box;
  width: 100%;
}
.uk-grid-width-1-2 > * {
  width: 50%;
}
.uk-grid-width-1-3 > * {
  width: 33.333%;
}
.uk-grid-width-1-4 > * {
  width: 25%;
}
.uk-grid-width-1-5 > * {
  width: 20%;
}
.uk-grid-width-1-6 > * {
  width: 16.666%;
}
.uk-grid-width-1-10 > * {
  width: 10%;
}
.uk-grid-width-auto > * {
  width: auto;
}
/* Phone landscape and bigger */
@media (min-width: 480px) {
  .uk-grid-width-small-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-small-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-small-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-small-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-small-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-small-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-small-1-10 > * {
    width: 10%;
  }
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-grid-width-medium-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-medium-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-medium-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-medium-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-medium-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-medium-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-medium-1-10 > * {
    width: 10%;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-grid-width-large-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-large-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-large-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-large-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-large-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-large-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-large-1-10 > * {
    width: 10%;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-grid-width-xlarge-1-1 > * {
    width: 100%;
  }
  .uk-grid-width-xlarge-1-2 > * {
    width: 50%;
  }
  .uk-grid-width-xlarge-1-3 > * {
    width: 33.333%;
  }
  .uk-grid-width-xlarge-1-4 > * {
    width: 25%;
  }
  .uk-grid-width-xlarge-1-5 > * {
    width: 20%;
  }
  .uk-grid-width-xlarge-1-6 > * {
    width: 16.666%;
  }
  .uk-grid-width-xlarge-1-10 > * {
    width: 10%;
  }
}
/* Sub-objects: `uk-width-*`
 ========================================================================== */
[class*='uk-width'] {
  box-sizing: border-box;
  width: 100%;
}
/*
 * Widths
 */
/* Whole */
.uk-width-1-1 {
  width: 100%;
}
/* Halves */
.uk-width-1-2,
.uk-width-2-4,
.uk-width-3-6,
.uk-width-5-10 {
  width: 50%;
}
/* Thirds */
.uk-width-1-3,
.uk-width-2-6 {
  width: 33.333%;
}
.uk-width-2-3,
.uk-width-4-6 {
  width: 66.666%;
}
/* Quarters */
.uk-width-1-4 {
  width: 25%;
}
.uk-width-3-4 {
  width: 75%;
}
/* Fifths */
.uk-width-1-5,
.uk-width-2-10 {
  width: 20%;
}
.uk-width-2-5,
.uk-width-4-10 {
  width: 40%;
}
.uk-width-3-5,
.uk-width-6-10 {
  width: 60%;
}
.uk-width-4-5,
.uk-width-8-10 {
  width: 80%;
}
/* Sixths */
.uk-width-1-6 {
  width: 16.666%;
}
.uk-width-5-6 {
  width: 83.333%;
}
/* Tenths */
.uk-width-1-10 {
  width: 10%;
}
.uk-width-3-10 {
  width: 30%;
}
.uk-width-7-10 {
  width: 70%;
}
.uk-width-9-10 {
  width: 90%;
}
/* Phone landscape and bigger */
@media (min-width: 480px) {
  /* Whole */
  .uk-width-small-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-small-1-2,
  .uk-width-small-2-4,
  .uk-width-small-3-6,
  .uk-width-small-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-small-1-3,
  .uk-width-small-2-6 {
    width: 33.333%;
  }
  .uk-width-small-2-3,
  .uk-width-small-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-small-1-4 {
    width: 25%;
  }
  .uk-width-small-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-small-1-5,
  .uk-width-small-2-10 {
    width: 20%;
  }
  .uk-width-small-2-5,
  .uk-width-small-4-10 {
    width: 40%;
  }
  .uk-width-small-3-5,
  .uk-width-small-6-10 {
    width: 60%;
  }
  .uk-width-small-4-5,
  .uk-width-small-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-small-1-6 {
    width: 16.666%;
  }
  .uk-width-small-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-small-1-10 {
    width: 10%;
  }
  .uk-width-small-3-10 {
    width: 30%;
  }
  .uk-width-small-7-10 {
    width: 70%;
  }
  .uk-width-small-9-10 {
    width: 90%;
  }
}
/* Tablet and bigger */
@media (min-width: 900px) {
  /* Whole */
  .uk-width-medium-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-medium-1-2,
  .uk-width-medium-2-4,
  .uk-width-medium-3-6,
  .uk-width-medium-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-medium-1-3,
  .uk-width-medium-2-6 {
    width: 33.333%;
  }
  .uk-width-medium-2-3,
  .uk-width-medium-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-medium-1-4 {
    width: 25%;
  }
  .uk-width-medium-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-medium-1-5,
  .uk-width-medium-2-10 {
    width: 20%;
  }
  .uk-width-medium-2-5,
  .uk-width-medium-4-10 {
    width: 40%;
  }
  .uk-width-medium-3-5,
  .uk-width-medium-6-10 {
    width: 60%;
  }
  .uk-width-medium-4-5,
  .uk-width-medium-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-medium-1-6 {
    width: 16.666%;
  }
  .uk-width-medium-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-medium-1-10 {
    width: 10%;
  }
  .uk-width-medium-3-10 {
    width: 30%;
  }
  .uk-width-medium-7-10 {
    width: 70%;
  }
  .uk-width-medium-9-10 {
    width: 90%;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  /* Whole */
  .uk-width-large-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-large-1-2,
  .uk-width-large-2-4,
  .uk-width-large-3-6,
  .uk-width-large-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-large-1-3,
  .uk-width-large-2-6 {
    width: 33.333%;
  }
  .uk-width-large-2-3,
  .uk-width-large-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-large-1-4 {
    width: 25%;
  }
  .uk-width-large-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-large-1-5,
  .uk-width-large-2-10 {
    width: 20%;
  }
  .uk-width-large-2-5,
  .uk-width-large-4-10 {
    width: 40%;
  }
  .uk-width-large-3-5,
  .uk-width-large-6-10 {
    width: 60%;
  }
  .uk-width-large-4-5,
  .uk-width-large-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-large-1-6 {
    width: 16.666%;
  }
  .uk-width-large-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-large-1-10 {
    width: 10%;
  }
  .uk-width-large-3-10 {
    width: 30%;
  }
  .uk-width-large-7-10 {
    width: 70%;
  }
  .uk-width-large-9-10 {
    width: 90%;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  /* Whole */
  .uk-width-xlarge-1-1 {
    width: 100%;
  }
  /* Halves */
  .uk-width-xlarge-1-2,
  .uk-width-xlarge-2-4,
  .uk-width-xlarge-3-6,
  .uk-width-xlarge-5-10 {
    width: 50%;
  }
  /* Thirds */
  .uk-width-xlarge-1-3,
  .uk-width-xlarge-2-6 {
    width: 33.333%;
  }
  .uk-width-xlarge-2-3,
  .uk-width-xlarge-4-6 {
    width: 66.666%;
  }
  /* Quarters */
  .uk-width-xlarge-1-4 {
    width: 25%;
  }
  .uk-width-xlarge-3-4 {
    width: 75%;
  }
  /* Fifths */
  .uk-width-xlarge-1-5,
  .uk-width-xlarge-2-10 {
    width: 20%;
  }
  .uk-width-xlarge-2-5,
  .uk-width-xlarge-4-10 {
    width: 40%;
  }
  .uk-width-xlarge-3-5,
  .uk-width-xlarge-6-10 {
    width: 60%;
  }
  .uk-width-xlarge-4-5,
  .uk-width-xlarge-8-10 {
    width: 80%;
  }
  /* Sixths */
  .uk-width-xlarge-1-6 {
    width: 16.666%;
  }
  .uk-width-xlarge-5-6 {
    width: 83.333%;
  }
  /* Tenths */
  .uk-width-xlarge-1-10 {
    width: 10%;
  }
  .uk-width-xlarge-3-10 {
    width: 30%;
  }
  .uk-width-xlarge-7-10 {
    width: 70%;
  }
  .uk-width-xlarge-9-10 {
    width: 90%;
  }
}
/* Sub-object: `uk-push-*` and `uk-pull-*`
 ========================================================================== */
/*
 * Source ordering
 * Works only with `uk-width-medium-*`
 */
/* Tablet and bigger */
@media (min-width: 900px) {
  [class*='uk-push-'],
  [class*='uk-pull-'] {
    position: relative;
  }
  /*
     * Push
     */
  /* Halves */
  .uk-push-1-2,
  .uk-push-2-4,
  .uk-push-3-6,
  .uk-push-5-10 {
    left: 50%;
  }
  /* Thirds */
  .uk-push-1-3,
  .uk-push-2-6 {
    left: 33.333%;
  }
  .uk-push-2-3,
  .uk-push-4-6 {
    left: 66.666%;
  }
  /* Quarters */
  .uk-push-1-4 {
    left: 25%;
  }
  .uk-push-3-4 {
    left: 75%;
  }
  /* Fifths */
  .uk-push-1-5,
  .uk-push-2-10 {
    left: 20%;
  }
  .uk-push-2-5,
  .uk-push-4-10 {
    left: 40%;
  }
  .uk-push-3-5,
  .uk-push-6-10 {
    left: 60%;
  }
  .uk-push-4-5,
  .uk-push-8-10 {
    left: 80%;
  }
  /* Sixths */
  .uk-push-1-6 {
    left: 16.666%;
  }
  .uk-push-5-6 {
    left: 83.333%;
  }
  /* Tenths */
  .uk-push-1-10 {
    left: 10%;
  }
  .uk-push-3-10 {
    left: 30%;
  }
  .uk-push-7-10 {
    left: 70%;
  }
  .uk-push-9-10 {
    left: 90%;
  }
  /*
     * Pull
     */
  /* Halves */
  .uk-pull-1-2,
  .uk-pull-2-4,
  .uk-pull-3-6,
  .uk-pull-5-10 {
    left: -50%;
  }
  /* Thirds */
  .uk-pull-1-3,
  .uk-pull-2-6 {
    left: -33.333%;
  }
  .uk-pull-2-3,
  .uk-pull-4-6 {
    left: -66.666%;
  }
  /* Quarters */
  .uk-pull-1-4 {
    left: -25%;
  }
  .uk-pull-3-4 {
    left: -75%;
  }
  /* Fifths */
  .uk-pull-1-5,
  .uk-pull-2-10 {
    left: -20%;
  }
  .uk-pull-2-5,
  .uk-pull-4-10 {
    left: -40%;
  }
  .uk-pull-3-5,
  .uk-pull-6-10 {
    left: -60%;
  }
  .uk-pull-4-5,
  .uk-pull-8-10 {
    left: -80%;
  }
  /* Sixths */
  .uk-pull-1-6 {
    left: -16.666%;
  }
  .uk-pull-5-6 {
    left: -83.333%;
  }
  /* Tenths */
  .uk-pull-1-10 {
    left: -10%;
  }
  .uk-pull-3-10 {
    left: -30%;
  }
  .uk-pull-7-10 {
    left: -70%;
  }
  .uk-pull-9-10 {
    left: -90%;
  }
}
.uk-grid-divider:empty {
  border-top: 1px dashed rgba(0, 0, 0, 0.1);
}
/*
	 * Horizontal divider
	 */
.uk-grid-divider > [class*='uk-width-1-']:not(.uk-width-1-1):nth-child(n+2),
.uk-grid-divider > [class*='uk-width-2-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-3-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-4-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-5-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-6-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-7-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-8-']:nth-child(n+2),
.uk-grid-divider > [class*='uk-width-9-']:nth-child(n+2) {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.05);
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-grid-divider > [class*='uk-width-medium-']:not(.uk-width-medium-1-1):nth-child(n+2) {
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.05);
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-grid-divider > [class*='uk-width-large-']:not(.uk-width-large-1-1):nth-child(n+2) {
    box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.05);
  }
}
/* ========================================================================
   Component: Panel
 ========================================================================== */
/*
 * 1. Needed for `a` elements
 * 2. Create position context for badges
 */
.uk-panel {
  /* 1 */
  display: block;
  /* 2 */
  position: relative;
}
/*
 * Allow panels to be anchors
 */
.uk-panel,
.uk-panel:hover {
  text-decoration: none;
}
/*
 * Micro clearfix to make panels more robust
 */
.uk-panel:before,
.uk-panel:after {
  content: "";
  display: table;
}
.uk-panel:after {
  clear: both;
}
/*
 * Remove margin from the last-child if not `uk-widget-title`
 */
.uk-panel > :not(.uk-panel-title):last-child {
  margin-bottom: 0;
}
/* Sub-object: `uk-panel-title`
 ========================================================================== */
.uk-panel-title {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
  font-weight: normal;
  color: #2a455d;
  padding-bottom: 15px;
  position: relative;
  font-weight: 500;
  letter-spacing: -0.5px;
  text-transform: none;
}
/* Sub-object: `uk-panel-badge`
 ========================================================================== */
.uk-panel-badge {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
/* Sub-object: `uk-panel-teaser`
 ========================================================================== */
.uk-panel-teaser {
  margin-bottom: 20px;
}
/* Sub-object: `uk-panel-body`
 ========================================================================== */
.uk-panel-body {
  padding: 30px;
}
/* Modifier: `uk-panel-box`
 ========================================================================== */
.uk-panel-box {
  padding: 30px;
  background: #ffffff;
  color: #2a455d;
}
.uk-panel-box.uk-panel-box-primary,
.uk-panel-box.uk-panel-box-secondary {
  border: 1px solid rgba(0, 0, 0, 0.05);
}
.uk-panel-box .uk-panel-badge {
  padding: 0 8px;
}
.uk-panel-box .uk-nav-side {
  margin: 0 -30px;
}
.uk-panel-box .uk-nav-side > li > a,
.uk-panel-box .uk-nav-side .uk-nav-header {
  padding-left: 30px;
  padding-right: 30px;
}
.uk-panel-box .uk-nav-side .uk-nav-divider {
  margin: 0 30px;
}
.uk-panel-box .uk-nav-side ul.uk-nav-sub {
  padding-left: 30px;
}
.uk-panel-box-hover:hover {
  color: #2a455d;
}
.uk-panel-box .uk-panel-title {
  color: #2a455d;
}
.uk-panel-box .uk-panel-badge {
  top: 20px;
  right: 20px;
}
.uk-panel-box > .uk-panel-teaser {
  margin-top: -30px;
  margin-left: -30px;
  margin-right: -30px;
}
/*
 * Nav in panel
 */
.uk-panel-box > .uk-nav-side {
  margin: 0 -30px;
}
/*
 * Sub-modifier: `uk-panel-box-primary`
 */
.uk-panel-box-primary {
  color: #ffffff;
  background-color: #258bce;
  /*
     * Sub-object: `uk-nav-header`
     */
  /*
     * Sub-object: `uk-nav-divider`
     */
  /* Placeholder */
  /* modal */
}
.uk-panel-box-primary > a:not([class]),
.uk-panel-box-primary > :not([class*='uk-nav']) a:not([class]) {
  color: #223148;
}
.uk-panel-box-primary > a:not([class]):hover,
.uk-panel-box-primary > :not([class*='uk-nav']) a:not([class]):hover {
  color: #2a3d59;
}
.uk-panel-box-primary .uk-nav-autocomplete > li > a {
  color: #258bce !important;
}
.uk-panel-box-primary .uk-nav-autocomplete > li.uk-active > a {
  color: #ffffff !important;
}
.uk-panel-box-primary > .uk-panel-title,
.uk-panel-box-primary > .uk-module-title {
  color: #ffffff;
}
.uk-panel-box-primary > .uk-panel-title:after,
.uk-panel-box-primary > .uk-module-title:after {
  border-bottom-color: #ffffff;
}
.uk-panel-box-primary > .uk-module-title-alt {
  color: #ffffff;
}
.uk-panel-box-primary > .uk-module-title-alt:before,
.uk-panel-box-primary > .uk-module-title-alt:after {
  background-color: #ffffff;
}
.uk-panel-box-primary.tm-darker {
  background-color: #1f83c4;
}
.uk-panel-box-primary.tm-lighter {
  background-color: #2694dc;
}
.uk-panel-box-primary .uk-nav-side > li > a {
  border-color: rgba(0, 0, 0, 0.05);
  color: #ffffff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.uk-panel-box-primary .uk-nav-side ul a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.uk-panel-box-primary .uk-nav-side > li:first-child > a,
.uk-panel-box-primary .uk-nav-divider + li > a {
  border: none;
  box-shadow: none;
}
.uk-panel-box-primary .uk-subnav.uk-subnav-list > li > a {
  color: #223148;
}
.uk-panel-box-primary .uk-nav-side .uk-nav-header {
  color: #ffffff;
}
.uk-panel-box-primary .uk-nav-divider {
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.uk-panel-box-primary .uk-nav-side ul.uk-nav-sub {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}
.uk-panel-box-primary .uk-nav-side ul a {
  color: #ffffff;
}
.uk-panel-box-primary .uk-nav-side > li > a:hover,
.uk-panel-box-primary .uk-nav-side > li > a:focus {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.uk-panel-box-primary .uk-nav-side > li.uk-active > a {
  color: #ffffff;
}
.uk-panel-box-primary hr {
  height: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.uk-panel-box-primary h1,
.uk-panel-box-primary h2,
.uk-panel-box-primary h3,
.uk-panel-box-primary h4,
.uk-panel-box-primary h5,
.uk-panel-box-primary h6 {
  color: #ffffff;
}
.uk-panel-box-primary .uk-form select,
.uk-panel-box-primary .uk-form textarea,
.uk-panel-box-primary .uk-form input:not([type]),
.uk-panel-box-primary .uk-form input[type="text"],
.uk-panel-box-primary .uk-form input[type="password"],
.uk-panel-box-primary .uk-form input[type="email"] {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  /* Focus state */
}
.uk-panel-box-primary .uk-form select:focus,
.uk-panel-box-primary .uk-form textarea:focus,
.uk-panel-box-primary .uk-form input:not([type]):focus,
.uk-panel-box-primary .uk-form input[type="text"]:focus,
.uk-panel-box-primary .uk-form input[type="password"]:focus,
.uk-panel-box-primary .uk-form input[type="email"]:focus {
  border-color: #195f8d;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.uk-panel-box-primary .uk-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-panel-box-primary .uk-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-panel-box-primary .uk-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-panel-box-primary.uk-modal-dialog {
  background-color: #258bce;
}
.uk-panel-box-primary.uk-modal-dialog [class*='uk-icon-'] {
  color: #ffffff;
}
.uk-panel-box-primary .uk-panel-badge {
  color: #ffffff;
  border-color: #ffffff;
}
.uk-panel-box-primary-hover:hover {
  color: #ffffff;
}
.uk-panel-box-primary .uk-panel-title {
  color: #ffffff;
}
/*
 * Sub-modifier: `uk-panel-box-secondary`
 */
.uk-panel-box-secondary {
  color: #ffffff;
  background-color: #223148;
}
.uk-panel-box-secondary > a:not([class]),
.uk-panel-box-secondary > :not([class*='uk-nav']) a:not([class]) {
  color: #3298da;
}
.uk-panel-box-secondary > a:not([class]):hover,
.uk-panel-box-secondary > :not([class*='uk-nav']) a:not([class]):hover {
  color: rgba(50, 152, 218, 0.8);
}
.uk-panel-box-secondary .uk-nav-autocomplete > li > a {
  color: #3298da !important;
}
.uk-panel-box-secondary .uk-nav-autocomplete > li.uk-active > a {
  color: #ffffff !important;
}
.uk-panel-box-secondary > .uk-panel-title,
.uk-panel-box-secondary > .uk-module-title {
  color: #ffffff;
}
.uk-panel-box-secondary > .uk-panel-title:after,
.uk-panel-box-secondary > .uk-module-title:after {
  border-bottom-color: #ffffff;
}
.uk-panel-box-secondary > .uk-module-title-alt {
  color: #ffffff;
}
.uk-panel-box-secondary > .uk-module-title-alt:before,
.uk-panel-box-secondary > .uk-module-title-alt:after {
  background-color: #ffffff;
}
.uk-panel-box-secondary.tm-darker {
  background-color: #141f30;
}
.uk-panel-box-secondary.tm-lighter {
  background-color: #2c4264;
}
.uk-panel-box-secondary .uk-subnav.uk-subnav-list > li > a {
  color: #3298da;
}
.uk-panel-box-secondary .uk-button-line {
  border: 1px solid #ffffff !important;
  color: #ffffff !important;
}
.uk-panel-box-secondary .uk-nav-side > li > a {
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.uk-panel-box-secondary .uk-nav-side > li:first-child > a,
.uk-panel-box-secondary .uk-nav-divider + li > a {
  border: none;
  box-shadow: none;
}
.uk-panel-box-secondary .uk-nav-side .uk-nav-header {
  color: #ffffff;
}
.uk-panel-box-secondary .uk-nav-divider {
  border-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.uk-panel-box-secondary .uk-nav-side ul.uk-nav-sub {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
}
.uk-panel-box-secondary .uk-nav-side ul a {
  color: #ffffff;
}
.uk-panel-box-secondary .uk-nav-side > li > a:hover,
.uk-panel-box-secondary .uk-nav-side > li > a:focus {
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.uk-panel-box-secondary .uk-nav-side > li.uk-active > a {
  color: #ffffff;
}
.uk-panel-box-secondary hr {
  height: 1px;
  border-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.uk-panel-box-secondary h1,
.uk-panel-box-secondary h2,
.uk-panel-box-secondary h3,
.uk-panel-box-secondary h4,
.uk-panel-box-secondary h5,
.uk-panel-box-secondary h6 {
  color: #ffffff;
}
.uk-panel-box-secondary .uk-form select,
.uk-panel-box-secondary .uk-form textarea,
.uk-panel-box-secondary .uk-form input:not([type]),
.uk-panel-box-secondary .uk-form input[type="text"],
.uk-panel-box-secondary .uk-form input[type="password"],
.uk-panel-box-secondary .uk-form input[type="tel"],
.uk-panel-box-secondary .uk-form input[type="number"],
.uk-panel-box-secondary .uk-form input[type="email"] {
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.uk-panel-box-secondary .uk-form select:focus,
.uk-panel-box-secondary .uk-form textarea:focus,
.uk-panel-box-secondary .uk-form input:not([type]):focus,
.uk-panel-box-secondary .uk-form input[type="text"]:focus,
.uk-panel-box-secondary .uk-form input[type="password"]:focus,
.uk-panel-box-secondary .uk-form input[type="tel"]:focus,
.uk-panel-box-secondary .uk-form input[type="number"]:focus,
.uk-panel-box-secondary .uk-form input[type="email"]:focus {
  border-color: #258bce;
  background: rgba(0, 0, 0, 0.1);
  color: #ffffff;
}
.uk-panel-box-secondary .uk-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-panel-box-secondary .uk-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-panel-box-secondary .uk-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-panel-box-secondary.uk-modal-dialog {
  background-color: #223148;
}
.uk-panel-box-secondary .uk-panel-badge {
  color: #ffffff;
  border-color: #ffffff;
}
.uk-panel-box-secondary-hover:hover {
  color: #ffffff;
}
.uk-panel-box-secondary .uk-panel-title {
  color: #ffffff;
}
/* Modifier: `uk-panel-hover`
 ========================================================================== */
.uk-panel-hover {
  padding: 15px;
  color: #444444;
}
.uk-panel-hover:hover {
  background: #f5f5f5;
  color: #444444;
}
.uk-panel-hover .uk-panel-badge {
  top: 10px;
  right: 10px;
}
.uk-panel-hover > .uk-panel-teaser {
  margin-top: -15px;
  margin-left: -15px;
  margin-right: -15px;
}
/* Modifier: `uk-panel-header`
 ========================================================================== */
.uk-panel-header .uk-panel-title {
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #2a455d;
}
/* Modifier: `uk-panel-space`
 ========================================================================== */
.uk-panel-space {
  padding: 40px;
}
.uk-panel-space .uk-panel-badge {
  top: 40px;
  right: 40px;
}
/* Modifier: `uk-panel-divider`
 ========================================================================== */
.uk-panel + .uk-panel-divider {
  margin-top: 60px !important;
}
.uk-panel + .uk-panel-divider:before {
  content: "";
  display: block;
  position: absolute;
  top: -30px;
  left: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-panel + .uk-panel-divider {
    margin-top: 80px !important;
  }
  .uk-panel + .uk-panel-divider:before {
    top: -40px;
  }
}
.uk-panel > h3:not(.uk-panel-title):first-child {
  margin-bottom: 15px;
}
.uk-panel.tm-panel-top-offset {
  margin-top: -40px;
}
.uk-panel-header {
  padding: 20px;
}
.uk-panel-header .uk-panel-title {
  border-bottom: 0;
}
.uk-panel-header .uk-panel-title:after {
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  border-bottom: 1px solid #2a455d;
}
.uk-panel-header .uk-panel-badge {
  top: 0;
  right: 0;
}
.tm-background-icon h3.uk-panel-title > i {
  position: absolute;
  right: -40px;
  font-size: 120px;
  opacity: 0.1;
  top: -40px;
}
.tm-panel-box-primary-light {
  border: 1px solid #dfeaff;
  background-color: #eef4ff;
}
.tm-panel-box-primary-light .uk-nav-side ul.uk-nav-sub {
  background-color: #e4edff;
}
.tm-panel-box-primary-light .uk-nav-side > li > a {
  border-top: 1px solid #dfeaff;
}
.tm-panel-card {
  padding: 0 !important;
  border: 1px solid #eef4ff;
  background-color: #ffffff;
  border-radius: 3px;
  border-bottom-color: #dfeaff;
  border-bottom-width: 2px;
}
.tm-panel-card:hover > .uk-panel-teaser .tm-social-icons {
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  margin-top: -45px;
  opacity: 1;
}
.tm-panel-card:hover > .tm-card-link:before {
  color: #258bce;
}
.tm-panel-card:hover .tm-card-avatar {
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-panel-card .tm-card-link {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  position: relative;
  display: inline-block;
  float: right;
  margin: -20px 15px 0 0;
  text-align: center;
  line-height: 40px;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;
  background-color: #ffffff;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  -webkit-box-shadow: inset 0 0 0 1px #258bce;
  -moz-box-shadow: inset 0 0 0 1px #258bce;
  box-shadow: inset 0 0 0 1px #258bce;
}
.tm-panel-card .tm-card-link > * {
  cursor: pointer;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-panel-card .tm-card-link:before {
  cursor: pointer;
  content: "\f141";
  font-family: "fontAwesome";
  color: #258bce;
  font-size: 20px;
  display: block;
}
.tm-panel-card .tm-card-link:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
  position: absolute;
  background-color: #223148;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  z-index: -1;
  left: 0;
  top: 0;
  -webkit-transition: -webkit-transform 0.4s, opacity 0.5s;
  -moz-transition: -moz-transform 0.4s, opacity 0.5s;
  transition: transform 0.4s, opacity 0.5s;
}
.tm-panel-card .tm-card-link:hover {
  -webkit-box-shadow: inset 0 0 0 1px #ffffff;
  -moz-box-shadow: inset 0 0 0 1px #ffffff;
  box-shadow: inset 0 0 0 1px #ffffff;
}
.tm-panel-card .tm-card-link:hover:before {
  color: #ffffff;
}
.tm-panel-card .tm-card-link:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.tm-panel-card .uk-panel-teaser {
  margin: 0;
  overflow: hidden;
  position: relative;
}
.tm-panel-card .uk-panel-teaser .tm-social-icons {
  background-color: rgba(37, 139, 206, 0.6);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(37, 153, 206, 0.6)), color-stop(100%, rgba(37, 125, 206, 0.6))) rgba(37, 139, 206, 0.6);
  background: -webkit-linear-gradient(top, rgba(37, 153, 206, 0.6) 0%, rgba(37, 125, 206, 0.6) 100%) rgba(37, 139, 206, 0.6);
  background: -moz-linear-gradient(top, rgba(37, 153, 206, 0.6) 0%, rgba(37, 125, 206, 0.6) 100%) rgba(37, 139, 206, 0.6);
  background: -o-linear-gradient(top, rgba(37, 153, 206, 0.6) 0%, rgba(37, 125, 206, 0.6) 100%) rgba(37, 139, 206, 0.6);
  background: -ms-linear-gradient(top, rgba(37, 153, 206, 0.6) 0%, rgba(37, 125, 206, 0.6) 100%) rgba(37, 139, 206, 0.6);
  background: linear-gradient(to bottom, rgba(37, 153, 206, 0.6) 0%, rgba(37, 125, 206, 0.6) 100%) rgba(37, 139, 206, 0.6);
  margin-top: -12px;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  position: absolute;
  opacity: 1;
  padding: 5px 10px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15);
  transition: all 250ms ease-in-out;
  width: 100%;
}
.tm-panel-card .uk-panel-teaser .tm-social-icons.tm-icons-visible {
  margin-top: -45px;
  opacity: 1;
}
.tm-panel-card .uk-panel-teaser .tm-social-icons .uk-icon-button {
  width: 30px;
  height: 35px;
  color: #ffffff;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
}
.tm-panel-card .uk-panel-teaser .tm-social-icons .uk-icon-button:hover {
  color: #ffffff;
}
.tm-panel-card .uk-panel-teaser:before {
  border-bottom: 0;
}
.tm-panel-card .tm-card-content {
  padding: 20px;
  border-top: 1px solid #258bce;
}
.tm-panel-card .tm-card-content .uk-panel-title {
  display: block;
  font-size: 20px;
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 5px;
}
.tm-panel-card .tm-card-content .uk-panel-title:after {
  border-bottom: 0;
}
.tm-panel-card .tm-card-title {
  padding-bottom: 20px;
  margin: 0;
  font-family: 'Varela';
  border-top: 1px solid #258bce;
  color: #258bce;
  display: inline-block;
  clear: both;
  padding: 5px 0 0;
  font-size: 14px;
  text-transform: none;
  letter-spacing: 2px;
}
.tm-panel-card .tm-card-avatar {
  width: 100%;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/* ========================================================================
   Component: Block
 ========================================================================== */
.uk-block {
  position: relative;
  box-sizing: border-box;
  padding-top: 40px;
  padding-bottom: 40px;
}
/* Phone landscape and bigger */
@media (min-width: 900px) {
  .uk-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
/*
 * Micro clearfix to make blocks more robust
 */
.uk-block:before,
.uk-block:after {
  content: "";
  display: table;
}
.uk-block:after {
  clear: both;
}
/*
 * Remove margin from the last-child
 */
.uk-block > :last-child {
  margin-bottom: 0;
}
/* Padding Modifier
 ========================================================================== */
/*
 * Large padding
 */
.uk-block-large {
  padding-top: 40px;
  padding-bottom: 40px;
}
/* Tablets and bigger */
@media (min-width: 900px) {
  .uk-block-large {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-block-large {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}
/* Color Modifier
 ========================================================================== */
/*
 * Default
 */
.uk-block-default {
  background: #ffffff;
}
/*
 * Muted
 */
.uk-block-muted {
  background: #eef4ff;
}
/*
 * Primary
 */
.uk-block-primary {
  background: #258bce;
  color: #ffffff;
}
.uk-block-primary a:not([class*="uk"]),
.uk-block-primary .uk-link {
  color: #061621;
}
.uk-block-primary a:not([class*="uk"]):hover,
.uk-block-primary .uk-link:hover {
  color: #155178;
  text-decoration: none;
}
.uk-block-primary .uk-panel-title {
  color: #ffffff;
}
.uk-block-primary .uk-subnav > * > * {
  color: #ffffff;
}
.uk-block-primary .uk-subnav > * > *:hover {
  color: #ffffff;
}
.uk-block-primary .uk-grid-divider > [class*='uk-width-1-']:not(.uk-width-1-1):nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-2-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-3-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-4-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-5-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-6-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-7-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-8-']:nth-child(n+2),
.uk-block-primary .uk-grid-divider > [class*='uk-width-9-']:nth-child(n+2) {
  border-left-color: #277bb3;
}
@media (min-width: 900px) {
  .uk-block-primary .uk-grid-divider > [class*='uk-width-medium-']:not(.uk-width-medium-1-1):nth-child(n+2) {
    border-left-color: #277bb3;
  }
}
@media (min-width: 960px) {
  .uk-block-primary .uk-grid-divider > [class*='uk-width-large-']:not(.uk-width-large-1-1):nth-child(n+2) {
    border-left-color: #277bb3;
  }
}
.uk-block-primary .uk-grid-divider:empty {
  border-top-color: #277bb3;
}
.uk-block-primary .uk-panel-box-primary {
  border-color: #277bb3;
  background-color: #2685c3;
}
.uk-block-primary .uk-panel-teaser {
  background: #2488ca;
}
.uk-block-primary .uk-panel-header .uk-panel-title::after {
  border-bottom-color: #ffffff;
}
.uk-block-primary h1,
.uk-block-primary h2,
.uk-block-primary h3,
.uk-block-primary h4,
.uk-block-primary h5,
.uk-block-primary h6 {
  color: #ffffff;
}
.uk-block-primary .uk-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-block-primary .uk-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-block-primary .uk-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-block-primary .uk-form select,
.uk-block-primary .uk-form textarea,
.uk-block-primary .uk-form input:not([type]),
.uk-block-primary .uk-form input[type="text"],
.uk-block-primary .uk-form input[type="password"],
.uk-block-primary .uk-form input[type="email"] {
  color: rgba(255, 255, 255, 0.8);
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.04);
  background-color: rgba(0, 0, 0, 0.05);
  /* Focus state */
}
.uk-block-primary .uk-form select:focus,
.uk-block-primary .uk-form textarea:focus,
.uk-block-primary .uk-form input:not([type]):focus,
.uk-block-primary .uk-form input[type="text"]:focus,
.uk-block-primary .uk-form input[type="password"]:focus,
.uk-block-primary .uk-form input[type="email"]:focus {
  border-color: rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0);
}
.uk-block-primary ::-moz-selection {
  background: rgba(34, 49, 72, 0.75);
  color: #ffffff;
  text-shadow: none;
}
.uk-block-primary ::selection {
  background: rgba(34, 49, 72, 0.75);
  color: #ffffff;
  text-shadow: none;
}
.uk-block-primary .tm-block-left {
  background-color: #1d6ea3;
}
/*
 * Secondary
 */
.uk-block-secondary {
  background: #223148;
  color: #ffffff;
}
.uk-block-secondary .uk-module-title:after,
.uk-block-secondary > .uk-panel-title:after {
  border-bottom-color: #258bce;
}
.uk-block-secondary .uk-module-title-alt:before,
.uk-block-secondary .uk-module-title-alt:after {
  background-color: #258bce;
}
.uk-block-secondary.tm-darker {
  background-color: #1c2a3f;
}
.uk-block-secondary.tm-lighter {
  background-color: #2694dc;
}
.uk-block-secondary a:not([class*="uk"]),
.uk-block-secondary .uk-link {
  color: #258bce;
}
.uk-block-secondary a:not([class*="uk"]):hover,
.uk-block-secondary .uk-link:hover {
  color: rgba(37, 139, 206, 0.8);
  text-decoration: none;
}
.uk-block-secondary em {
  color: #ffffff;
}
.uk-block-secondary h1,
.uk-block-secondary h2,
.uk-block-secondary h3,
.uk-block-secondary h4,
.uk-block-secondary h5,
.uk-block-secondary h6,
.uk-block-secondary .uk-panel-header .uk-panel-title {
  color: #ffffff;
  border-bottom-color: #ffffff;
}
.uk-block-secondary hr {
  border-top-color: rgba(255, 255, 255, 0.15);
}
.uk-block-secondary .uk-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5) !important;
}
.uk-block-secondary .uk-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-block-secondary .uk-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.uk-block-secondary .uk-form select,
.uk-block-secondary .uk-form textarea,
.uk-block-secondary .uk-form input:not([type]),
.uk-block-secondary .uk-form input[type="text"],
.uk-block-secondary .uk-form input[type="password"],
.uk-block-secondary .uk-form input[type="email"] {
  color: rgba(255, 255, 255, 0.8);
  /* Focus state */
}
.uk-block-secondary .uk-form select:focus,
.uk-block-secondary .uk-form textarea:focus,
.uk-block-secondary .uk-form input:not([type]):focus,
.uk-block-secondary .uk-form input[type="text"]:focus,
.uk-block-secondary .uk-form input[type="password"]:focus,
.uk-block-secondary .uk-form input[type="email"]:focus {
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0);
}
.uk-block-secondary .uk-badge {
  border-color: #364967 !important;
  background-color: #364967 !important;
  color: #258bce !important;
}
.uk-block-secondary .uk-grid-divider > [class*='uk-width-1-']:not(.uk-width-1-1):nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-2-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-3-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-4-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-5-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-6-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-7-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-8-']:nth-child(n+2),
.uk-block-secondary .uk-grid-divider > [class*='uk-width-9-']:nth-child(n+2) {
  border-left-color: #1e2633;
}
@media (min-width: 900px) {
  .uk-block-secondary .uk-grid-divider > [class*='uk-width-medium-']:not(.uk-width-medium-1-1):nth-child(n+2) {
    border-left-color: #1e2633;
  }
}
@media (min-width: 960px) {
  .uk-block-secondary .uk-grid-divider > [class*='uk-width-large-']:not(.uk-width-large-1-1):nth-child(n+2) {
    border-left-color: #1e2633;
  }
}
.uk-block-secondary .uk-grid-divider:empty {
  border-top-color: #1e2633;
}
.uk-block-secondary .uk-icon-button {
  background: rgba(0, 0, 0, 0);
  color: #258bce;
}
.uk-block-secondary .uk-icon-button:hover,
.uk-block-secondary .uk-icon-button:focus {
  background-color: rgba(0, 0, 0, 0);
  color: rgba(37, 139, 206, 0.8);
}
.uk-block-secondary .uk-icon-button:active {
  background-color: rgba(0, 0, 0, 0.05);
  color: #ffffff;
}
.uk-block-secondary .uk-panel-teaser {
  background: #202f45;
}
.uk-block-secondary .uk-panel-header .uk-panel-title:after {
  border-color: #ffffff;
}
.uk-block-secondary .uk-subnav > * > a {
  color: #258bce;
  text-decoration: none;
}
.uk-block-secondary .uk-subnav > * > a:hover,
.uk-block-secondary .uk-subnav > * > a:focus {
  color: rgba(37, 139, 206, 0.8);
  text-decoration: none;
}
.uk-block-secondary .uk-subnav > .uk-active > a {
  color: #258bce;
}
.uk-block-secondary .uk-subnav-line > :nth-child(n+2):before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-block-secondary .uk-subnav-pill > .uk-active > a {
  color: #ffffff;
}
.uk-block-secondary .uk-text-muted,
.uk-block-secondary .uk-link-muted,
.uk-block-secondary .uk-link-muted a {
  color: rgba(255, 255, 255, 0.6) !important;
}
.uk-block-secondary .uk-text-primary {
  color: #ffffff !important;
}
.uk-block-secondary .tm-block-text {
  background-color: #121925;
}
.uk-block-primary-light {
  background-color: #eef4ff;
}
/* ========================================================================
   Component: Article
 ========================================================================== */
/*
 * Micro clearfix to make articles more robust
 */
.uk-article:before,
.uk-article:after {
  content: "";
  display: table;
}
.uk-article:after {
  clear: both;
}
/*
 * Remove margin from the last-child
 */
.uk-article > :last-child {
  margin-bottom: 0;
}
/*
 * Vertical gutter for articles
 */
.uk-article + .uk-article {
  margin-top: 30px;
}
/* Sub-object `uk-article-title`
 ========================================================================== */
.uk-article-title {
  font-weight: normal;
  text-transform: capitalize;
  margin-top: 0;
  line-height: 40px;
  font-size: 32px;
  font-weight: 500;
  padding-bottom: 20px;
  display: block;
  padding-bottom: 0;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
  text-transform: none;
}
.uk-article-title:after {
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  border-bottom: 1px solid #258bce;
}
.uk-article-title.uk-text-right:after {
  left: auto;
  right: 0;
}
.uk-article-title a {
  color: inherit;
  text-decoration: none;
}
/* Sub-object `uk-article-meta`
 ========================================================================== */
.uk-article-meta {
  font-size: 14px;
  line-height: 30px;
  color: #2a455d;
  text-transform: uppercase;
  margin-top: 0;
  color: #32526f;
  letter-spacing: 1px;
  padding-bottom: 10px;
  border-bottom: 1px dashed rgba(42, 69, 93, 0.2);
}
/* Sub-object `uk-article-lead`
 ========================================================================== */
.uk-article-lead {
  color: #2a455d;
  font-size: 20px;
  line-height: 36px;
  font-weight: 300;
}
/* Sub-object `uk-article-divider`
 ========================================================================== */
.uk-article-divider {
  margin-bottom: 30px;
  border-color: rgba(0, 0, 0, 0.1);
}
* + .uk-article-divider {
  margin-top: 30px;
}
.uk-article + .uk-article {
  margin-top: 60px;
}
/* ========================================================================
   Component: Comment
 ========================================================================== */
/* Sub-object `uk-comment-header`
 ========================================================================== */
.uk-comment-header {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  background: #eef4ff;
  overflow: hidden;
}
/*
 * Micro clearfix
 */
.uk-comment-header:before,
.uk-comment-header:after {
  content: "";
  display: table;
}
.uk-comment-header:after {
  clear: both;
}
/* Sub-object `uk-comment-avatar`
 ========================================================================== */
.uk-comment-avatar {
  margin-right: 20px;
  float: left;
  border-radius: 100%;
  box-shadow: 0 0 0 4px #ffffff, 0 0 0 5px #258bce;
}
/* Sub-object `uk-comment-title`
 ========================================================================== */
.uk-comment-title {
  margin: 5px 0 0 0;
  font-size: 18px;
  line-height: 25px;
  font-size: 20px;
  line-height: 26px;
  margin: 5px 0 10px;
}
/* Sub-object `uk-comment-meta`
 ========================================================================== */
.uk-comment-meta {
  margin: 2px 0 0 0;
  font-size: 14px;
  line-height: 26px;
  color: #2a455d;
  text-transform: uppercase;
  letter-spacing: 1px;
}
/* Sub-object `uk-comment-body`
 ========================================================================== */
/*
 * Remove margin from the last-child
 */
.uk-comment-body > :last-child {
  margin-bottom: 0;
}
/* Sub-object `uk-comment-list`
 ========================================================================== */
.uk-comment-list {
  padding: 0;
  list-style: none;
}
.uk-comment-list .uk-comment + ul {
  margin: 30px 0 0 0;
  list-style: none;
}
.uk-comment-list > li:nth-child(n+2),
.uk-comment-list .uk-comment + ul > li:nth-child(n+2) {
  margin-top: 30px;
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-comment-list .uk-comment + ul {
    padding-left: 100px;
  }
}
/* Modifier `uk-comment-primary`
 ========================================================================== */
.uk-comment-primary .uk-comment-header {
  background: rgba(132, 193, 24, 0.2);
  border-color: rgba(132, 193, 24, 0.15);
}
.uk-comment-primary .uk-comment-header:before {
  color: rgba(126, 184, 23, 0.2);
}
.uk-comment-body {
  padding-right: 20px;
  padding-left: 20px;
}
.uk-comment-header:before {
  content: "\e66d";
  float: right;
  font-family: "sandal-icons";
  font-size: 150px;
  color: #dfeaff;
  margin: -15px -35px;
}
/* ========================================================================
   Component: Cover
 ========================================================================== */
/*
 * Background image always covers and centers its element
 */
.uk-cover-background {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
/*
 * Emulates image cover, works with video and image elements
 * 1. Parent container which clips resized object
 * 2. Resizes the object to always covers its container
 * 3. Reset the responsive image CSS
 * 4. Center object
 */
/* 1 */
.uk-cover {
  overflow: hidden;
}
.uk-cover-object {
  /* 2 */
  width: auto;
  height: auto;
  min-width: 100%;
  min-height: 100%;
  /* 3 */
  max-width: none;
  /* 4 */
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/*
 * To center iframes use `data-uk-cover` JavaScript
 */
[data-uk-cover] {
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* ========================================================================
   Component: Nav
 ========================================================================== */
.uk-nav,
.uk-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
 * Items
 */
.uk-nav li > a {
  display: block;
  text-decoration: none;
}
.uk-nav > li > a {
  padding: 7px 0;
}
/*
 * Nested items
 */
.uk-nav ul {
  padding-left: 15px;
}
.uk-nav ul a {
  padding: 2px 0;
}
/*
 * Item subtitle
 */
.uk-nav li > a > div {
  font-size: 14px;
  line-height: 21px;
}
/* Sub-object: `uk-nav-header`
 ========================================================================== */
.uk-nav-header {
  padding: 7px 0;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 14px;
}
.uk-nav-header:not(:first-child) {
  margin-top: 20px;
}
/* Sub-object: `uk-nav-divider`
 ========================================================================== */
.uk-nav-divider {
  margin: 10px 0;
}
/* Sub-object: `uk-nav-sub`
 ========================================================================== */
/*
 * `ul` needed for higher specificity to override padding
 */
ul.uk-nav-sub {
  padding: 10px 0 10px 15px;
  padding-right: 10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  background: #eef4ff;
}
/* Modifier: `uk-nav-parent-icon`
 ========================================================================== */
.uk-nav-parent-icon > .uk-parent > a:after {
  content: "\f105";
  width: 12px;
  margin-right: 0;
  float: right;
  font-family: FontAwesome;
  text-align: center;
  font-size: 20px;
}
.uk-nav-parent-icon > .uk-parent.uk-open > a:after {
  content: "\f107";
}
/* Modifier `uk-nav-side`
 ========================================================================== */
/*
 * Items
 */
.uk-nav-side > li > a {
  color: #2a455d;
  padding: 10px 15px;
  border-top: 1px solid #eef4ff;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-nav-side > li > a:hover,
.uk-nav-side > li > a:focus {
  background: #eef4ff;
  color: #258bce;
  /* 2 */
  outline: none;
}
/* Active */
.uk-nav-side > li.uk-active > a {
  background: rgba(0, 0, 0, 0);
  color: #258bce;
  background-color: #258bce;
  border-radius: 0;
  color: #ffffff;
}
/*
 * Sub-object: `uk-nav-header`
 */
.uk-nav-side .uk-nav-header {
  color: #2a455d;
}
/*
 * Sub-object: `uk-nav-divider`
 */
.uk-nav-side .uk-nav-divider {
  border-top: 1px solid #eef4ff;
}
/*
 * Nested items
 */
.uk-nav-side ul a {
  color: #2a455d;
}
.uk-nav-side ul a:hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Modifier `uk-nav-dropdown`
 ========================================================================== */
/*
 * Items
 */
.uk-nav-dropdown > li > a {
  color: #2a455d;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-nav-dropdown > li > a:hover,
.uk-nav-dropdown > li > a:focus {
  background: rgba(0, 0, 0, 0);
  color: #258bce;
  /* 2 */
  outline: none;
}
/*
 * Sub-object: `uk-nav-header`
 */
.uk-nav-dropdown .uk-nav-header {
  color: #a8aeb6;
}
/*
 * Sub-object: `uk-nav-divider`
 */
.uk-nav-dropdown .uk-nav-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/*
 * Nested items
 */
.uk-nav-dropdown ul a {
  color: #2a455d;
}
.uk-nav-dropdown ul a:hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Modifier `uk-nav-navbar`
 ========================================================================== */
/*
 * Items
 */
.uk-nav-navbar > li > a {
  color: #2a455d;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-nav-navbar > li > a:hover,
.uk-nav-navbar > li > a:focus {
  background: rgba(0, 0, 0, 0);
  color: #258bce;
  /* 2 */
  outline: none;
}
/*
 * Sub-object: `uk-nav-header`
 */
.uk-nav-navbar .uk-nav-header {
  color: #a8aeb6;
}
/*
 * Sub-object: `uk-nav-divider`
 */
.uk-nav-navbar .uk-nav-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/*
 * Nested items
 */
.uk-nav-navbar ul a {
  color: #2a455d;
}
.uk-nav-navbar ul a:hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Modifier `uk-nav-offcanvas`
 ========================================================================== */
/*
 * Items
 */
.uk-nav-offcanvas > li > a {
  color: #ffffff;
  padding: 10px 20px;
}
/*
 * Hover
 * No hover on touch devices because it behaves buggy in fixed offcanvas
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-nav-offcanvas > .uk-open > a,
html:not(.uk-touch) .uk-nav-offcanvas > li > a:hover,
html:not(.uk-touch) .uk-nav-offcanvas > li > a:focus {
  background: rgba(0, 0, 0, 0);
  color: rgba(37, 139, 206, 0.8);
  /* 2 */
  outline: none;
}
/*
 * Active
 * `html .uk-nav` needed for higher specificity to override hover
 */
html .uk-nav.uk-nav-offcanvas > li.uk-active > a {
  background: rgba(0, 0, 0, 0);
  color: rgba(37, 139, 206, 0.8);
}
/*
 * Sub-object: `uk-nav-header`
 */
.uk-nav-offcanvas .uk-nav-header {
  color: #ffffff;
  padding: 10px 20px;
}
/*
 * Sub-object: `uk-nav-divider`
 */
.uk-nav-offcanvas .uk-nav-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/*
 * Nested items
 * No hover on touch devices because it behaves buggy in fixed offcanvas
 */
.uk-nav-offcanvas ul a {
  color: #ffffff;
}
html:not(.uk-touch) .uk-nav-offcanvas ul a:hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Modifier `uk-nav-side`
	 ========================================================================== */
.uk-nav-side > li:first-child > a {
  border-top-width: 0;
}
.uk-nav-dropdown ul.uk-nav-sub,
.uk-nav-navbar ul.uk-nav-sub {
  padding: 5px 0 5px 25px;
}
.uk-nav-offcanvas ul.uk-nav-sub {
  padding-left: 20px;
}
/* Modifier `uk-nav-list`
	 ========================================================================== */
.uk-subnav.uk-subnav-list > li {
  display: block;
  line-height: 35px;
}
.tm-bottom-b .uk-subnav.uk-subnav-list > li {
  width: 100%;
  margin-top: 0;
}
.uk-subnav.uk-subnav-list > li:nth-child(n+2) {
  margin-left: 0;
}
/* ========================================================================
   Component: Navbar
 ========================================================================== */
.uk-navbar {
  background: rgba(0, 0, 0, 0);
  color: #ffffff;
}
/*
 * Micro clearfix
 */
.uk-navbar:before,
.uk-navbar:after {
  content: "";
  display: table;
}
.uk-navbar:after {
  clear: both;
}
/* Sub-object: `uk-navbar-nav`
 ========================================================================== */
.uk-navbar-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  float: left;
}
/*
 * 1. Create position context for dropdowns
 */
.uk-navbar-nav > li {
  float: left;
  /* 1 */
  position: relative;
}
/*
 * 1. Dimensions
 * 2. Style
 */
.uk-navbar-nav > li > a {
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  /* 1 */
  height: 70px;
  padding: 0 25px;
  line-height: 70px;
  /* 2 */
  color: #ffffff;
  font-size: 16px;
  font-family: 'Varela';
  font-weight: 400;
  text-transform: none;
  letter-spacing: 0;
}
/* Appear not as link */
.uk-navbar-nav > li > a[href='#'] {
  cursor: text;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Also apply if dropdown is opened
 * 3. Remove default focus style
 */
.uk-navbar-nav > li:hover > a,
.uk-navbar-nav > li > a:focus,
.uk-navbar-nav > li.uk-open > a {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
  /* 3 */
  outline: none;
}
/* OnClick */
.uk-navbar-nav > li > a:active {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
}
/* Active */
.uk-navbar-nav > li.uk-active > a {
  background-color: rgba(255, 255, 255, 0.15);
  color: #ffffff;
}
/* Sub-objects: `uk-navbar-nav-subtitle`
 ========================================================================== */
.uk-navbar-nav .uk-navbar-nav-subtitle {
  line-height: 58px;
}
.uk-navbar-nav-subtitle > div {
  margin-top: -20px;
  font-size: 10px;
  line-height: 12px;
}
/* Sub-objects: `uk-navbar-content`, `uk-navbar-brand`, `uk-navbar-toggle`
 ========================================================================== */
/*
 * Imitate navbar items
 */
.uk-navbar-content,
.uk-navbar-brand,
.uk-navbar-toggle {
  box-sizing: border-box;
  display: block;
  height: 70px;
  padding: 0 25px;
  float: left;
  padding: 0 10px;
}
/*
 * Helper to center all child elements vertically
 */
.uk-navbar-content:before,
.uk-navbar-brand:before,
.uk-navbar-toggle:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/* Sub-objects: `uk-navbar-content`
 ========================================================================== */
/*
 * Better sibling spacing
 */
.uk-navbar-content + .uk-navbar-content:not(.uk-navbar-center) {
  padding-left: 0;
}
/*
 * Link colors
 */
.uk-navbar-content > a:not([class]) {
  color: #258bce;
}
.uk-navbar-content > a:not([class]):hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Sub-objects: `uk-navbar-brand`
 ========================================================================== */
.uk-navbar-brand {
  font-size: 18px;
  color: #ffffff;
  text-decoration: none;
}
/*
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-navbar-brand:hover,
.uk-navbar-brand:focus {
  color: #ffffff;
  text-decoration: none;
  /* 2 */
  outline: none;
}
/* Sub-object: `uk-navbar-toggle`
 ========================================================================== */
.uk-navbar-toggle {
  font-size: 16px;
  color: #2a455d;
  text-decoration: none;
}
/*
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-navbar-toggle:hover,
.uk-navbar-toggle:focus {
  color: #258bce;
  text-decoration: none;
  /* 2 */
  outline: none;
}
/*
 * 1. Center icon vertically
 */
.uk-navbar-toggle:after {
  content: "\f0c9";
  font-family: FontAwesome;
  /* 1 */
  vertical-align: middle;
}
.uk-navbar-toggle-alt:after {
  content: "\f002";
}
/* Sub-object: `uk-navbar-center`
 ========================================================================== */
/*
 * The element with this class needs to be last child in the navbar
 * 1. This hack is needed because other float elements shift centered text
 */
.uk-navbar-center {
  float: none;
  text-align: center;
  /* 1 */
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
/* Sub-object: `uk-navbar-flip`
 ========================================================================== */
.uk-navbar-flip {
  float: right;
}
.uk-navbar > .uk-navbar-nav:first-child > li:first-child > a {
  border-left-width: 0;
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1);
}
.uk-navbar-flip > .uk-navbar-nav > li:last-child > a {
  border-right-width: 0;
  box-shadow: none;
}
.uk-navbar-brand + .uk-navbar-nav > li:first-child > a,
.uk-navbar-flip > .uk-navbar-nav > li:first-child > a {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1), inset 1px 0 0 rgba(255, 255, 255, 0.1);
}
.uk-navbar-nav-subtitle > div {
  margin-top: 0px;
}
.uk-nav-navbar ul a,
.uk-nav-navbar > li > a {
  color: #2a455d;
  font-family: 'Varela';
  font-weight: 400;
}
.uk-navbar-nav li.uk-parent {
  position: relative;
}
.uk-nav-navbar > li > a {
  color: #2a455d;
  font-family: 'Varela';
  font-weight: 400;
}
.uk-nav-navbar ul,
.uk-nav-navbar ul > li.uk-parent > ul {
  padding: 0 !important;
  position: absolute;
  left: 100%;
  width: 250px;
  background-color: #ffffff;
  opacity: 0;
  top: 0;
  z-index: 999;
  visibility: hidden;
}
li.uk-parent > ul.uk-dropdown-flip {
  left: auto !important;
  right: 100%;
}
.uk-nav-navbar ul a:hover {
  color: #ffffff;
  background-color: #258bce;
}
.uk-nav-navbar > li.uk-parent:hover > ul > li.uk-parent:hover > ul,
.uk-nav-navbar > li.uk-parent:hover > ul {
  opacity: 1;
  visibility: visible;
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
}
/* ========================================================================
   Component: Subnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.uk-subnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -10px;
  margin-top: -10px;
  /* 2 */
  padding: 0;
  list-style: none;
}
/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `uk-width-*` classes can be applied
 * 3. Create position context for dropdowns
 */
.uk-subnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  /* 2 */
  padding-left: 10px;
  margin-top: 10px;
  /* 3 */
  position: relative;
}
/*
 * DEPRECATED IE9 Support
 */
.uk-subnav:before,
.uk-subnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-subnav:after {
  clear: both;
}
.uk-subnav > * {
  float: left;
}
/* Items
 ========================================================================== */
.uk-subnav > * > * {
  display: inline-block;
  color: #2a455d;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.uk-subnav > * > :hover,
.uk-subnav > * > :focus {
  color: #258bce;
  text-decoration: none;
}
/*
 * Active
 */
.uk-subnav > .uk-active > * {
  color: #258bce;
}
/* Modifier: 'subnav-line'
 ========================================================================== */
.uk-subnav-line > :before {
  content: "";
  display: inline-block;
  height: 10px;
  vertical-align: middle;
}
.uk-subnav-line > :nth-child(n+2):before {
  margin-right: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}
/* Modifier: 'subnav-pill'
 ========================================================================== */
.uk-subnav-pill > * > * {
  padding: 3px 9px;
  padding: 0px 6px 2px;
  box-shadow: inset 0 -2px 0 #dfeaff, inset 0 2px 0 #dfeaff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-subnav-pill > * > :hover,
.uk-subnav-pill > * > :focus {
  background: #eef4ff;
  color: #258bce;
  text-decoration: none;
  /* 2 */
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 -2px 0 #258bce;
}
/*
 * Active
 * `li` needed for higher specificity to override hover
 */
.uk-subnav-pill > .uk-active > * {
  background: #258bce;
  color: #ffffff;
  border-radius: 3px;
  box-shadow: none;
  background-color: #258bce;
}
/* Disabled state
 ========================================================================== */
.uk-subnav > .uk-disabled > * {
  background: none;
  color: #a8aeb6;
  text-decoration: none;
  cursor: text;
}
.uk-subnav.uk-subnav-list > li {
  width: 100%;
  line-height: 26px;
}
/* ========================================================================
   Component: Breadcrumb
 ========================================================================== */
/*
 * 1. Remove default list style
 * 2. Remove whitespace between child elements when using `inline-block`
 */
.uk-breadcrumb {
  /* 1 */
  padding: 0;
  list-style: none;
  /* 2 */
  font-size: 0.001px;
  margin: 0;
  padding: 10px 0;
}
/* Items
 ========================================================================== */
/*
 * Reset whitespace hack
 */
.uk-breadcrumb > li {
  font-size: 15px;
  vertical-align: top;
}
.uk-breadcrumb > li,
.uk-breadcrumb > li > a,
.uk-breadcrumb > li > span {
  display: inline-block;
}
.uk-breadcrumb > li:nth-child(n+2):before {
  content: "\f105";
  display: inline-block;
  margin: 0 15px;
  color: rgba(42, 69, 93, 0.5);
  font-family: "fontAwesome";
  font-size: 16px;
}
/*
 * Disabled
 */
.uk-breadcrumb > li:not(.uk-active) > span {
  color: rgba(42, 69, 93, 0.5);
}
/* ========================================================================
   Component: Pagination
 ========================================================================== */
/*
 * 1. Remove default list style
 * 2. Center pagination by default
 * 3. Remove whitespace between child elements when using `inline-block`
 */
.uk-pagination {
  /* 1 */
  padding: 0;
  list-style: none;
  /* 2 */
  text-align: center;
  /* 3 */
  font-size: 0.001px;
}
/*
 * Micro clearfix
 * Needed if `uk-pagination-previous` or `uk-pagination-next` sub-objects are used
 */
.uk-pagination:before,
.uk-pagination:after {
  content: "";
  display: table;
}
.uk-pagination:after {
  clear: both;
}
/* Items
 ========================================================================== */
/*
 * 1. Reset whitespace hack
 * 2. Remove the gap at the bottom of it container
 */
.uk-pagination > li {
  display: inline-block;
  /* 1 */
  font-size: 1rem;
  /* 2 */
  vertical-align: top;
}
.uk-pagination > li:nth-child(n+2) {
  margin-left: 10px;
}
/*
 * 1. Makes pagination more robust against different box-sizing use
 * 2. Reset text-align to center if alignment modifier is used
 */
.uk-pagination > li > a,
.uk-pagination > li > span {
  display: inline-block;
  min-width: 28px;
  padding: 1px 3px;
  line-height: 32px;
  text-decoration: none;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 3px;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Links
 */
.uk-pagination > li > a {
  background: rgba(0, 0, 0, 0);
  color: #2a455d;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-pagination > li > a:hover,
.uk-pagination > li > a:focus {
  background-color: #ffffff;
  color: #258bce;
  /* 2 */
  outline: none;
  border: 1px solid #258bce;
}
/* OnClick */
.uk-pagination > li > a:active {
  background-color: #eef4ff;
  color: #258bce;
}
/*
 * Active
 */
.uk-pagination > .uk-active > span {
  background: #ffffff;
  color: #258bce;
  border: 1px solid #258bce;
}
/*
 * Disabled
 */
.uk-pagination > .uk-disabled > span {
  background-color: #ffffff;
  color: #a8aeb6;
}
/* Previous and next navigation
 ========================================================================== */
.uk-pagination-previous {
  float: left;
}
.uk-pagination-next {
  float: right;
}
/* Alignment modifiers
 ========================================================================== */
.uk-pagination-left {
  text-align: left;
}
.uk-pagination-right {
  text-align: right;
}
/* ========================================================================
   Component: Tab
 ========================================================================== */
.uk-tab {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #258bce;
}
/*
 * Micro clearfix on the deepest container
 */
.uk-tab:before,
.uk-tab:after {
  content: "";
  display: table;
}
.uk-tab:after {
  clear: both;
}
/*
 * Items
 * 1. Create position context for dropdowns
 */
.uk-tab > li {
  margin-bottom: -1px;
  float: left;
  /* 1 */
  position: relative;
}
.uk-tab > li > a {
  display: block;
  padding: 8px 12px 8px 12px;
  border: 1px solid transparent;
  border-bottom-width: 0;
  color: #2a455d;
  text-decoration: none;
  border-radius: 3px 3px 0 0;
}
.uk-tab > li:nth-child(n+2) > a {
  margin-left: 5px;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Also apply if dropdown is opened
 * 3. Remove default focus style
 */
.uk-tab > li > a:hover,
.uk-tab > li > a:focus,
.uk-tab > li.uk-open > a {
  border-color: rgba(0, 0, 0, 0.1);
  background: #eef4ff;
  color: #2a455d;
  /* 2 */
  outline: none;
}
.uk-tab > li:not(.uk-active) > a:hover,
.uk-tab > li:not(.uk-active) > a:focus,
.uk-tab > li.uk-open:not(.uk-active) > a {
  margin-bottom: 1px;
  padding-bottom: 7px;
}
/* Active */
.uk-tab > li.uk-active > a {
  border-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: transparent;
  background: #258bce;
  color: #ffffff;
}
/* Disabled */
.uk-tab > li.uk-disabled > a {
  color: #a8aeb6;
  cursor: text;
}
.uk-tab > li.uk-disabled > a:hover,
.uk-tab > li.uk-disabled > a:focus,
.uk-tab > li.uk-disabled.uk-active > a {
  background: none;
  border-color: transparent;
}
/* Modifier: 'tab-flip'
 ========================================================================== */
.uk-tab-flip > li {
  float: right;
}
.uk-tab-flip > li:nth-child(n+2) > a {
  margin-left: 0;
  margin-right: 5px;
}
/* Modifier: 'tab-responsive'
 ========================================================================== */
.uk-tab > li.uk-tab-responsive > a {
  margin-left: 0;
  margin-right: 0;
}
/*
 * Icon
 */
.uk-tab-responsive > a:before {
  content: "\f0c9\00a0";
  font-family: FontAwesome;
}
/* Modifier: 'tab-center'
 ========================================================================== */
.uk-tab-center {
  border-bottom: 1px solid #258bce;
}
.uk-tab-center-bottom {
  border-bottom: none;
  border-top: 1px solid #258bce;
}
.uk-tab-center:before,
.uk-tab-center:after {
  content: "";
  display: table;
}
.uk-tab-center:after {
  clear: both;
}
/*
 * 1. Using `right` to prevent vertical scrollbar caused by centering if to many tabs
 */
.uk-tab-center .uk-tab {
  position: relative;
  right: 50%;
  border: none;
  float: right;
}
.uk-tab-center .uk-tab > li {
  position: relative;
  right: -50%;
}
.uk-tab-center .uk-tab > li > a {
  text-align: center;
}
/* Modifier: 'tab-bottom'
 ========================================================================== */
.uk-tab-bottom {
  border-top: 1px solid #258bce;
  border-bottom: none;
}
.uk-tab-bottom > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.uk-tab-bottom > li > a {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom-width: 1px;
  border-top-width: 0;
}
.uk-tab-bottom > li:not(.uk-active) > a:hover,
.uk-tab-bottom > li:not(.uk-active) > a:focus,
.uk-tab-bottom > li.uk-open:not(.uk-active) > a {
  margin-bottom: 0;
  margin-top: 1px;
  padding-bottom: 8px;
  padding-top: 7px;
}
.uk-tab-bottom > li.uk-active > a {
  border-top-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.1);
}
/* Modifier: 'tab-grid'
 ========================================================================== */
/*
 * 1. Create position context to prevent hidden border because of negative `z-index`
 */
.uk-tab-grid {
  margin-left: -5px;
  border-bottom: none;
  /* 1 */
  position: relative;
  z-index: 0;
}
.uk-tab-grid:before {
  display: block;
  position: absolute;
  left: 5px;
  right: 0;
  bottom: -1px;
  border-top: 1px solid #258bce;
  /* 1 */
  z-index: -1;
}
.uk-tab-grid > li:first-child > a {
  margin-left: 5px;
}
.uk-tab-grid > li > a {
  text-align: center;
}
/*
 * If `uk-tab-bottom`
 */
.uk-tab-grid.uk-tab-bottom {
  border-top: none;
}
.uk-tab-grid.uk-tab-bottom:before {
  top: -1px;
  bottom: auto;
}
/* Modifier: 'tab-left', 'tab-right'
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-tab-left,
  .uk-tab-right {
    border-bottom: none;
  }
  .uk-tab-left > li,
  .uk-tab-right > li {
    margin-bottom: 0;
    float: none;
  }
  .uk-tab-left > li > a,
  .uk-tab-right > li > a {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .uk-tab-left > li:nth-child(n+2) > a,
  .uk-tab-right > li:nth-child(n+2) > a {
    margin-left: 0;
    margin-top: 5px;
  }
  .uk-tab-left > li.uk-active > a,
  .uk-tab-right > li.uk-active > a {
    border-color: rgba(0, 0, 0, 0.1);
  }
  /*
     * Modifier: 'tab-left'
     */
  .uk-tab-left {
    border-right: 1px solid #258bce;
  }
  .uk-tab-left > li {
    margin-right: -1px;
  }
  .uk-tab-left > li > a {
    border-bottom-width: 1px;
    border-right-width: 0;
  }
  .uk-tab-left > li:not(.uk-active) > a:hover,
  .uk-tab-left > li:not(.uk-active) > a:focus {
    margin-bottom: 0;
    margin-right: 1px;
    padding-bottom: 8px;
    padding-right: 11px;
  }
  .uk-tab-left > li.uk-active > a {
    border-right-color: transparent;
  }
  /*
     * Modifier: 'tab-right'
     */
  .uk-tab-right {
    border-left: 1px solid #258bce;
  }
  .uk-tab-right > li {
    margin-left: -1px;
  }
  .uk-tab-right > li > a {
    border-bottom-width: 1px;
    border-left-width: 0;
  }
  .uk-tab-right > li:not(.uk-active) > a:hover,
  .uk-tab-right > li:not(.uk-active) > a:focus {
    margin-bottom: 0;
    margin-left: 1px;
    padding-bottom: 8px;
    padding-left: 11px;
  }
  .uk-tab-right > li.uk-active > a {
    border-left-color: transparent;
  }
}
.uk-tab-top:before {
  border-width: 2px;
  bottom: -2px;
}
.uk-tab-right,
.uk-tab-left {
  border-width: 2px;
}
.uk-tab-left > li > a {
  border-radius: 3px 0 0 3px;
}
.uk-tab-right > li > a {
  border-radius: 0 3px 3px 0;
}
.uk-tab-content {
  box-shadow: 0 1px 0 1px #eef4ff;
  margin: 0 !important;
  padding: 40px !important;
  border-radius: 0 0 4px 4px;
}
/* ========================================================================
   Component: Thumbnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.uk-thumbnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -10px;
  margin-top: -10px;
  /* 2 */
  padding: 0;
  list-style: none;
}
/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `uk-width-*` classes can be applied
 */
.uk-thumbnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  /* 2 */
  padding-left: 10px;
  margin-top: 10px;
}
/*
 * DEPRECATED IE9 Support
 */
.uk-thumbnav:before,
.uk-thumbnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-thumbnav:after {
  clear: both;
}
.uk-thumbnav > * {
  float: left;
}
/* Items
 ========================================================================== */
.uk-thumbnav > * > * {
  display: block;
  background: #ffffff;
  border-radius: 3px;
}
.uk-thumbnav > * > * > img {
  opacity: 0.7;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
/*
 * Hover
 */
.uk-thumbnav > * > :hover > img,
.uk-thumbnav > * > :focus > img {
  opacity: 1;
}
/*
 * Active
 */
.uk-thumbnav > .uk-active > * > img {
  opacity: 1;
}
.uk-thumbnav > * > * > img {
  border-radius: 3px;
}
/* ========================================================================
   Component: List
 ========================================================================== */
.uk-list {
  padding: 0;
  list-style: none;
}
/*
 * Micro clearfix to make list more robust
 */
.uk-list > li:before,
.uk-list > li:after {
  content: "";
  display: table;
}
.uk-list > li:after {
  clear: both;
}
/*
 * Remove margin from the last-child
 */
.uk-list > li > :last-child {
  margin-bottom: 0;
}
/*
 * Nested lists
 */
.uk-list ul {
  margin: 0;
  padding-left: 20px;
  list-style: none;
}
/* Modifier: `uk-list-line`
 ========================================================================== */
.uk-list-line > li:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-top-style: dotted;
}
/* Modifier: `uk-list-striped`
 ========================================================================== */
.uk-list-striped > li {
  padding: 5px 5px;
}
.uk-list-striped > li:nth-of-type(odd) {
  background: rgba(0, 0, 0, 0.02);
}
/* Modifier: `uk-list-space`
 ========================================================================== */
.uk-list-space > li:nth-child(n+2) {
  margin-top: 10px;
}
ul.list-icons li i {
  margin-right: 10px;
}
.uk-list-striped > li {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
}
.uk-list-striped > li:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.list-icons {
  padding-top: 10px;
}
.list-icons.uk-grid {
  margin: 0;
}
.list-icons.uk-text-right li {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.list-icons li {
  padding-left: 30px;
  position: relative;
  word-wrap: break-word;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.list-icons li i {
  position: absolute;
  margin: 5px 0 0 -25px;
  line-height: 22px;
}
.list-icons.uk-icons-large li i {
  font-size: 20px;
  margin: 5px 0 0 -30px;
}
.list-icons.uk-icons-xlarge li {
  padding-left: 40px;
}
.list-icons.uk-icons-xlarge li i {
  font-size: 32px;
  margin: 5px 0 0 -45px;
}
.uk-plain-list {
  padding-left: 0;
}
.uk-list > li:before,
.uk-list > li:after {
  display: inline;
}
#iconfonts .list-icons li {
  line-height: 35px;
  padding-left: 30px;
  word-wrap: break-word;
}
#iconfonts .list-icons li i {
  font-size: 20px;
  margin: 5px 0 0 -30px;
}
/* ========================================================================
   Component: Description list
 ========================================================================== */
/* Modifier: `uk-description-list-horizontal`
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-description-list-horizontal {
    overflow: hidden;
  }
  .uk-description-list-horizontal > dt {
    width: 160px;
    float: left;
    clear: both;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .uk-description-list-horizontal > dd {
    margin-left: 180px;
  }
}
/* Modifier: `uk-description-list-line`
 ========================================================================== */
.uk-description-list-line > dt {
  font-weight: normal;
}
.uk-description-list-line > dt:nth-child(n+2) {
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.uk-description-list-line > dd {
  color: #a8aeb6;
}
dt {
  font-weight: 400;
}
dl {
  margin: 10px 0;
}
/* ========================================================================
   Component: Table
 ========================================================================== */
/*
 * 1. Remove most spacing between table cells.
 * 2. Block element behavior
 * 3. Style
 */
.uk-table {
  /* 1 */
  border-collapse: collapse;
  border-spacing: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  margin-bottom: 20px;
}
/*
 * Add margin if adjacent element
 */
* + .uk-table {
  margin-top: 20px;
}
.uk-table th,
.uk-table td {
  padding: 8px 8px;
  border-bottom: 1px solid #eef4ff;
}
/*
 * Set alignment
 */
.uk-table th {
  text-align: left;
}
.uk-table td {
  vertical-align: top;
}
.uk-table thead th {
  vertical-align: bottom;
}
/*
 * Caption and footer
 */
.uk-table caption,
.uk-table tfoot {
  font-size: 14px;
  font-style: italic;
}
.uk-table caption {
  text-align: left;
  color: #a8aeb6;
}
/*
 * Active State
 */
.uk-table tbody tr.uk-active {
  background: #dfeaff;
}
/* Sub-modifier: `uk-table-middle`
 ========================================================================== */
.uk-table-middle,
.uk-table-middle td {
  vertical-align: middle !important;
}
/* Modifier: `uk-table-striped`
 ========================================================================== */
.uk-table-striped tbody tr:nth-of-type(odd) {
  background: #eef4ff;
}
/* Modifier: `uk-table-condensed`
 ========================================================================== */
.uk-table-condensed td {
  padding: 4px 8px;
}
/* Modifier: `uk-table-hover`
 ========================================================================== */
.uk-table-hover tbody tr:hover {
  background: #dfeaff;
}
.uk-table {
  border: 1px solid #eef4ff;
}
.uk-table thead {
  background-color: #258bce;
  color: #ffffff;
}
.uk-table tfoot {
  font-style: normal;
  font-size: 16px;
}
.uk-table caption {
  color: rgba(42, 69, 93, 0.5);
}
.table-bordered th,
.table-bordered td {
  border-color: #eef4ff;
}
/* ========================================================================
   Component: Form
 ========================================================================== */
/*
 * 1. Define consistent box sizing.
 *    Default is `content-box` with following exceptions set to `border-box`
 *    `button`, `select`, `input[type="checkbox"]` and `input[type="radio"]`
 *    `input[type="search"]` in Chrome, Safari and Opera
 *    `input[type="color"]` in Firefox
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Correct `font` properties and `color` not being inherited.
 */
.uk-form input,
.uk-form select,
.uk-form textarea {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  color: inherit;
}
/*
 * Address inconsistent `text-transform` inheritance which is only inherit in Firefox
 */
.uk-form select {
  text-transform: none;
}
/*
 * 1. Correct `font` properties not being inherited.
 * 2. Don't inherit the `font-weight` and use `bold` instead.
 * NOTE: Both declarations don't work in Chrome, Safari and Opera.
 */
.uk-form optgroup {
  /* 1 */
  font: inherit;
  /* 2 */
  font-weight: bold;
}
/*
 * Removes inner padding and border in Firefox 4+.
 */
.uk-form input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * Removes excess padding in IE 8/9/10.
 */
.uk-form input[type="checkbox"],
.uk-form input[type="radio"] {
  padding: 0;
}
/*
 * Improves consistency of cursor style for clickable elements
 */
.uk-form input[type="checkbox"]:not(:disabled),
.uk-form input[type="radio"]:not(:disabled) {
  cursor: pointer;
}
/*
 * Remove default style in iOS.
 */
.uk-form textarea,
.uk-form input:not([type]),
.uk-form input[type="text"],
.uk-form input[type="password"],
.uk-form input[type="email"],
.uk-form input[type="url"],
.uk-form input[type="search"],
.uk-form input[type="tel"],
.uk-form input[type="number"],
.uk-form input[type="datetime"] {
  -webkit-appearance: none;
}
/*
 * Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
 */
.uk-form input[type="search"]::-webkit-search-cancel-button,
.uk-form input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
.uk-form input[type="number"]::-webkit-inner-spin-button,
.uk-form input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/*
 * Define consistent border, margin, and padding.
 */
.uk-form fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
/*
 * 1. Remove default vertical scrollbar in IE 8/9/10/11.
 * 2. Improve readability and alignment in all browsers.
 */
.uk-form textarea {
  /* 1 */
  overflow: auto;
  /* 2 */
  vertical-align: top;
}
/*
 * Removes placeholder transparency in Firefox.
 */
.uk-form ::-moz-placeholder {
  opacity: 1;
}
/*
 * Removes `box-shadow` for invalid controls in Firefox.
 */
.uk-form :invalid {
  box-shadow: none;
}
/*
 * Vertical alignment
 */
.uk-form input:not([type="radio"]):not([type="checkbox"]),
.uk-form select {
  vertical-align: middle;
}
/* Style
 ========================================================================== */
/*
 * Remove margin from the last-child
 */
.uk-form > :last-child {
  margin-bottom: 0;
}
/*
 * Controls
 * Except for `range`, `radio`, `checkbox`, `file`, `submit`, `reset`, `button` and `image`
 * 1. Must be `height` because `min-height` is not working in OSX
 * 2. Responsiveness: Sets a maximum width relative to the parent to scale on narrower viewports
 * 3. Vertical `padding` needed for `select` elements in Firefox
 * 4. Style
 */
.uk-form select,
.uk-form textarea,
.uk-form input:not([type]),
.uk-form input[type="text"],
.uk-form input[type="password"],
.uk-form input[type="datetime"],
.uk-form input[type="datetime-local"],
.uk-form input[type="date"],
.uk-form input[type="month"],
.uk-form input[type="time"],
.uk-form input[type="week"],
.uk-form input[type="number"],
.uk-form input[type="email"],
.uk-form input[type="url"],
.uk-form input[type="search"],
.uk-form input[type="tel"],
.uk-form input[type="color"] {
  /* 1 */
  height: 40px;
  /* 2 */
  max-width: 100%;
  /* 3 */
  padding: 4px 6px;
  /* 4 */
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #ffffff;
  color: #2a455d;
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  font-weight: 400;
  border-radius: 2px;
  padding: 5px 10px;
}
.uk-form select:focus,
.uk-form textarea:focus,
.uk-form input:not([type]):focus,
.uk-form input[type="text"]:focus,
.uk-form input[type="password"]:focus,
.uk-form input[type="datetime"]:focus,
.uk-form input[type="datetime-local"]:focus,
.uk-form input[type="date"]:focus,
.uk-form input[type="month"]:focus,
.uk-form input[type="time"]:focus,
.uk-form input[type="week"]:focus,
.uk-form input[type="number"]:focus,
.uk-form input[type="email"]:focus,
.uk-form input[type="url"]:focus,
.uk-form input[type="search"]:focus,
.uk-form input[type="tel"]:focus,
.uk-form input[type="color"]:focus {
  border-color: rgba(37, 139, 206, 0.75);
  outline: 0;
  background: #ffffff;
  color: #2a455d;
}
.uk-form select:disabled,
.uk-form textarea:disabled,
.uk-form input:not([type]):disabled,
.uk-form input[type="text"]:disabled,
.uk-form input[type="password"]:disabled,
.uk-form input[type="datetime"]:disabled,
.uk-form input[type="datetime-local"]:disabled,
.uk-form input[type="date"]:disabled,
.uk-form input[type="month"]:disabled,
.uk-form input[type="time"]:disabled,
.uk-form input[type="week"]:disabled,
.uk-form input[type="number"]:disabled,
.uk-form input[type="email"]:disabled,
.uk-form input[type="url"]:disabled,
.uk-form input[type="search"]:disabled,
.uk-form input[type="tel"]:disabled,
.uk-form input[type="color"]:disabled {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #eef4ff;
  color: #a8aeb6;
}
/*
 * Placeholder
 */
.uk-form :-ms-input-placeholder {
  color: rgba(42, 69, 93, 0.5) !important;
}
.uk-form ::-moz-placeholder {
  color: rgba(42, 69, 93, 0.5);
}
.uk-form ::-webkit-input-placeholder {
  color: rgba(42, 69, 93, 0.5);
}
.uk-form :disabled:-ms-input-placeholder {
  color: #a8aeb6 !important;
}
.uk-form :disabled::-moz-placeholder {
  color: #a8aeb6;
}
.uk-form :disabled::-webkit-input-placeholder {
  color: #a8aeb6;
}
/*
 * Legend
 * 1. Behave like block element
 * 2. Correct `color` not being inherited in IE 8/9/10/11.
 * 3. Remove padding
 * 4. `margin-bottom` is not working in Safari and Opera.
 *    Using `padding` and :after instead to create the border
 * 5. Style
 */
.uk-form legend {
  /* 1 */
  width: 100%;
  /* 2 */
  border: 0;
  /* 3 */
  padding: 0;
  /* 4 */
  padding-bottom: 20px;
  /* 5 */
  font-size: 20px;
  line-height: 33px;
}
/*
 * 1. Fixes IE9
 */
.uk-form legend:after {
  content: "";
  display: block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  /* 1 */
  width: 100%;
}
/* Size modifiers
 * Higher specificity needed to override defaults
 ========================================================================== */
select.uk-form-small,
textarea.uk-form-small,
input[type].uk-form-small,
input:not([type]).uk-form-small {
  height: 35px;
  padding: 3px 3px;
  font-size: 14px;
}
select.uk-form-large,
textarea.uk-form-large,
input[type].uk-form-large,
input:not([type]).uk-form-large {
  height: 60px;
  padding: 8px 12px;
  font-size: 16px;
}
/* Reset height
 * Must be after size modifiers
 ========================================================================== */
.uk-form textarea,
.uk-form select[multiple],
.uk-form select[size] {
  height: auto;
}
/* Validation states
 * Using !important to keep the selector simple
 ========================================================================== */
/*
 * Error state
 */
.uk-form-danger {
  border-color: rgba(218, 49, 75, 0.5) !important;
  background: rgba(218, 49, 75, 0.1) !important;
  color: #da314b !important;
}
/*
 * Success state
 */
.uk-form-success {
  border-color: rgba(132, 193, 24, 0.7) !important;
  background: rgba(132, 193, 24, 0.1) !important;
  color: #84c118 !important;
}
/* Style modifiers
 * Using !important to keep the selector simple
 ========================================================================== */
/*
 * Blank form
 */
.uk-form-blank {
  border-color: transparent !important;
  border-style: dashed !important;
  background: none !important;
}
.uk-form-blank:focus {
  border-color: rgba(0, 0, 0, 0.1) !important;
}
/* Size sub-modifiers
 ========================================================================== */
/*
 * Fixed widths
 * Different widths for mini sized `input` and `select` elements
 */
input.uk-form-width-mini {
  width: 40px;
}
select.uk-form-width-mini {
  width: 65px;
}
.uk-form-width-small {
  width: 130px;
}
.uk-form-width-medium {
  width: 200px;
}
.uk-form-width-large {
  width: 500px;
}
/* Sub-objects: `uk-form-row`
 * Groups labels and controls in rows
 ========================================================================== */
/*
 * Micro clearfix
 * Needed for `uk-form-horizontal` modifier
 */
.uk-form-row:before,
.uk-form-row:after {
  content: "";
  display: table;
}
.uk-form-row:after {
  clear: both;
}
/*
 * Vertical gutter
 */
.uk-form-row + .uk-form-row {
  margin-top: 20px;
}
/* Help text
 * Sub-object: `uk-form-help-inline`, `uk-form-help-block`
 ========================================================================== */
.uk-form-help-inline {
  display: inline-block;
  margin: 0 0 0 10px;
}
.uk-form-help-block {
  margin: 5px 0 0 0;
}
/* Controls content
 * Sub-object: `uk-form-controls`, `uk-form-controls-condensed`
 ========================================================================== */
/*
 * Remove margins
 */
.uk-form-controls > :first-child {
  margin-top: 0;
}
.uk-form-controls > :last-child {
  margin-bottom: 0;
}
/*
 * Group controls and text into blocks with a small spacing between blocks
 */
.uk-form-controls-condensed {
  margin: 5px 0;
}
/* Modifier: `uk-form-stacked`
 * Requires sub-object: `uk-form-label`
 ========================================================================== */
.uk-form-stacked .uk-form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}
/* Modifier: `uk-form-horizontal`
 * Requires sub-objects: `uk-form-label`, `uk-form-controls`
 ========================================================================== */
/* Tablet portrait and smaller */
@media (max-width: 959px) {
  /* Behave like `uk-form-stacked` */
  .uk-form-horizontal .uk-form-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-form-horizontal .uk-form-label {
    width: 200px;
    margin-top: 5px;
    float: left;
  }
  .uk-form-horizontal .uk-form-controls {
    margin-left: 215px;
  }
  /* Better vertical alignment if controls are checkboxes and radio buttons with text */
  .uk-form-horizontal .uk-form-controls-text {
    padding-top: 5px;
  }
}
/* Sub-object: `uk-form-icon`
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Prevent `inline-block` consequences
 */
.uk-form-icon {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
}
/*
 * 1. Make form element clickable through icon
 */
.uk-form-icon > [class*='uk-icon-'] {
  position: absolute;
  top: 50%;
  width: 40px;
  margin-top: -8px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.2);
  text-align: center;
  /* 1 */
  pointer-events: none;
}
.uk-form-icon:not(.uk-form-icon-flip) > input {
  padding-left: 40px !important;
}
/*
 * Sub-modifier: `uk-form-icon-flip`
 */
.uk-form-icon-flip > [class*='uk-icon-'] {
  right: 0;
}
.uk-form-icon-flip > input {
  padding-right: 40px !important;
}
.uk-form-label + input,
.uk-form-label + textarea {
  margin-top: 5px !important;
}
.uk-form-icon > [class*='uk-icon-'] {
  z-index: 1;
  margin-top: -8px;
}
.tm-textarea-icon > [class*='uk-icon-'] {
  top: 22px;
}
.tm-textarea-icon textarea {
  padding-left: 40px !important;
}
.uk-form input {
  border-radius: 2px;
}
.uk-form label.radio {
  line-height: 0.5;
  padding-left: 30px;
}
.uk-form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 40px #ffffff inset !important;
  border-color: rgba(0, 0, 0, 0.15) !important;
  -webkit-text-fill-color: rgba(42, 69, 93, 0.5);
}
.uk-form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 40px #ffffff inset !important;
  border-color: rgba(37, 139, 206, 0.75) !important;
  -webkit-text-fill-color: #2a455d;
}
input:focus:invalid:focus,
textarea:focus:invalid:focus,
select:focus:invalid:focus {
  box-shadow: none;
  border-color: rgba(37, 139, 206, 0.75);
}
/* ========================================================================
   Component: Button
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.uk-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * 1. Correct inability to style clickable `input` types in iOS.
 * 2. Remove margins in Chrome, Safari and Opera.
 * 3. Remove borders for `button`.
 * 4. Address `overflow` set to `hidden` in IE 8/9/10/11.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Address inconsistent `text-transform` inheritance which is only inherit in Firefox and IE
 * 7. Style
 * 8. `line-height` is used to create a height
 * 9. `min-height` is necessary for `input` elements in Firefox and Opera because `line-height` is not working.
 * 10. Reset button group whitespace hack
 * 11. Required for `a`.
 */
.uk-button {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  margin: 0;
  /* 3 */
  border: none;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: #ffffff;
  /* 6 */
  text-transform: none;
  /* 7 */
  display: inline-block;
  box-sizing: border-box;
  padding: 0 12px;
  background: #223148;
  vertical-align: middle;
  /* 8 */
  line-height: 40px;
  /* 9 */
  min-height: 40px;
  /* 10 */
  font-size: 1rem;
  /* 11 */
  text-decoration: none;
  text-align: center;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 15px;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.uk-button i {
  -webkit-transition: 200ms ease-in-out;
  -moz-transition: 200ms ease-in-out;
  transition: 200ms ease-in-out;
}
.uk-button:not(:disabled) {
  cursor: pointer;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 */
.uk-button:hover,
.uk-button:focus {
  background-color: #222b39;
  color: #ffffff;
  /* 2 */
  outline: none;
  /* 3 */
  text-decoration: none;
}
.uk-button:hover:not(.uk-button-link) > i.uk-icon-chevron-right,
.uk-button:focus:not(.uk-button-link) > i.uk-icon-chevron-right {
  webkit-transform: translate(3px, 0);
  -moz-transform: translate(3px, 0);
  transform: translate(3px, 0);
}
/* Active */
.uk-button:active,
.uk-button.uk-active {
  background-color: #1c242f;
  color: #ffffff;
}
/* Color modifiers
 ========================================================================== */
/*
 * Modifier: `uk-button-primary`
 */
.uk-button-primary {
  background-color: #258bce;
  color: #ffffff;
}
/* Hover */
.uk-button-primary:hover,
.uk-button-primary:focus {
  background-color: #2682be;
  color: #ffffff;
}
/* Active */
.uk-button-primary:active,
.uk-button-primary.uk-active {
  background-color: #2479b1;
  color: #ffffff;
}
/*
 * Modifier: `uk-button-success`
 */
.uk-button-success {
  background-color: #84c118;
  color: #ffffff;
}
/* Hover */
.uk-button-success:hover,
.uk-button-success:focus {
  background-color: #78a920;
  color: #ffffff;
}
/* Active */
.uk-button-success:active,
.uk-button-success.uk-active {
  background-color: #6f9c1e;
  color: #ffffff;
}
/*
 * Modifier: `uk-button-danger`
 */
.uk-button-danger {
  background-color: #da314b;
  color: #ffffff;
}
/* Hover */
.uk-button-danger:hover,
.uk-button-danger:focus {
  background-color: #c9334a;
  color: #ffffff;
}
/* Active */
.uk-button-danger:active,
.uk-button-danger.uk-active {
  background-color: #bd3045;
  color: #ffffff;
}
/* Disabled state
 * Overrides also the color modifiers
 ========================================================================== */
/* Equal for all button types */
.uk-button:disabled {
  background-color: #eef4ff;
  color: #a8aeb6;
  box-shadow: none;
  background-image: none;
}
/* Modifier: `uk-button-link`
 ========================================================================== */
/* Reset */
.uk-button-link,
.uk-button-link:hover,
.uk-button-link:focus,
.uk-button-link:active,
.uk-button-link.uk-active,
.uk-button-link:disabled {
  border-color: transparent;
  background: none;
  box-shadow: none;
  padding: 0;
}
/* Color */
.uk-button-link {
  color: #258bce;
}
.uk-button-link:hover,
.uk-button-link:focus,
.uk-button-link:active,
.uk-button-link.uk-active {
  color: rgba(37, 139, 206, 0.8);
  text-decoration: underline;
}
.uk-button-link:disabled {
  color: #a8aeb6;
}
/* Focus */
.uk-button-link:focus {
  outline: 1px dotted;
}
/* Size modifiers
 ========================================================================== */
.uk-button-mini {
  min-height: 25px;
  padding: 0 6px;
  line-height: 25px;
  font-size: 12px;
}
.uk-button-small {
  min-height: 35px;
  padding: 0 10px;
  line-height: 35px;
  font-size: 14px;
}
.uk-button-large {
  min-height: 45px;
  padding: 0 25px;
  line-height: 45px;
  font-size: 18px;
}
/* Sub-object `uk-button-group`
 ========================================================================== */
/*
 * 1. Behave like buttons
 * 2. Create position context for dropdowns
 * 3. Remove whitespace between child elements when using `inline-block`
 * 4. Prevent buttons from wrapping
 * 5. Remove whitespace between child elements when using `inline-block`
 */
.uk-button-group {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
  /* 3 */
  font-size: 0.001px;
  /* 4 */
  white-space: nowrap;
}
.uk-button-group > * {
  display: inline-block;
}
/* 5 */
.uk-button-group .uk-button {
  vertical-align: top;
}
/* Sub-object: `uk-button-dropdown`
 ========================================================================== */
/*
 * 1. Behave like buttons
 * 2. Create position context for dropdowns
 */
.uk-button-dropdown {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
}
/* Sub-object `uk-button-group`
     ========================================================================== */
.uk-button-group > .uk-button:not(:first-child):not(:last-child),
.uk-button-group > div:not(:first-child):not(:last-child) .uk-button {
  border-radius: 0;
}
.uk-button-group > .uk-button:first-child,
.uk-button-group > div:first-child .uk-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.uk-button-group > .uk-button:last-child,
.uk-button-group > div:last-child .uk-button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
.uk-button-group > .uk-button:not(:first-child):not(:last-child),
.uk-button-group > div:not(:first-child):not(:last-child) .uk-button {
  border-left: 1px solid rgba(255, 255, 255, 0.05);
}
.uk-button-line,
.uk-button.uk-button-line {
  background: none !important;
  border: 1px solid #258bce;
  color: #258bce;
  border-radius: 3px;
}
.uk-button-line:hover,
.uk-button.uk-button-line:hover {
  background: #258bce !important;
  border: 1px solid #2382c1 !important;
  color: #ffffff;
}
.uk-button-secondary {
  background-color: #223148;
  color: #ffffff;
}
.uk-button-secondary:hover {
  background-color: #1e2a3c;
}
.uk-button-secondary:focus,
.uk-button-secondary:active {
  background-color: #1d2a3e;
}
.uk-form input[type="submit"] {
  border-radius: 3px;
  font-family: 'Varela';
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 1rem;
}
.uk-form input[type="submit"].uk-button-primary {
  color: #ffffff;
}
.uk-form input[type="submit"].uk-button-default {
  color: #ffffff;
}
.uk-button i:before {
  font-size: 12px;
  margin-left: 5px;
}
.uk-button.uk-button-large i:before {
  font-size: 14px;
}
a.btn-link {
  font-family: 'Varela';
  font-size: 16px;
}
/* ========================================================================
   Component: Icon
 ========================================================================== */
@font-face {
  font-family: 'FontAwesome';
  src: url("../../vendor/uikit/fonts/fontawesome-webfont.woff2") format('woff2'), url("../../vendor/uikit/fonts/fontawesome-webfont.woff") format("woff"), url("../../vendor/uikit/fonts/fontawesome-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/*
 * 1. Allow margin
 * 2. Prevent inherit font style
 * 4. Correct line-height
 * 5. Better font rendering
 * 6. Remove `text-decoration` for anchors
 */
[class*='uk-icon-'] {
  font-family: FontAwesome;
  /* 1 */
  display: inline-block;
  /* 2 */
  font-weight: normal;
  font-style: normal;
  /* 4 */
  line-height: 1;
  /* 5 */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* 6 */
[class*='uk-icon-'],
[class*='uk-icon-']:hover,
[class*='uk-icon-']:focus {
  text-decoration: none;
}
/* Size modifiers
 ========================================================================== */
.uk-icon-small {
  font-size: 150%;
  vertical-align: -10%;
}
.uk-icon-medium {
  font-size: 200%;
  vertical-align: -16%;
}
.uk-icon-large {
  font-size: 250%;
  vertical-align: -22%;
}
/* Modifier: `uk-icon-justify`
 ========================================================================== */
.uk-icon-justify {
  width: 1em;
  text-align: center;
}
/* Modifier: `uk-icon-spin`
 ========================================================================== */
.uk-icon-spin {
  display: inline-block;
  -webkit-animation: uk-rotate 2s infinite linear;
  animation: uk-rotate 2s infinite linear;
}
/* Modifier: `uk-icon-hover`
 ========================================================================== */
.uk-icon-hover {
  color: #2a455d;
}
/*
 * Hover
 */
.uk-icon-hover:hover {
  color: #258bce;
}
/* Modifier: `uk-icon-button`
 ========================================================================== */
.uk-icon-button {
  box-sizing: border-box;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0);
  line-height: 30px;
  color: #2a455d;
  font-size: 20px;
  text-align: center;
  width: 35px;
  height: 35px;
  font-weight: bold;
  line-height: 35px;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-icon-button:hover,
.uk-icon-button:focus {
  background-color: rgba(0, 0, 0, 0);
  color: #258bce;
  /* 2 */
  outline: none;
}
/* Active */
.uk-icon-button:active {
  background-color: rgba(0, 0, 0, 0);
  color: #258bce;
}
/* Icon mapping
 ========================================================================== */
.uk-icon-glass:before {
  content: "\f000";
}
.uk-icon-music:before {
  content: "\f001";
}
.uk-icon-search:before {
  content: "\f002";
}
.uk-icon-envelope-o:before {
  content: "\f003";
}
.uk-icon-heart:before {
  content: "\f004";
}
.uk-icon-star:before {
  content: "\f005";
}
.uk-icon-star-o:before {
  content: "\f006";
}
.uk-icon-user:before {
  content: "\f007";
}
.uk-icon-film:before {
  content: "\f008";
}
.uk-icon-th-large:before {
  content: "\f009";
}
.uk-icon-th:before {
  content: "\f00a";
}
.uk-icon-th-list:before {
  content: "\f00b";
}
.uk-icon-check:before {
  content: "\f00c";
}
.uk-icon-remove:before,
.uk-icon-close:before,
.uk-icon-times:before {
  content: "\f00d";
}
.uk-icon-search-plus:before {
  content: "\f00e";
}
.uk-icon-search-minus:before {
  content: "\f010";
}
.uk-icon-power-off:before {
  content: "\f011";
}
.uk-icon-signal:before {
  content: "\f012";
}
.uk-icon-gear:before,
.uk-icon-cog:before {
  content: "\f013";
}
.uk-icon-trash-o:before {
  content: "\f014";
}
.uk-icon-home:before {
  content: "\f015";
}
.uk-icon-file-o:before {
  content: "\f016";
}
.uk-icon-clock-o:before {
  content: "\f017";
}
.uk-icon-road:before {
  content: "\f018";
}
.uk-icon-download:before {
  content: "\f019";
}
.uk-icon-arrow-circle-o-down:before {
  content: "\f01a";
}
.uk-icon-arrow-circle-o-up:before {
  content: "\f01b";
}
.uk-icon-inbox:before {
  content: "\f01c";
}
.uk-icon-play-circle-o:before {
  content: "\f01d";
}
.uk-icon-rotate-right:before,
.uk-icon-repeat:before {
  content: "\f01e";
}
.uk-icon-refresh:before {
  content: "\f021";
}
.uk-icon-list-alt:before {
  content: "\f022";
}
.uk-icon-lock:before {
  content: "\f023";
}
.uk-icon-flag:before {
  content: "\f024";
}
.uk-icon-headphones:before {
  content: "\f025";
}
.uk-icon-volume-off:before {
  content: "\f026";
}
.uk-icon-volume-down:before {
  content: "\f027";
}
.uk-icon-volume-up:before {
  content: "\f028";
}
.uk-icon-qrcode:before {
  content: "\f029";
}
.uk-icon-barcode:before {
  content: "\f02a";
}
.uk-icon-tag:before {
  content: "\f02b";
}
.uk-icon-tags:before {
  content: "\f02c";
}
.uk-icon-book:before {
  content: "\f02d";
}
.uk-icon-bookmark:before {
  content: "\f02e";
}
.uk-icon-print:before {
  content: "\f02f";
}
.uk-icon-camera:before {
  content: "\f030";
}
.uk-icon-font:before {
  content: "\f031";
}
.uk-icon-bold:before {
  content: "\f032";
}
.uk-icon-italic:before {
  content: "\f033";
}
.uk-icon-text-height:before {
  content: "\f034";
}
.uk-icon-text-width:before {
  content: "\f035";
}
.uk-icon-align-left:before {
  content: "\f036";
}
.uk-icon-align-center:before {
  content: "\f037";
}
.uk-icon-align-right:before {
  content: "\f038";
}
.uk-icon-align-justify:before {
  content: "\f039";
}
.uk-icon-list:before {
  content: "\f03a";
}
.uk-icon-dedent:before,
.uk-icon-outdent:before {
  content: "\f03b";
}
.uk-icon-indent:before {
  content: "\f03c";
}
.uk-icon-video-camera:before {
  content: "\f03d";
}
.uk-icon-photo:before,
.uk-icon-image:before,
.uk-icon-picture-o:before {
  content: "\f03e";
}
.uk-icon-pencil:before {
  content: "\f040";
}
.uk-icon-map-marker:before {
  content: "\f041";
}
.uk-icon-adjust:before {
  content: "\f042";
}
.uk-icon-tint:before {
  content: "\f043";
}
.uk-icon-edit:before,
.uk-icon-pencil-square-o:before {
  content: "\f044";
}
.uk-icon-share-square-o:before {
  content: "\f045";
}
.uk-icon-check-square-o:before {
  content: "\f046";
}
.uk-icon-arrows:before {
  content: "\f047";
}
.uk-icon-step-backward:before {
  content: "\f048";
}
.uk-icon-fast-backward:before {
  content: "\f049";
}
.uk-icon-backward:before {
  content: "\f04a";
}
.uk-icon-play:before {
  content: "\f04b";
}
.uk-icon-pause:before {
  content: "\f04c";
}
.uk-icon-stop:before {
  content: "\f04d";
}
.uk-icon-forward:before {
  content: "\f04e";
}
.uk-icon-fast-forward:before {
  content: "\f050";
}
.uk-icon-step-forward:before {
  content: "\f051";
}
.uk-icon-eject:before {
  content: "\f052";
}
.uk-icon-chevron-left:before {
  content: "\f053";
}
.uk-icon-chevron-right:before {
  content: "\f054";
}
.uk-icon-plus-circle:before {
  content: "\f055";
}
.uk-icon-minus-circle:before {
  content: "\f056";
}
.uk-icon-times-circle:before {
  content: "\f057";
}
.uk-icon-check-circle:before {
  content: "\f058";
}
.uk-icon-question-circle:before {
  content: "\f059";
}
.uk-icon-info-circle:before {
  content: "\f05a";
}
.uk-icon-crosshairs:before {
  content: "\f05b";
}
.uk-icon-times-circle-o:before {
  content: "\f05c";
}
.uk-icon-check-circle-o:before {
  content: "\f05d";
}
.uk-icon-ban:before {
  content: "\f05e";
}
.uk-icon-arrow-left:before {
  content: "\f060";
}
.uk-icon-arrow-right:before {
  content: "\f061";
}
.uk-icon-arrow-up:before {
  content: "\f062";
}
.uk-icon-arrow-down:before {
  content: "\f063";
}
.uk-icon-mail-forward:before,
.uk-icon-share:before {
  content: "\f064";
}
.uk-icon-expand:before {
  content: "\f065";
}
.uk-icon-compress:before {
  content: "\f066";
}
.uk-icon-plus:before {
  content: "\f067";
}
.uk-icon-minus:before {
  content: "\f068";
}
.uk-icon-asterisk:before {
  content: "\f069";
}
.uk-icon-exclamation-circle:before {
  content: "\f06a";
}
.uk-icon-gift:before {
  content: "\f06b";
}
.uk-icon-leaf:before {
  content: "\f06c";
}
.uk-icon-fire:before {
  content: "\f06d";
}
.uk-icon-eye:before {
  content: "\f06e";
}
.uk-icon-eye-slash:before {
  content: "\f070";
}
.uk-icon-warning:before,
.uk-icon-exclamation-triangle:before {
  content: "\f071";
}
.uk-icon-plane:before {
  content: "\f072";
}
.uk-icon-calendar:before {
  content: "\f073";
}
.uk-icon-random:before {
  content: "\f074";
}
.uk-icon-comment:before {
  content: "\f075";
}
.uk-icon-magnet:before {
  content: "\f076";
}
.uk-icon-chevron-up:before {
  content: "\f077";
}
.uk-icon-chevron-down:before {
  content: "\f078";
}
.uk-icon-retweet:before {
  content: "\f079";
}
.uk-icon-shopping-cart:before {
  content: "\f07a";
}
.uk-icon-folder:before {
  content: "\f07b";
}
.uk-icon-folder-open:before {
  content: "\f07c";
}
.uk-icon-arrows-v:before {
  content: "\f07d";
}
.uk-icon-arrows-h:before {
  content: "\f07e";
}
.uk-icon-bar-chart-o:before,
.uk-icon-bar-chart:before {
  content: "\f080";
}
.uk-icon-twitter-square:before {
  content: "\f081";
}
.uk-icon-facebook-square:before {
  content: "\f082";
}
.uk-icon-camera-retro:before {
  content: "\f083";
}
.uk-icon-key:before {
  content: "\f084";
}
.uk-icon-gears:before,
.uk-icon-cogs:before {
  content: "\f085";
}
.uk-icon-comments:before {
  content: "\f086";
}
.uk-icon-thumbs-o-up:before {
  content: "\f087";
}
.uk-icon-thumbs-o-down:before {
  content: "\f088";
}
.uk-icon-star-half:before {
  content: "\f089";
}
.uk-icon-heart-o:before {
  content: "\f08a";
}
.uk-icon-sign-out:before {
  content: "\f08b";
}
.uk-icon-linkedin-square:before {
  content: "\f08c";
}
.uk-icon-thumb-tack:before {
  content: "\f08d";
}
.uk-icon-external-link:before {
  content: "\f08e";
}
.uk-icon-sign-in:before {
  content: "\f090";
}
.uk-icon-trophy:before {
  content: "\f091";
}
.uk-icon-github-square:before {
  content: "\f092";
}
.uk-icon-upload:before {
  content: "\f093";
}
.uk-icon-lemon-o:before {
  content: "\f094";
}
.uk-icon-phone:before {
  content: "\f095";
}
.uk-icon-square-o:before {
  content: "\f096";
}
.uk-icon-bookmark-o:before {
  content: "\f097";
}
.uk-icon-phone-square:before {
  content: "\f098";
}
.uk-icon-twitter:before {
  content: "\f099";
}
.uk-icon-facebook-f:before,
.uk-icon-facebook:before {
  content: "\f09a";
}
.uk-icon-github:before {
  content: "\f09b";
}
.uk-icon-unlock:before {
  content: "\f09c";
}
.uk-icon-credit-card:before {
  content: "\f09d";
}
.uk-icon-rss:before {
  content: "\f09e";
}
.uk-icon-hdd-o:before {
  content: "\f0a0";
}
.uk-icon-bullhorn:before {
  content: "\f0a1";
}
.uk-icon-bell:before {
  content: "\f0f3";
}
.uk-icon-certificate:before {
  content: "\f0a3";
}
.uk-icon-hand-o-right:before {
  content: "\f0a4";
}
.uk-icon-hand-o-left:before {
  content: "\f0a5";
}
.uk-icon-hand-o-up:before {
  content: "\f0a6";
}
.uk-icon-hand-o-down:before {
  content: "\f0a7";
}
.uk-icon-arrow-circle-left:before {
  content: "\f0a8";
}
.uk-icon-arrow-circle-right:before {
  content: "\f0a9";
}
.uk-icon-arrow-circle-up:before {
  content: "\f0aa";
}
.uk-icon-arrow-circle-down:before {
  content: "\f0ab";
}
.uk-icon-globe:before {
  content: "\f0ac";
}
.uk-icon-wrench:before {
  content: "\f0ad";
}
.uk-icon-tasks:before {
  content: "\f0ae";
}
.uk-icon-filter:before {
  content: "\f0b0";
}
.uk-icon-briefcase:before {
  content: "\f0b1";
}
.uk-icon-arrows-alt:before {
  content: "\f0b2";
}
.uk-icon-group:before,
.uk-icon-users:before {
  content: "\f0c0";
}
.uk-icon-chain:before,
.uk-icon-link:before {
  content: "\f0c1";
}
.uk-icon-cloud:before {
  content: "\f0c2";
}
.uk-icon-flask:before {
  content: "\f0c3";
}
.uk-icon-cut:before,
.uk-icon-scissors:before {
  content: "\f0c4";
}
.uk-icon-copy:before,
.uk-icon-files-o:before {
  content: "\f0c5";
}
.uk-icon-paperclip:before {
  content: "\f0c6";
}
.uk-icon-save:before,
.uk-icon-floppy-o:before {
  content: "\f0c7";
}
.uk-icon-square:before {
  content: "\f0c8";
}
.uk-icon-navicon:before,
.uk-icon-reorder:before,
.uk-icon-bars:before {
  content: "\f0c9";
}
.uk-icon-list-ul:before {
  content: "\f0ca";
}
.uk-icon-list-ol:before {
  content: "\f0cb";
}
.uk-icon-strikethrough:before {
  content: "\f0cc";
}
.uk-icon-underline:before {
  content: "\f0cd";
}
.uk-icon-table:before {
  content: "\f0ce";
}
.uk-icon-magic:before {
  content: "\f0d0";
}
.uk-icon-truck:before {
  content: "\f0d1";
}
.uk-icon-pinterest:before {
  content: "\f0d2";
}
.uk-icon-pinterest-square:before {
  content: "\f0d3";
}
.uk-icon-google-plus-square:before {
  content: "\f0d4";
}
.uk-icon-google-plus:before {
  content: "\f0d5";
}
.uk-icon-money:before {
  content: "\f0d6";
}
.uk-icon-caret-down:before {
  content: "\f0d7";
}
.uk-icon-caret-up:before {
  content: "\f0d8";
}
.uk-icon-caret-left:before {
  content: "\f0d9";
}
.uk-icon-caret-right:before {
  content: "\f0da";
}
.uk-icon-columns:before {
  content: "\f0db";
}
.uk-icon-unsorted:before,
.uk-icon-sort:before {
  content: "\f0dc";
}
.uk-icon-sort-down:before,
.uk-icon-sort-desc:before {
  content: "\f0dd";
}
.uk-icon-sort-up:before,
.uk-icon-sort-asc:before {
  content: "\f0de";
}
.uk-icon-envelope:before {
  content: "\f0e0";
}
.uk-icon-linkedin:before {
  content: "\f0e1";
}
.uk-icon-rotate-left:before,
.uk-icon-undo:before {
  content: "\f0e2";
}
.uk-icon-legal:before,
.uk-icon-gavel:before {
  content: "\f0e3";
}
.uk-icon-dashboard:before,
.uk-icon-tachometer:before {
  content: "\f0e4";
}
.uk-icon-comment-o:before {
  content: "\f0e5";
}
.uk-icon-comments-o:before {
  content: "\f0e6";
}
.uk-icon-flash:before,
.uk-icon-bolt:before {
  content: "\f0e7";
}
.uk-icon-sitemap:before {
  content: "\f0e8";
}
.uk-icon-umbrella:before {
  content: "\f0e9";
}
.uk-icon-paste:before,
.uk-icon-clipboard:before {
  content: "\f0ea";
}
.uk-icon-lightbulb-o:before {
  content: "\f0eb";
}
.uk-icon-exchange:before {
  content: "\f0ec";
}
.uk-icon-cloud-download:before {
  content: "\f0ed";
}
.uk-icon-cloud-upload:before {
  content: "\f0ee";
}
.uk-icon-user-md:before {
  content: "\f0f0";
}
.uk-icon-stethoscope:before {
  content: "\f0f1";
}
.uk-icon-suitcase:before {
  content: "\f0f2";
}
.uk-icon-bell-o:before {
  content: "\f0a2";
}
.uk-icon-coffee:before {
  content: "\f0f4";
}
.uk-icon-cutlery:before {
  content: "\f0f5";
}
.uk-icon-file-text-o:before {
  content: "\f0f6";
}
.uk-icon-building-o:before {
  content: "\f0f7";
}
.uk-icon-hospital-o:before {
  content: "\f0f8";
}
.uk-icon-ambulance:before {
  content: "\f0f9";
}
.uk-icon-medkit:before {
  content: "\f0fa";
}
.uk-icon-fighter-jet:before {
  content: "\f0fb";
}
.uk-icon-beer:before {
  content: "\f0fc";
}
.uk-icon-h-square:before {
  content: "\f0fd";
}
.uk-icon-plus-square:before {
  content: "\f0fe";
}
.uk-icon-angle-double-left:before {
  content: "\f100";
}
.uk-icon-angle-double-right:before {
  content: "\f101";
}
.uk-icon-angle-double-up:before {
  content: "\f102";
}
.uk-icon-angle-double-down:before {
  content: "\f103";
}
.uk-icon-angle-left:before {
  content: "\f104";
}
.uk-icon-angle-right:before {
  content: "\f105";
}
.uk-icon-angle-up:before {
  content: "\f106";
}
.uk-icon-angle-down:before {
  content: "\f107";
}
.uk-icon-desktop:before {
  content: "\f108";
}
.uk-icon-laptop:before {
  content: "\f109";
}
.uk-icon-tablet:before {
  content: "\f10a";
}
.uk-icon-mobile-phone:before,
.uk-icon-mobile:before {
  content: "\f10b";
}
.uk-icon-circle-o:before {
  content: "\f10c";
}
.uk-icon-quote-left:before {
  content: "\f10d";
}
.uk-icon-quote-right:before {
  content: "\f10e";
}
.uk-icon-spinner:before {
  content: "\f110";
}
.uk-icon-circle:before {
  content: "\f111";
}
.uk-icon-mail-reply:before,
.uk-icon-reply:before {
  content: "\f112";
}
.uk-icon-github-alt:before {
  content: "\f113";
}
.uk-icon-folder-o:before {
  content: "\f114";
}
.uk-icon-folder-open-o:before {
  content: "\f115";
}
.uk-icon-smile-o:before {
  content: "\f118";
}
.uk-icon-frown-o:before {
  content: "\f119";
}
.uk-icon-meh-o:before {
  content: "\f11a";
}
.uk-icon-gamepad:before {
  content: "\f11b";
}
.uk-icon-keyboard-o:before {
  content: "\f11c";
}
.uk-icon-flag-o:before {
  content: "\f11d";
}
.uk-icon-flag-checkered:before {
  content: "\f11e";
}
.uk-icon-terminal:before {
  content: "\f120";
}
.uk-icon-code:before {
  content: "\f121";
}
.uk-icon-mail-reply-all:before,
.uk-icon-reply-all:before {
  content: "\f122";
}
.uk-icon-star-half-empty:before,
.uk-icon-star-half-full:before,
.uk-icon-star-half-o:before {
  content: "\f123";
}
.uk-icon-location-arrow:before {
  content: "\f124";
}
.uk-icon-crop:before {
  content: "\f125";
}
.uk-icon-code-fork:before {
  content: "\f126";
}
.uk-icon-unlink:before,
.uk-icon-chain-broken:before {
  content: "\f127";
}
.uk-icon-question:before {
  content: "\f128";
}
.uk-icon-info:before {
  content: "\f129";
}
.uk-icon-exclamation:before {
  content: "\f12a";
}
.uk-icon-superscript:before {
  content: "\f12b";
}
.uk-icon-subscript:before {
  content: "\f12c";
}
.uk-icon-eraser:before {
  content: "\f12d";
}
.uk-icon-puzzle-piece:before {
  content: "\f12e";
}
.uk-icon-microphone:before {
  content: "\f130";
}
.uk-icon-microphone-slash:before {
  content: "\f131";
}
.uk-icon-shield:before {
  content: "\f132";
}
.uk-icon-calendar-o:before {
  content: "\f133";
}
.uk-icon-fire-extinguisher:before {
  content: "\f134";
}
.uk-icon-rocket:before {
  content: "\f135";
}
.uk-icon-maxcdn:before {
  content: "\f136";
}
.uk-icon-chevron-circle-left:before {
  content: "\f137";
}
.uk-icon-chevron-circle-right:before {
  content: "\f138";
}
.uk-icon-chevron-circle-up:before {
  content: "\f139";
}
.uk-icon-chevron-circle-down:before {
  content: "\f13a";
}
.uk-icon-html5:before {
  content: "\f13b";
}
.uk-icon-css3:before {
  content: "\f13c";
}
.uk-icon-anchor:before {
  content: "\f13d";
}
.uk-icon-unlock-alt:before {
  content: "\f13e";
}
.uk-icon-bullseye:before {
  content: "\f140";
}
.uk-icon-ellipsis-h:before {
  content: "\f141";
}
.uk-icon-ellipsis-v:before {
  content: "\f142";
}
.uk-icon-rss-square:before {
  content: "\f143";
}
.uk-icon-play-circle:before {
  content: "\f144";
}
.uk-icon-ticket:before {
  content: "\f145";
}
.uk-icon-minus-square:before {
  content: "\f146";
}
.uk-icon-minus-square-o:before {
  content: "\f147";
}
.uk-icon-level-up:before {
  content: "\f148";
}
.uk-icon-level-down:before {
  content: "\f149";
}
.uk-icon-check-square:before {
  content: "\f14a";
}
.uk-icon-pencil-square:before {
  content: "\f14b";
}
.uk-icon-external-link-square:before {
  content: "\f14c";
}
.uk-icon-share-square:before {
  content: "\f14d";
}
.uk-icon-compass:before {
  content: "\f14e";
}
.uk-icon-toggle-down:before,
.uk-icon-caret-square-o-down:before {
  content: "\f150";
}
.uk-icon-toggle-up:before,
.uk-icon-caret-square-o-up:before {
  content: "\f151";
}
.uk-icon-toggle-right:before,
.uk-icon-caret-square-o-right:before {
  content: "\f152";
}
.uk-icon-euro:before,
.uk-icon-eur:before {
  content: "\f153";
}
.uk-icon-gbp:before {
  content: "\f154";
}
.uk-icon-dollar:before,
.uk-icon-usd:before {
  content: "\f155";
}
.uk-icon-rupee:before,
.uk-icon-inr:before {
  content: "\f156";
}
.uk-icon-cny:before,
.uk-icon-rmb:before,
.uk-icon-yen:before,
.uk-icon-jpy:before {
  content: "\f157";
}
.uk-icon-ruble:before,
.uk-icon-rouble:before,
.uk-icon-rub:before {
  content: "\f158";
}
.uk-icon-won:before,
.uk-icon-krw:before {
  content: "\f159";
}
.uk-icon-bitcoin:before,
.uk-icon-btc:before {
  content: "\f15a";
}
.uk-icon-file:before {
  content: "\f15b";
}
.uk-icon-file-text:before {
  content: "\f15c";
}
.uk-icon-sort-alpha-asc:before {
  content: "\f15d";
}
.uk-icon-sort-alpha-desc:before {
  content: "\f15e";
}
.uk-icon-sort-amount-asc:before {
  content: "\f160";
}
.uk-icon-sort-amount-desc:before {
  content: "\f161";
}
.uk-icon-sort-numeric-asc:before {
  content: "\f162";
}
.uk-icon-sort-numeric-desc:before {
  content: "\f163";
}
.uk-icon-thumbs-up:before {
  content: "\f164";
}
.uk-icon-thumbs-down:before {
  content: "\f165";
}
.uk-icon-youtube-square:before {
  content: "\f166";
}
.uk-icon-youtube:before {
  content: "\f167";
}
.uk-icon-xing:before {
  content: "\f168";
}
.uk-icon-xing-square:before {
  content: "\f169";
}
.uk-icon-youtube-play:before {
  content: "\f16a";
}
.uk-icon-dropbox:before {
  content: "\f16b";
}
.uk-icon-stack-overflow:before {
  content: "\f16c";
}
.uk-icon-instagram:before {
  content: "\f16d";
}
.uk-icon-flickr:before {
  content: "\f16e";
}
.uk-icon-adn:before {
  content: "\f170";
}
.uk-icon-bitbucket:before {
  content: "\f171";
}
.uk-icon-bitbucket-square:before {
  content: "\f172";
}
.uk-icon-tumblr:before {
  content: "\f173";
}
.uk-icon-tumblr-square:before {
  content: "\f174";
}
.uk-icon-long-arrow-down:before {
  content: "\f175";
}
.uk-icon-long-arrow-up:before {
  content: "\f176";
}
.uk-icon-long-arrow-left:before {
  content: "\f177";
}
.uk-icon-long-arrow-right:before {
  content: "\f178";
}
.uk-icon-apple:before {
  content: "\f179";
}
.uk-icon-windows:before {
  content: "\f17a";
}
.uk-icon-android:before {
  content: "\f17b";
}
.uk-icon-linux:before {
  content: "\f17c";
}
.uk-icon-dribbble:before {
  content: "\f17d";
}
.uk-icon-skype:before {
  content: "\f17e";
}
.uk-icon-foursquare:before {
  content: "\f180";
}
.uk-icon-trello:before {
  content: "\f181";
}
.uk-icon-female:before {
  content: "\f182";
}
.uk-icon-male:before {
  content: "\f183";
}
.uk-icon-gittip:before,
.uk-icon-gratipay:before {
  content: "\f184";
}
.uk-icon-sun-o:before {
  content: "\f185";
}
.uk-icon-moon-o:before {
  content: "\f186";
}
.uk-icon-archive:before {
  content: "\f187";
}
.uk-icon-bug:before {
  content: "\f188";
}
.uk-icon-vk:before {
  content: "\f189";
}
.uk-icon-weibo:before {
  content: "\f18a";
}
.uk-icon-renren:before {
  content: "\f18b";
}
.uk-icon-pagelines:before {
  content: "\f18c";
}
.uk-icon-stack-exchange:before {
  content: "\f18d";
}
.uk-icon-arrow-circle-o-right:before {
  content: "\f18e";
}
.uk-icon-arrow-circle-o-left:before {
  content: "\f190";
}
.uk-icon-toggle-left:before,
.uk-icon-caret-square-o-left:before {
  content: "\f191";
}
.uk-icon-dot-circle-o:before {
  content: "\f192";
}
.uk-icon-wheelchair:before {
  content: "\f193";
}
.uk-icon-vimeo-square:before {
  content: "\f194";
}
.uk-icon-turkish-lira:before,
.uk-icon-try:before {
  content: "\f195";
}
.uk-icon-plus-square-o:before {
  content: "\f196";
}
.uk-icon-space-shuttle:before {
  content: "\f197";
}
.uk-icon-slack:before {
  content: "\f198";
}
.uk-icon-envelope-square:before {
  content: "\f199";
}
.uk-icon-wordpress:before {
  content: "\f19a";
}
.uk-icon-openid:before {
  content: "\f19b";
}
.uk-icon-institution:before,
.uk-icon-bank:before,
.uk-icon-university:before {
  content: "\f19c";
}
.uk-icon-mortar-board:before,
.uk-icon-graduation-cap:before {
  content: "\f19d";
}
.uk-icon-yahoo:before {
  content: "\f19e";
}
.uk-icon-google:before {
  content: "\f1a0";
}
.uk-icon-reddit:before {
  content: "\f1a1";
}
.uk-icon-reddit-square:before {
  content: "\f1a2";
}
.uk-icon-stumbleupon-circle:before {
  content: "\f1a3";
}
.uk-icon-stumbleupon:before {
  content: "\f1a4";
}
.uk-icon-delicious:before {
  content: "\f1a5";
}
.uk-icon-digg:before {
  content: "\f1a6";
}
.uk-icon-pied-piper:before {
  content: "\f1a7";
}
.uk-icon-pied-piper-alt:before {
  content: "\f1a8";
}
.uk-icon-drupal:before {
  content: "\f1a9";
}
.uk-icon-joomla:before {
  content: "\f1aa";
}
.uk-icon-language:before {
  content: "\f1ab";
}
.uk-icon-fax:before {
  content: "\f1ac";
}
.uk-icon-building:before {
  content: "\f1ad";
}
.uk-icon-child:before {
  content: "\f1ae";
}
.uk-icon-paw:before {
  content: "\f1b0";
}
.uk-icon-spoon:before {
  content: "\f1b1";
}
.uk-icon-cube:before {
  content: "\f1b2";
}
.uk-icon-cubes:before {
  content: "\f1b3";
}
.uk-icon-behance:before {
  content: "\f1b4";
}
.uk-icon-behance-square:before {
  content: "\f1b5";
}
.uk-icon-steam:before {
  content: "\f1b6";
}
.uk-icon-steam-square:before {
  content: "\f1b7";
}
.uk-icon-recycle:before {
  content: "\f1b8";
}
.uk-icon-automobile:before,
.uk-icon-car:before {
  content: "\f1b9";
}
.uk-icon-cab:before,
.uk-icon-taxi:before {
  content: "\f1ba";
}
.uk-icon-tree:before {
  content: "\f1bb";
}
.uk-icon-spotify:before {
  content: "\f1bc";
}
.uk-icon-deviantart:before {
  content: "\f1bd";
}
.uk-icon-soundcloud:before {
  content: "\f1be";
}
.uk-icon-database:before {
  content: "\f1c0";
}
.uk-icon-file-pdf-o:before {
  content: "\f1c1";
}
.uk-icon-file-word-o:before {
  content: "\f1c2";
}
.uk-icon-file-excel-o:before {
  content: "\f1c3";
}
.uk-icon-file-powerpoint-o:before {
  content: "\f1c4";
}
.uk-icon-file-photo-o:before,
.uk-icon-file-picture-o:before,
.uk-icon-file-image-o:before {
  content: "\f1c5";
}
.uk-icon-file-zip-o:before,
.uk-icon-file-archive-o:before {
  content: "\f1c6";
}
.uk-icon-file-sound-o:before,
.uk-icon-file-audio-o:before {
  content: "\f1c7";
}
.uk-icon-file-movie-o:before,
.uk-icon-file-video-o:before {
  content: "\f1c8";
}
.uk-icon-file-code-o:before {
  content: "\f1c9";
}
.uk-icon-vine:before {
  content: "\f1ca";
}
.uk-icon-codepen:before {
  content: "\f1cb";
}
.uk-icon-jsfiddle:before {
  content: "\f1cc";
}
.uk-icon-life-bouy:before,
.uk-icon-life-buoy:before,
.uk-icon-life-saver:before,
.uk-icon-support:before,
.uk-icon-life-ring:before {
  content: "\f1cd";
}
.uk-icon-circle-o-notch:before {
  content: "\f1ce";
}
.uk-icon-ra:before,
.uk-icon-rebel:before {
  content: "\f1d0";
}
.uk-icon-ge:before,
.uk-icon-empire:before {
  content: "\f1d1";
}
.uk-icon-git-square:before {
  content: "\f1d2";
}
.uk-icon-git:before {
  content: "\f1d3";
}
.uk-icon-hacker-news:before {
  content: "\f1d4";
}
.uk-icon-tencent-weibo:before {
  content: "\f1d5";
}
.uk-icon-qq:before {
  content: "\f1d6";
}
.uk-icon-wechat:before,
.uk-icon-weixin:before {
  content: "\f1d7";
}
.uk-icon-send:before,
.uk-icon-paper-plane:before {
  content: "\f1d8";
}
.uk-icon-send-o:before,
.uk-icon-paper-plane-o:before {
  content: "\f1d9";
}
.uk-icon-history:before {
  content: "\f1da";
}
.uk-icon-genderless:before,
.uk-icon-circle-thin:before {
  content: "\f1db";
}
.uk-icon-header:before {
  content: "\f1dc";
}
.uk-icon-paragraph:before {
  content: "\f1dd";
}
.uk-icon-sliders:before {
  content: "\f1de";
}
.uk-icon-share-alt:before {
  content: "\f1e0";
}
.uk-icon-share-alt-square:before {
  content: "\f1e1";
}
.uk-icon-bomb:before {
  content: "\f1e2";
}
.uk-icon-soccer-ball-o:before,
.uk-icon-futbol-o:before {
  content: "\f1e3";
}
.uk-icon-tty:before {
  content: "\f1e4";
}
.uk-icon-binoculars:before {
  content: "\f1e5";
}
.uk-icon-plug:before {
  content: "\f1e6";
}
.uk-icon-slideshare:before {
  content: "\f1e7";
}
.uk-icon-twitch:before {
  content: "\f1e8";
}
.uk-icon-yelp:before {
  content: "\f1e9";
}
.uk-icon-newspaper-o:before {
  content: "\f1ea";
}
.uk-icon-wifi:before {
  content: "\f1eb";
}
.uk-icon-calculator:before {
  content: "\f1ec";
}
.uk-icon-paypal:before {
  content: "\f1ed";
}
.uk-icon-google-wallet:before {
  content: "\f1ee";
}
.uk-icon-cc-visa:before {
  content: "\f1f0";
}
.uk-icon-cc-mastercard:before {
  content: "\f1f1";
}
.uk-icon-cc-discover:before {
  content: "\f1f2";
}
.uk-icon-cc-amex:before {
  content: "\f1f3";
}
.uk-icon-cc-paypal:before {
  content: "\f1f4";
}
.uk-icon-cc-stripe:before {
  content: "\f1f5";
}
.uk-icon-bell-slash:before {
  content: "\f1f6";
}
.uk-icon-bell-slash-o:before {
  content: "\f1f7";
}
.uk-icon-trash:before {
  content: "\f1f8";
}
.uk-icon-copyright:before {
  content: "\f1f9";
}
.uk-icon-at:before {
  content: "\f1fa";
}
.uk-icon-eyedropper:before {
  content: "\f1fb";
}
.uk-icon-paint-brush:before {
  content: "\f1fc";
}
.uk-icon-birthday-cake:before {
  content: "\f1fd";
}
.uk-icon-area-chart:before {
  content: "\f1fe";
}
.uk-icon-pie-chart:before {
  content: "\f200";
}
.uk-icon-line-chart:before {
  content: "\f201";
}
.uk-icon-lastfm:before {
  content: "\f202";
}
.uk-icon-lastfm-square:before {
  content: "\f203";
}
.uk-icon-toggle-off:before {
  content: "\f204";
}
.uk-icon-toggle-on:before {
  content: "\f205";
}
.uk-icon-bicycle:before {
  content: "\f206";
}
.uk-icon-bus:before {
  content: "\f207";
}
.uk-icon-ioxhost:before {
  content: "\f208";
}
.uk-icon-angellist:before {
  content: "\f209";
}
.uk-icon-cc:before {
  content: "\f20a";
}
.uk-icon-shekel:before,
.uk-icon-sheqel:before,
.uk-icon-ils:before {
  content: "\f20b";
}
.uk-icon-meanpath:before {
  content: "\f20c";
}
.uk-icon-buysellads:before {
  content: "\f20d";
}
.uk-icon-connectdevelop:before {
  content: "\f20e";
}
.uk-icon-dashcube:before {
  content: "\f210";
}
.uk-icon-forumbee:before {
  content: "\f211";
}
.uk-icon-leanpub:before {
  content: "\f212";
}
.uk-icon-sellsy:before {
  content: "\f213";
}
.uk-icon-shirtsinbulk:before {
  content: "\f214";
}
.uk-icon-simplybuilt:before {
  content: "\f215";
}
.uk-icon-skyatlas:before {
  content: "\f216";
}
.uk-icon-cart-plus:before {
  content: "\f217";
}
.uk-icon-cart-arrow-down:before {
  content: "\f218";
}
.uk-icon-diamond:before {
  content: "\f219";
}
.uk-icon-ship:before {
  content: "\f21a";
}
.uk-icon-user-secret:before {
  content: "\f21b";
}
.uk-icon-motorcycle:before {
  content: "\f21c";
}
.uk-icon-street-view:before {
  content: "\f21d";
}
.uk-icon-heartbeat:before {
  content: "\f21e";
}
.uk-icon-venus:before {
  content: "\f221";
}
.uk-icon-mars:before {
  content: "\f222";
}
.uk-icon-mercury:before {
  content: "\f223";
}
.uk-icon-transgender:before {
  content: "\f224";
}
.uk-icon-transgender-alt:before {
  content: "\f225";
}
.uk-icon-venus-double:before {
  content: "\f226";
}
.uk-icon-mars-double:before {
  content: "\f227";
}
.uk-icon-venus-mars:before {
  content: "\f228";
}
.uk-icon-mars-stroke:before {
  content: "\f229";
}
.uk-icon-mars-stroke-v:before {
  content: "\f22a";
}
.uk-icon-mars-stroke-h:before {
  content: "\f22b";
}
.uk-icon-neuter:before {
  content: "\f22c";
}
.uk-icon-facebook-official:before {
  content: "\f230";
}
.uk-icon-pinterest-p:before {
  content: "\f231";
}
.uk-icon-whatsapp:before {
  content: "\f232";
}
.uk-icon-server:before {
  content: "\f233";
}
.uk-icon-user-plus:before {
  content: "\f234";
}
.uk-icon-user-times:before {
  content: "\f235";
}
.uk-icon-hotel:before,
.uk-icon-bed:before {
  content: "\f236";
}
.uk-icon-viacoin:before {
  content: "\f237";
}
.uk-icon-train:before {
  content: "\f238";
}
.uk-icon-subway:before {
  content: "\f239";
}
.uk-icon-medium-logo:before {
  content: "\f23a";
}
.uk-icon-500px:before {
  content: "\f26e";
}
.uk-icon-amazon:before {
  content: "\f270";
}
.uk-icon-balance-scale:before {
  content: "\f24e";
}
.uk-icon-battery-empty:before,
.uk-icon-battery-0:before {
  content: "\f244";
}
.uk-icon-battery-quarter:before,
.uk-icon-battery-1:before {
  content: "\f243";
}
.uk-icon-battery-half:before,
.uk-icon-battery-2:before {
  content: "\f242";
}
.uk-icon-battery-three-quarters:before,
.uk-icon-battery-3:before {
  content: "\f241";
}
.uk-icon-battery-full:before,
.uk-icon-battery-4:before {
  content: "\f240";
}
.uk-icon-black-tie:before {
  content: "\f27e";
}
.uk-icon-calendar-check-o:before {
  content: "\f274";
}
.uk-icon-calendar-minus-o:before {
  content: "\f272";
}
.uk-icon-calendar-plus-o:before {
  content: "\f271";
}
.uk-icon-calendar-times-o:before {
  content: "\f273";
}
.uk-icon-cc-diners-club:before {
  content: "\f24c";
}
.uk-icon-cc-jcb:before {
  content: "\f24b";
}
.uk-icon-chrome:before {
  content: "\f268";
}
.uk-icon-clone:before {
  content: "\f24d";
}
.uk-icon-commenting:before {
  content: "\f27a";
}
.uk-icon-commenting-o:before {
  content: "\f27b";
}
.uk-icon-contao:before {
  content: "\f26d";
}
.uk-icon-creative-commons:before {
  content: "\f25e";
}
.uk-icon-expeditedssl:before {
  content: "\f23e";
}
.uk-icon-firefox:before {
  content: "\f269";
}
.uk-icon-fonticons:before {
  content: "\f280";
}
.uk-icon-get-pocket:before {
  content: "\f265";
}
.uk-icon-gg:before {
  content: "\f260";
}
.uk-icon-gg-circle:before {
  content: "\f261";
}
.uk-icon-hand-lizard-o:before {
  content: "\f258";
}
.uk-icon-hand-stop-o:before,
.uk-icon-hand-paper-o:before {
  content: "\f256";
}
.uk-icon-hand-peace-o:before {
  content: "\f25b";
}
.uk-icon-hand-pointer-o:before {
  content: "\f25a";
}
.uk-icon-hand-grab-o:before,
.uk-icon-hand-rock-o:before {
  content: "\f255";
}
.uk-icon-hand-scissors-o:before {
  content: "\f257";
}
.uk-icon-hand-spock-o:before {
  content: "\f259";
}
.uk-icon-hourglass:before {
  content: "\f254";
}
.uk-icon-hourglass-o:before {
  content: "\f250";
}
.uk-icon-hourglass-1:before,
.uk-icon-hourglass-start:before {
  content: "\f251";
}
.uk-icon-hourglass-2:before,
.uk-icon-hourglass-half:before {
  content: "\f252";
}
.uk-icon-hourglass-3:before,
.uk-icon-hourglass-end:before {
  content: "\f253";
}
.uk-icon-houzz:before {
  content: "\f27c";
}
.uk-icon-i-cursor:before {
  content: "\f246";
}
.uk-icon-industry:before {
  content: "\f275";
}
.uk-icon-internet-explorer:before {
  content: "\f26b";
}
.uk-icon-map:before {
  content: "\f279";
}
.uk-icon-map-o:before {
  content: "\f278";
}
.uk-icon-map-pin:before {
  content: "\f276";
}
.uk-icon-map-signs:before {
  content: "\f277";
}
.uk-icon-mouse-pointer:before {
  content: "\f245";
}
.uk-icon-object-group:before {
  content: "\f247";
}
.uk-icon-object-ungroup:before {
  content: "\f248";
}
.uk-icon-odnoklassniki:before {
  content: "\f263";
}
.uk-icon-odnoklassniki-square:before {
  content: "\f264";
}
.uk-icon-opencart:before {
  content: "\f23d";
}
.uk-icon-opera:before {
  content: "\f26a";
}
.uk-icon-optin-monster:before {
  content: "\f23c";
}
.uk-icon-registered:before {
  content: "\f25d";
}
.uk-icon-safari:before {
  content: "\f267";
}
.uk-icon-sticky-note:before {
  content: "\f249";
}
.uk-icon-sticky-note-o:before {
  content: "\f24a";
}
.uk-icon-tv:before,
.uk-icon-television:before {
  content: "\f26c";
}
.uk-icon-trademark:before {
  content: "\f25c";
}
.uk-icon-tripadvisor:before {
  content: "\f262";
}
.uk-icon-vimeo:before {
  content: "\f27d";
}
.uk-icon-wikipedia-w:before {
  content: "\f266";
}
.uk-icon-yc:before,
.uk-icon-y-combinator:before {
  content: "\f23b";
}
.uk-icon-yc-square:before,
.uk-icon-y-combinator-square:before {
  content: "\f1d4";
}
.uk-icon-bluetooth:before {
  content: "\f293";
}
.uk-icon-bluetooth-b:before {
  content: "\f294";
}
.uk-icon-codiepie:before {
  content: "\f284";
}
.uk-icon-credit-card-alt:before {
  content: "\f283";
}
.uk-icon-edge:before {
  content: "\f282";
}
.uk-icon-fort-awesome:before {
  content: "\f286";
}
.uk-icon-hashtag:before {
  content: "\f292";
}
.uk-icon-mixcloud:before {
  content: "\f289";
}
.uk-icon-modx:before {
  content: "\f285";
}
.uk-icon-pause-circle:before {
  content: "\f28b";
}
.uk-icon-pause-circle-o:before {
  content: "\f28c";
}
.uk-icon-percent:before {
  content: "\f295";
}
.uk-icon-product-hunt:before {
  content: "\f288";
}
.uk-icon-reddit-alien:before {
  content: "\f281";
}
.uk-icon-scribd:before {
  content: "\f28a";
}
.uk-icon-shopping-bag:before {
  content: "\f290";
}
.uk-icon-shopping-basket:before {
  content: "\f291";
}
.uk-icon-stop-circle:before {
  content: "\f28d";
}
.uk-icon-stop-circle-o:before {
  content: "\f28e";
}
.uk-icon-usb:before {
  content: "\f287";
}
.tm-icon-large {
  font-size: 50px;
}
/* ========================================================================
   Component: Close
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.uk-close::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * 1. Correct inability to style clickable `input` types in iOS.
 * 2. Remove margins in Chrome, Safari and Opera.
 * 3. Remove borders for `button`.
 * 4. Address `overflow` set to `hidden` in IE 8/9/10/11.
 * 5. Correct `font` properties and `color` not being inherited for `button`.
 * 6. Address inconsistent `text-transform` inheritance which is only inherit in Firefox and IE
 * 7. Remove default `button` padding and background color
 * 8. Style
 */
.uk-close {
  /* 1 */
  -webkit-appearance: none;
  /* 2 */
  margin: 0;
  /* 3 */
  border: none;
  /* 4 */
  overflow: visible;
  /* 5 */
  font: inherit;
  color: inherit;
  /* 6 */
  text-transform: none;
  /* 7. */
  padding: 0;
  background: transparent;
  /* 8 */
  display: inline-block;
  box-sizing: content-box;
  width: 20px;
  line-height: 20px;
  text-align: center;
  vertical-align: middle;
  opacity: 0.3;
  opacity: 1;
}
/* Icon */
.uk-close:after {
  display: block;
  content: "\f00d";
  font-family: FontAwesome;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 */
.uk-close:hover,
.uk-close:focus {
  opacity: 0.5;
  /* 2 */
  outline: none;
  /* 3 */
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
/* Modifier
 ========================================================================== */
.uk-close-alt {
  padding: 2px;
  border-radius: 50%;
  background: #eeeeee;
  opacity: 1;
  background-color: #ffffff;
  padding: 5px;
}
/* Hover */
.uk-close-alt:hover,
.uk-close-alt:focus {
  opacity: 1;
}
/* Icon */
.uk-close-alt:after {
  opacity: 0.5;
}
.uk-close-alt:hover:after,
.uk-close-alt:focus:after {
  opacity: 0.8;
}
.uk-alert .uk-close:after {
  margin-top: 5px;
}
/* ========================================================================
   Component: Badge
 ========================================================================== */
.uk-badge {
  display: inline-block;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0);
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  color: #258bce;
  text-align: center;
  vertical-align: middle;
  text-transform: lowercase;
  border-radius: 3px;
  border: 1px solid #258bce;
  text-transform: uppercase;
  letter-spacing: 1px;
}
/*
 * Keep color when badge is a link
 */
a.uk-badge:hover {
  color: #ffffff;
}
/* Modifier: `uk-badge-notification`;
 ========================================================================== */
.uk-badge-notification {
  box-sizing: border-box;
  min-width: 21px;
  border-radius: 500px;
  font-size: 14px;
  line-height: 21px;
}
/* Color modifier
 ========================================================================== */
/*
 * Modifier: `uk-badge-success`
 */
.uk-badge-success {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #84c118;
  color: #84c118;
}
/*
 * Modifier: `uk-badge-warning`
 */
.uk-badge-warning {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ff9926;
  color: #ff9926;
}
/*
 * Modifier: `uk-badge-danger`
 */
.uk-badge-danger {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #da314b;
  color: #da314b;
}
.uk-badge-notification {
  padding: 0 6px;
}
/* ========================================================================
   Component: Alert
 ========================================================================== */
.uk-alert {
  margin-bottom: 20px;
  padding: 10px;
  background: rgba(37, 139, 206, 0.02);
  color: #258bce;
  box-shadow: inset 0 0 0 1px rgba(37, 139, 206, 0.2), inset 4px 0 0 #258bce;
  border-radius: 3px;
  padding-left: 20px;
}
/*
 * Add margin if adjacent element
 */
* + .uk-alert {
  margin-top: 20px;
}
/*
 * Remove margin from the last-child
 */
.uk-alert > :last-child {
  margin-bottom: 0;
}
/*
 * Keep color for headings if the default heading color is changed
 */
.uk-alert h1,
.uk-alert h2,
.uk-alert h3,
.uk-alert h4,
.uk-alert h5,
.uk-alert h6 {
  color: inherit;
}
/* Close in alert
 ========================================================================== */
.uk-alert > .uk-close:first-child {
  float: right;
}
/*
 * Remove margin from adjacent element
 */
.uk-alert > .uk-close:first-child + * {
  margin-top: 0;
}
/* Modifier: `uk-alert-success`
 ========================================================================== */
.uk-alert-success {
  background: rgba(132, 193, 24, 0.02);
  color: #84c118;
  box-shadow: inset 0 0 0 1px rgba(132, 193, 24, 0.2), inset 4px 0 0 #84c118;
}
/* Modifier: `uk-alert-warning`
 ========================================================================== */
.uk-alert-warning {
  background: rgba(255, 153, 38, 0.02);
  color: #ff9926;
  box-shadow: inset 0 0 0 1px rgba(255, 153, 38, 0.2), inset 4px 0 0 #ff9926;
}
/* Modifier: `uk-alert-danger`
 ========================================================================== */
.uk-alert-danger {
  background: rgba(218, 49, 75, 0.02);
  color: #da314b;
  box-shadow: inset 0 0 0 1px rgba(218, 49, 75, 0.2), inset 4px 0 0 #da314b;
}
/* Modifier: `uk-alert-large`
 ========================================================================== */
.uk-alert-large {
  padding: 20px;
}
.uk-alert-large > .uk-close:first-child {
  margin: -10px -10px 0 0;
}
.uk-alert .uk-module-title:after {
  border-color: inherit;
}
/* ========================================================================
   Component: Thumbnail
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Responsive behavior
 * 3. Corrects `max-width` behavior sed
 * 4. Required for `figure` element
 * 5. Style
 */
.uk-thumbnail {
  /* 1 */
  display: inline-block;
  /* 2 */
  max-width: 100%;
  /* 3 */
  box-sizing: border-box;
  /* 3 */
  margin: 0;
  /* 4 */
  padding: 4px;
  border: 1px solid #eef4ff;
  background: rgba(0, 0, 0, 0);
}
/*
 * Hover state for `a` elements
 * 1. Apply hover style also to focus state
 * 2. Needed for caption
 * 3. Remove default focus style
 */
a.uk-thumbnail:hover,
a.uk-thumbnail:focus {
  border-color: rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  /* 2 */
  text-decoration: none;
  /* 3 */
  outline: none;
}
/* Caption
 ========================================================================== */
.uk-thumbnail-caption {
  padding-top: 4px;
  text-align: center;
  color: #2a455d;
  padding: 10px;
}
/* Sizes
 ========================================================================== */
.uk-thumbnail-mini {
  width: 150px;
}
.uk-thumbnail-small {
  width: 200px;
}
.uk-thumbnail-medium {
  width: 300px;
}
.uk-thumbnail-large {
  width: 400px;
}
.uk-thumbnail-expand,
.uk-thumbnail-expand > img {
  width: 100%;
}
/* ========================================================================
   Component: Overlay
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Set max-width for responsive images to prevent `inline-block` consequences
 * 4. Remove the gap between the container and its child element
 * 5. Needed for transitions and to fixed wrong scaling calculation for images in Chrome
 * 6. Fixed `overflow: hidden` to be ignored with border-radius and CSS transforms in Webkit
 * 7. Reset margin
 */
.uk-overlay {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
  /* 4 */
  vertical-align: middle;
  /* 5 */
  overflow: hidden;
  /* 6 */
  -webkit-transform: translateZ(0);
  /* 7 */
  margin: 0;
}
/* 6 for Safari */
.uk-overlay.uk-border-circle {
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
}
/*
 * Remove margin from content
 */
.uk-overlay > :first-child {
  margin-bottom: 0;
}
/* Sub-object `uk-overlay-panel`
 ========================================================================== */
/*
 * 1. Position cover
 * 2. Style
 */
.uk-overlay-panel {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  padding: 30px;
  color: #ffffff;
}
/*
 * Remove margin from the last-child
 */
.uk-overlay-panel > :last-child,
.uk-overlay-panel.uk-flex > * > :last-child {
  margin-bottom: 0;
}
/*
 * Keep color for headings if the default heading color is changed
 */
.uk-overlay-panel h1,
.uk-overlay-panel h2,
.uk-overlay-panel h3,
.uk-overlay-panel h4,
.uk-overlay-panel h5,
.uk-overlay-panel h6 {
  color: inherit;
}
.uk-overlay-panel a:not([class]) {
  color: inherit;
  text-decoration: underline;
}
.uk-overlay-panel a[class*='uk-icon-']:not(.uk-icon-button) {
  color: inherit;
}
/* Sub-object `uk-overlay-hover` and `uk-overlay-active`
 ========================================================================== */
.uk-overlay-hover:not(:hover):not(.uk-hover) .uk-overlay-panel:not(.uk-ignore) {
  opacity: 0;
}
.uk-overlay-active :not(.uk-active) > .uk-overlay-panel:not(.uk-ignore) {
  opacity: 0;
}
/* Modifier `uk-overlay-background`
 ========================================================================== */
.uk-overlay-background {
  background: rgba(37, 139, 206, 0.5);
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.05);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(37, 167, 206, 0.5)), color-stop(100%, rgba(37, 111, 206, 0.5))) rgba(37, 139, 206, 0.5);
  background: -webkit-linear-gradient(top, rgba(37, 167, 206, 0.5) 0%, rgba(37, 111, 206, 0.5) 100%) rgba(37, 139, 206, 0.5);
  background: -moz-linear-gradient(top, rgba(37, 167, 206, 0.5) 0%, rgba(37, 111, 206, 0.5) 100%) rgba(37, 139, 206, 0.5);
  background: -o-linear-gradient(top, rgba(37, 167, 206, 0.5) 0%, rgba(37, 111, 206, 0.5) 100%) rgba(37, 139, 206, 0.5);
  background: -ms-linear-gradient(top, rgba(37, 167, 206, 0.5) 0%, rgba(37, 111, 206, 0.5) 100%) rgba(37, 139, 206, 0.5);
  background: linear-gradient(to bottom, rgba(37, 167, 206, 0.5) 0%, rgba(37, 111, 206, 0.5) 100%) rgba(37, 139, 206, 0.5);
}
/* Modifier `uk-overlay-image`
 ========================================================================== */
/*
 * Reset panel
 */
.uk-overlay-image {
  padding: 0;
}
/* Position modifiers
 ========================================================================== */
.uk-overlay-top {
  bottom: auto;
}
.uk-overlay-bottom {
  top: auto;
}
.uk-overlay-left {
  right: auto;
}
.uk-overlay-right {
  left: auto;
}
/* Sub-object `uk-overlay-icon`
 ========================================================================== */
.uk-overlay-icon:before {
  content: "\e626";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  font-size: 30px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #ffffff;
  font-family: "sandal-icons";
  text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.1);
  margin-top: 0;
  top: 55%;
  opacity: 0;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/* Transitions
 ========================================================================== */
.uk-overlay-fade,
.uk-overlay-scale,
.uk-overlay-spin,
.uk-overlay-grayscale,
.uk-overlay-blur,
[class*='uk-overlay-slide'] {
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  transition-property: opacity, transform, filter;
}
.uk-overlay-active .uk-overlay-fade,
.uk-overlay-active .uk-overlay-scale,
.uk-overlay-active .uk-overlay-spin,
.uk-overlay-active [class*='uk-overlay-slide'] {
  transition-duration: 0.8s;
}
/*
 * Fade
 */
.uk-overlay-fade {
  opacity: 0.7;
}
.uk-overlay-hover:hover .uk-overlay-fade,
.uk-overlay-hover.uk-hover .uk-overlay-fade,
.uk-overlay-active .uk-active > .uk-overlay-fade {
  opacity: 1;
}
/*
 * Scale
 */
.uk-overlay-scale {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.uk-overlay-hover:hover .uk-overlay-scale,
.uk-overlay-hover.uk-hover .uk-overlay-scale,
.uk-overlay-active .uk-active > .uk-overlay-scale {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/*
 * Spin
 */
.uk-overlay-spin {
  -webkit-transform: scale(1) rotate(0deg);
  transform: scale(1) rotate(0deg);
}
.uk-overlay-hover:hover .uk-overlay-spin,
.uk-overlay-hover.uk-hover .uk-overlay-spin,
.uk-overlay-active .uk-active > .uk-overlay-spin {
  -webkit-transform: scale(1.1) rotate(3deg);
  transform: scale(1.1) rotate(3deg);
}
/*
 * Grayscale
 */
.uk-overlay-grayscale {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.uk-overlay-hover:hover .uk-overlay-grayscale,
.uk-overlay-hover.uk-hover .uk-overlay-grayscale,
.uk-overlay-active .uk-active > .uk-overlay-grayscale {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
/*
 * Slide
 */
[class*='uk-overlay-slide'] {
  opacity: 0;
}
/* Top */
.uk-overlay-slide-top {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
/* Bottom */
.uk-overlay-slide-bottom {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
/* Left */
.uk-overlay-slide-left {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}
/* Right */
.uk-overlay-slide-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
/* Hover */
.uk-overlay-hover:hover [class*='uk-overlay-slide'],
.uk-overlay-hover.uk-hover [class*='uk-overlay-slide'],
.uk-overlay-active .uk-active > [class*='uk-overlay-slide'] {
  opacity: 1;
  -webkit-transform: translateX(0) translateY(0);
  transform: translateX(0) translateY(0);
}
/* DEPRECATED
 * Sub-object `uk-overlay-area`
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set style
 * 3. Fade-in transition
 */
.uk-overlay-area {
  /* 1 */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  background: rgba(0, 0, 0, 0.3);
  /* 3 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0);
}
/*
 * Hover
 * 1. `uk-hover` to support touch devices
 * 2. Use optional `uk-overlay-toggle` to trigger the overlay earlier
 */
.uk-overlay:hover .uk-overlay-area,
.uk-overlay.uk-hover .uk-overlay-area,
.uk-overlay-toggle:hover .uk-overlay-area,
.uk-overlay-toggle.uk-hover .uk-overlay-area {
  opacity: 1;
}
/*
 * Icon
 */
.uk-overlay-area:empty:before {
  content: "\f002";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-top: -25px;
  margin-left: -25px;
  font-size: 50px;
  line-height: 1;
  font-family: FontAwesome;
  text-align: center;
  color: #ffffff;
}
/* DEPRECATED
 * Sub-object `uk-overlay-area-content`
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 * Needed for Firefox
 */
.uk-overlay-area:not(:empty) {
  font-size: 0.001px;
}
/*
 * 1. Needed for vertical alignment
 */
.uk-overlay-area:not(:empty):before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/*
 * 1. Set vertical alignment
 * 2. Reset whitespace hack
 * 3. Set horizontal alignment
 * 4. Set style
 */
.uk-overlay-area-content {
  /* 1 */
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  vertical-align: middle;
  /* 2 */
  font-size: 1rem;
  /* 3 */
  text-align: center;
  /* 4 */
  padding: 0 15px;
  color: #ffffff;
}
/*
 * Remove margin from the last-child
 */
.uk-overlay-area-content > :last-child {
  margin-bottom: 0;
}
/*
 * Links in overlay area
 */
.uk-overlay-area-content a:not([class]),
.uk-overlay-area-content a:not([class]):hover {
  color: inherit;
}
/* DEPRECATED
 * Sub-object `uk-overlay-caption`
 ========================================================================== */
/*
 * 1. Set position
 * 2. Set style
 * 3. Fade-in transition
 */
.uk-overlay-caption {
  /* 1 */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  /* 2 */
  padding: 15px;
  background: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  /* 3 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  -webkit-transform: translate3d(0, 0, 0);
}
/*
 * Hover
 * 1. `uk-hover` to support touch devices
 * 2. Use optional `uk-overlay-toggle` to trigger the overlay earlier
 */
.uk-overlay:hover .uk-overlay-caption,
.uk-overlay.uk-hover .uk-overlay-caption,
.uk-overlay-toggle:hover .uk-overlay-caption,
.uk-overlay-toggle.uk-hover .uk-overlay-caption {
  opacity: 1;
}
.uk-overlay > img.uk-thumbnail {
  max-width: 95%;
  max-width: calc(100% - 10px);
  margin: 5px;
}
.uk-overlay-background.tm-overlay-secondary {
  background-color: #223148;
}
.uk-overlay-active .uk-overlay-fade,
.uk-overlay-active .uk-overlay-scale,
.uk-overlay-active .uk-overlay-spin,
.uk-overlay-active [class*='uk-overlay-slide'] {
  transition-duration: 0.4s;
}
.tm-overlay-box:before,
.tm-overlay-box:after {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  content: '';
  opacity: 0;
  z-index: 1;
  -webkit-transition: opacity 0.35s ease, -webkit-transform 0.35s ease;
  transition: opacity .35s ease, transform .35s ease;
}
.tm-overlay-box:before {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
.tm-overlay-box:after {
  border-right: 1px solid #ffffff;
  border-left: 1px solid #ffffff;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}
.uk-overlay-hover:hover .tm-overlay-box:before,
.uk-overlay-hover:hover .tm-overlay-box:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease 0.2s;
  transition: opacity .4s ease, transform .4s ease .2s;
}
.uk-overlay-hover:hover .uk-overlay-icon:before {
  top: 50%;
  opacity: 0.9;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition-duration: 0.75s;
}
.uk-overlay-scale {
  transition-duration: 1s;
}
.uk-overlay-slide-top {
  -webkit-transform: translateY(-5%);
  transform: translateY(-5%);
}
.uk-overlay-slide-bottom {
  -webkit-transform: translateY(5%);
  transform: translateY(5%);
}
.uk-overlay-slide-left {
  -webkit-transform: translateX(-5%);
  transform: translateX(-5%);
}
.uk-overlay-slide-right {
  -webkit-transform: translateX(5%);
  transform: translateX(5%);
}
/* ========================================================================
   Component: Column
 ========================================================================== */
[class*='uk-column-'] {
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}
/* Width modifiers
 ========================================================================== */
.uk-column-1-2 {
  -webkit-column-count: 2;
  -moz-column-count: 2;
  column-count: 2;
}
.uk-column-1-3 {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
}
.uk-column-1-4 {
  -webkit-column-count: 4;
  -moz-column-count: 4;
  column-count: 4;
}
.uk-column-1-5 {
  -webkit-column-count: 5;
  -moz-column-count: 5;
  column-count: 5;
}
.uk-column-1-6 {
  -webkit-column-count: 6;
  -moz-column-count: 6;
  column-count: 6;
}
/* Phone landscape and bigger */
@media (min-width: 480px) {
  .uk-column-small-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-small-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-small-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-small-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-small-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-column-medium-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-medium-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-medium-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-medium-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-medium-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-column-large-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-large-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-large-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-large-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-large-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-column-xlarge-1-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
  .uk-column-xlarge-1-3 {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
  .uk-column-xlarge-1-4 {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
  .uk-column-xlarge-1-5 {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
  .uk-column-xlarge-1-6 {
    -webkit-column-count: 6;
    -moz-column-count: 6;
    column-count: 6;
  }
}
/* ========================================================================
   Component: Animation
 ========================================================================== */
[class*='uk-animation-'] {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
/* Hide animated element if scrollspy is used */
@media screen {
  [data-uk-scrollspy*='uk-animation-']:not([data-uk-scrollspy*='target']) {
    opacity: 0;
  }
}
/*
 * Fade
 * Higher specificity (!important) needed because of reverse modifier
 */
.uk-animation-fade {
  -webkit-animation-name: uk-fade;
  animation-name: uk-fade;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-timing-function: linear !important;
  animation-timing-function: linear !important;
}
/*
 * Fade with scale
 */
.uk-animation-scale-up {
  -webkit-animation-name: uk-fade-scale-02;
  animation-name: uk-fade-scale-02;
}
.uk-animation-scale-down {
  -webkit-animation-name: uk-fade-scale-18;
  animation-name: uk-fade-scale-18;
}
/*
 * Fade with slide
 */
.uk-animation-slide-top {
  -webkit-animation-name: uk-fade-top;
  animation-name: uk-fade-top;
}
.uk-animation-slide-bottom {
  -webkit-animation-name: uk-fade-bottom;
  animation-name: uk-fade-bottom;
}
.uk-animation-slide-left {
  -webkit-animation-name: uk-fade-left;
  animation-name: uk-fade-left;
}
.uk-animation-slide-right {
  -webkit-animation-name: uk-fade-right;
  animation-name: uk-fade-right;
}
/*
 * Scale
 */
.uk-animation-scale {
  -webkit-animation-name: uk-scale-12;
  animation-name: uk-scale-12;
}
/*
 * Shake
 */
.uk-animation-shake {
  -webkit-animation-name: uk-shake;
  animation-name: uk-shake;
}
/* Direction modifiers
 ========================================================================== */
.uk-animation-reverse {
  -webkit-animation-direction: reverse;
  animation-direction: reverse;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}
/* Duration modifiers
========================================================================== */
.uk-animation-15 {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
}
/* Origin modifiers
========================================================================== */
.uk-animation-top-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.uk-animation-top-center {
  -webkit-transform-origin: 50% 0;
  transform-origin: 50% 0;
}
.uk-animation-top-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.uk-animation-middle-left {
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
}
.uk-animation-middle-right {
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}
.uk-animation-bottom-left {
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}
.uk-animation-bottom-center {
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}
.uk-animation-bottom-right {
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
/* Sub-object: `uk-animation-hover`
========================================================================== */
/*
 * Enable animation only on hover
 * Note: Firefox also needs this because animations are not triggered when switching between display `hidden` and `block`
 */
.uk-animation-hover:not(:hover),
.uk-animation-hover:not(:hover) [class*='uk-animation-'],
.uk-touch .uk-animation-hover:not(.uk-hover),
.uk-touch .uk-animation-hover:not(.uk-hover) [class*='uk-animation-'] {
  -webkit-animation-name: none;
  animation-name: none;
}
/* Keyframes: Fade
 * Used by dropdown, datepicker and slideshow component
 ========================================================================== */
@-webkit-keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes uk-fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* Keyframes: Fade with slide
 ========================================================================== */
/*
 * Top
 */
@-webkit-keyframes uk-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Bottom
 */
@-webkit-keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Left
 */
@-webkit-keyframes uk-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/*
 * Right
 */
@-webkit-keyframes uk-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
/* Keyframes: Fade with scale
 ========================================================================== */
/*
 * Scale by 0.2
 */
@-webkit-keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.2);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-02 {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Scale by 1.5
 * Used by slideshow component
 */
@-webkit-keyframes uk-fade-scale-15 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-15 {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/*
 * Scale by 1.8
 */
@-webkit-keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    -webkit-transform: scale(1.8);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}
@keyframes uk-fade-scale-18 {
  0% {
    opacity: 0;
    transform: scale(1.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
/* Keyframes: Slide
 * Used by slideshow component
 ========================================================================== */
/*
 * Left
 */
@-webkit-keyframes uk-slide-left {
  0% {
    -webkit-transform: translateX(-100%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-left {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Right
 */
@-webkit-keyframes uk-slide-right {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Left third
 */
@-webkit-keyframes uk-slide-left-33 {
  0% {
    -webkit-transform: translateX(33%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-left-33 {
  0% {
    transform: translateX(33%);
  }
  100% {
    transform: translateX(0);
  }
}
/*
 * Right third
 */
@-webkit-keyframes uk-slide-right-33 {
  0% {
    -webkit-transform: translateX(-33%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-slide-right-33 {
  0% {
    transform: translateX(-33%);
  }
  100% {
    transform: translateX(0);
  }
}
/* Keyframes: Scale
 ========================================================================== */
@-webkit-keyframes uk-scale-12 {
  0% {
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@keyframes uk-scale-12 {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
/* Keyframes: Rotate
 * Used by icon component
 ========================================================================== */
@-webkit-keyframes uk-rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}
@keyframes uk-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
/* Keyframes: Shake
 ========================================================================== */
@-webkit-keyframes uk-shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
  }
  10% {
    -webkit-transform: translateX(-9px);
  }
  20% {
    -webkit-transform: translateX(8px);
  }
  30% {
    -webkit-transform: translateX(-7px);
  }
  40% {
    -webkit-transform: translateX(6px);
  }
  50% {
    -webkit-transform: translateX(-5px);
  }
  60% {
    -webkit-transform: translateX(4px);
  }
  70% {
    -webkit-transform: translateX(-3px);
  }
  80% {
    -webkit-transform: translateX(2px);
  }
  90% {
    -webkit-transform: translateX(-1px);
  }
}
@keyframes uk-shake {
  0%,
  100% {
    transform: translateX(0);
  }
  10% {
    transform: translateX(-9px);
  }
  20% {
    transform: translateX(8px);
  }
  30% {
    transform: translateX(-7px);
  }
  40% {
    transform: translateX(6px);
  }
  50% {
    transform: translateX(-5px);
  }
  60% {
    transform: translateX(4px);
  }
  70% {
    transform: translateX(-3px);
  }
  80% {
    transform: translateX(2px);
  }
  90% {
    transform: translateX(-1px);
  }
}
/* Keyframes: Fade with slide fixed
 * Used by dropdown and search component
 ========================================================================== */
/*
 * Top fixed
 */
@-webkit-keyframes uk-slide-top-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-slide-top-fixed {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/*
 * Bottom fixed
 */
@-webkit-keyframes uk-slide-bottom-fixed {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-slide-bottom-fixed {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
/* ========================================================================
   Component: Dropdown
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set position
 * 3. Box-sizing is needed for `uk-dropdown-justify`
 * 4. Set width
 */
.uk-dropdown,
.uk-dropdown-blank {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1020;
  /* 3 */
  box-sizing: border-box;
  /* 4 */
  width: 250px;
}
/*
 * Dropdown style
 * 1. Reset button group whitespace hack
 */
.uk-dropdown {
  padding: 25px;
  background: #ffffff;
  color: #2a455d;
  /* 1 */
  font-size: 1rem;
  vertical-align: top;
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 rgba(0, 0, 0, 0.1), 2px 2px 1px rgba(0, 0, 0, 0.05);
}
/*
 * 1. Show dropdown
 * 2. Set animation
 * 3. Needed for scale animation
 */
.uk-open > .uk-dropdown,
.uk-open > .uk-dropdown-blank {
  /* 1 */
  display: block;
  /* 2 */
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
  /* 3 */
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Alignment modifiers
 ========================================================================== */
/*
 * Modifier
 */
.uk-dropdown-top {
  margin-top: -5px;
}
.uk-dropdown-bottom {
  margin-top: 5px;
}
.uk-dropdown-left {
  margin-left: -5px;
}
.uk-dropdown-right {
  margin-left: 5px;
}
/* Nav in dropdown
 ========================================================================== */
.uk-dropdown .uk-nav {
  margin: 0 -25px;
}
/* Grid and panel in dropdown
 ========================================================================== */
/*
* Vertical gutter
*/
/*
 * Grid
 * Higher specificity to override large gutter
 */
.uk-grid .uk-dropdown-grid + .uk-dropdown-grid {
  margin-top: 25px;
}
/* Panels */
.uk-dropdown-grid > [class*='uk-width-'] > .uk-panel + .uk-panel {
  margin-top: 25px;
}
/* Tablet and bigger */
@media (min-width: 900px) {
  /*
     * Horizontal gutter
     */
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid {
    margin-left: -25px;
    margin-right: -25px;
  }
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid > [class*='uk-width-'] {
    padding-left: 25px;
    padding-right: 25px;
  }
  /*
     * Column divider
     */
  .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
  }
  /*
     * Width multiplier for dropdown columns
     */
  .uk-dropdown-width-2:not(.uk-dropdown-stack) {
    width: 500px;
  }
  .uk-dropdown-width-3:not(.uk-dropdown-stack) {
    width: 750px;
  }
  .uk-dropdown-width-4:not(.uk-dropdown-stack) {
    width: 1000px;
  }
  .uk-dropdown-width-5:not(.uk-dropdown-stack) {
    width: 1250px;
  }
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  /*
     * Stack columns and take full width
     */
  .uk-dropdown-grid > [class*='uk-width-'] {
    width: 100%;
  }
  /*
     * Vertical gutter
     */
  .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
    margin-top: 25px;
  }
}
/*
* Stack grid columns
*/
.uk-dropdown-stack > .uk-dropdown-grid > [class*='uk-width-'] {
  width: 100%;
}
.uk-dropdown-stack > .uk-dropdown-grid > [class*='uk-width-']:nth-child(n+2) {
  margin-top: 25px;
}
/* Modifier `uk-dropdown-small`
 ========================================================================== */
/*
 * Set min-width and text expands dropdown if needed
 */
.uk-dropdown-small {
  min-width: 150px;
  width: auto;
  padding: 15px;
  white-space: nowrap;
}
/*
 * Nav in dropdown
 */
.uk-dropdown-small .uk-nav {
  margin: 0 -15px;
}
/* Modifier: `uk-dropdown-navbar`
 ========================================================================== */
.uk-dropdown-navbar {
  margin-top: 0;
  background: #ffffff;
  color: #2a455d;
  padding: 0;
}
.uk-dropdown-navbar .uk-nav-navbar ul a,
.uk-dropdown-navbar .uk-nav-navbar > li > a {
  padding: 6px 18px;
  font-size: 16px;
  text-transform: none;
  letter-spacing: 0;
}
.uk-dropdown-navbar .uk-nav-header {
  padding: 7px 15px;
}
.uk-dropdown-navbar .uk-nav-navbar ul li a:hover,
.uk-dropdown-navbar .uk-nav-navbar > li > a:hover,
.uk-dropdown-navbar .uk-nav-navbar > li > a:focus,
.uk-dropdown-navbar .uk-nav-navbar > li.uk-active > a,
.uk-dropdown-navbar .uk-nav-navbar ul li.uk-active > a {
  background-color: #258bce;
  color: #ffffff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.075);
}
.uk-dropdown-navbar .uk-nav-navbar ul li:first-child a:hover,
.uk-dropdown-navbar .uk-nav-navbar > li:first-child > a:hover,
.uk-dropdown-navbar .uk-nav-navbar > li:first-child > a:focus,
.uk-dropdown-navbar .uk-nav-navbar > li.uk-active:first-child > a,
.uk-dropdown-navbar .uk-nav-navbar ul li.uk-active:first-child > a {
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.1);
}
.uk-open > .uk-dropdown-navbar {
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
}
/* Modifier `uk-dropdown-scrollable`
 ========================================================================== */
/*
 * Usefull for long lists
 */
.uk-dropdown-scrollable {
  overflow-y: auto;
  max-height: 200px;
}
/* Sub-object: `uk-dropdown-overlay`
 ========================================================================== */
@media (min-width: 900px) {
  .tm-navbar .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid {
    margin-left: 0;
    margin-right: 0;
  }
  .tm-navbar .uk-dropdown:not(.uk-dropdown-stack) > .uk-dropdown-grid > [class*='uk-width-'] {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Nav in dropdown
	 ========================================================================== */
.uk-dropdown .uk-nav {
  margin: 0;
}
.tm-navbar .uk-dropdown {
  display: block;
  visibility: hidden;
}
.tm-navbar .uk-dropdown .uk-nav {
  margin: 0;
}
.tm-navbar .uk-dropdown li.uk-parent > ul {
  margin-left: -10px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: inset -1px -1px 0 rgba(0, 0, 0, 0.1), inset 1px 0 0 rgba(0, 0, 0, 0.1), 2px 2px 2px rgba(0, 0, 0, 0.05);
}
.tm-navbar .uk-dropdown li.uk-parent > a:before {
  font-family: "fontAwesome";
  content: "\f105";
  position: absolute;
  right: 15px;
  font-size: 18px;
  color: #2a455d;
}
.tm-navbar .uk-dropdown li.uk-parent.uk-active > a:before,
.tm-navbar .uk-dropdown li.uk-parent:hover > a:before {
  color: #ffffff;
}
.tm-navbar .uk-open > .uk-dropdown {
  visibility: visible;
}
/* button dropdown */
.uk-button-dropdown .uk-dropdown .uk-nav {
  margin: 0 15px 0 0;
}
.tm-navbar:not(.uk-active) .uk-dropdown-width-4.uk-dropdown-flip {
  right: -100px !important;
  left: auto !important;
}
/* ========================================================================
   Component: Modal
 ========================================================================== */
/*
 * This is the modal overlay and modal dialog container
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Allow scrolling for the modal dialog
 * 4. Mask the background page
 * 5. Fade-in transition
 * 6. Deactivate browser history navigation in IE11
 * 7. force hardware acceleration to prevent browser rendering hiccups
 */
.uk-modal {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1010;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  background: rgba(37, 139, 206, 0.75);
  /* 5 */
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
  /* 6 */
  touch-action: cross-slide-y pinch-zoom double-tap-zoom;
  /* 7 */
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
/*
 * Open state
 */
.uk-modal.uk-open {
  opacity: 1;
}
/*
 * Prevents duplicated scrollbar caused by 4.
 */
.uk-modal-page,
.uk-modal-page body {
  overflow: hidden;
}
/* Sub-object: `uk-modal-dialog`
 ========================================================================== */
/*
 * 1. Create position context for caption, spinner and close button
 * 2. Set box sizing
 * 3. Set style
 * 4. Slide-in transition
 */
.uk-modal-dialog {
  /* 1 */
  position: relative;
  /* 2 */
  box-sizing: border-box;
  margin: 50px auto;
  padding: 20px;
  width: 600px;
  max-width: 100%;
  max-width: calc(100% - 20px);
  /* 3 */
  background: #ffffff;
  /* 4 */
  opacity: 0;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  -webkit-transition: opacity 0.3s linear, -webkit-transform 0.3s ease-out;
  transition: opacity 0.3s linear, transform 0.3s ease-out;
  padding: 30px;
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  /*
     * Fit in small screen
     */
  .uk-modal-dialog {
    width: auto;
    margin: 10px auto;
  }
}
/*
 * Open state
 */
.uk-open .uk-modal-dialog {
  /* 4 */
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
/*
 * Remove margin from the last-child
 */
.uk-modal-dialog > :not([class*='uk-modal-']):last-child {
  margin-bottom: 0;
}
/* Close in modal
 ========================================================================== */
.uk-modal-dialog > .uk-close:first-child {
  margin: -10px -10px 0 0;
  float: right;
}
/*
 * Remove margin from adjacent element
 */
.uk-modal-dialog > .uk-close:first-child + :not([class*='uk-modal-']) {
  margin-top: 0;
}
/* Modifier: `uk-modal-dialog-lightbox`
 ========================================================================== */
.uk-modal-dialog-lightbox {
  margin: 15px auto;
  padding: 0;
  max-width: 95%;
  max-width: calc(100% - 30px);
  min-height: 50px;
}
/*
 * Close button
 */
.uk-modal-dialog-lightbox > .uk-close:first-child {
  position: absolute;
  top: -12px;
  right: -12px;
  margin: 0;
  float: none;
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-modal-dialog-lightbox > .uk-close:first-child {
    top: -7px;
    right: -7px;
  }
}
/* Modifier: `uk-modal-dialog-blank`
 ========================================================================== */
.uk-modal-dialog-blank {
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}
/*
* Close button
*/
.uk-modal-dialog-blank > .uk-close:first-child {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  margin: 0;
  float: none;
}
/* Modifier: `uk-modal-dialog-large`
 ========================================================================== */
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-modal-dialog-large {
    width: 930px;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-modal-dialog-large {
    width: 1130px;
  }
}
/* Sub-Object: `uk-modal-header` and `uk-modal-footer`
 ========================================================================== */
.uk-modal-header {
  margin-bottom: 15px;
}
.uk-modal-footer {
  margin-top: 15px;
}
/*
 * Remove margin from the last-child
 */
.uk-modal-header > :last-child,
.uk-modal-footer > :last-child {
  margin-bottom: 0;
}
/* Sub-Object: `uk-modal-caption`
 ========================================================================== */
.uk-modal-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  margin-bottom: -10px;
  color: #ffffff;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Sub-Object: `uk-modal-spinner`
 ========================================================================== */
.uk-modal-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 25px;
  color: #ddd;
}
.uk-modal-spinner:after {
  content: "\f110";
  display: block;
  font-family: FontAwesome;
  -webkit-animation: uk-rotate 2s infinite linear;
  animation: uk-rotate 2s infinite linear;
}
.tm-modal-dialog {
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.tm-modal-dialog .uk-close {
  width: 30px;
  line-height: 30px;
  z-index: 10;
}
.tm-modal-dialog .uk-close:after {
  font-size: 20px;
  opacity: 1;
}
.tm-modal-image {
  padding: 20px;
}
.tm-modal-image > img {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.uk-modal-dialog.uk-modal-dialog-small {
  width: 350px;
}
/* ========================================================================
   Component: Off-canvas
 ========================================================================== */
/*
 * This is the offcanvas overlay and bar container
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Deactivate browser touch actions in IE11
 * 4. Mask the background page
 */
.uk-offcanvas {
  /* 1 */
  display: none;
  /* 2 */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  /* 3 */
  touch-action: none;
  /* 4 */
  background: rgba(0, 0, 0, 0.3);
}
.uk-offcanvas.uk-active {
  display: block;
}
/* Sub-object `uk-offcanvas-page`
 ========================================================================== */
/*
 * Prepares the whole HTML page to slide-out
 * 1. Fix the main page and disallow scrolling
 * 2. Side-out transition
 */
.uk-offcanvas-page {
  /* 1 */
  position: fixed;
  /* 2 */
  -webkit-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
}
/* Sub-object `uk-offcanvas-bar`
 ========================================================================== */
/*
 * This is the offcanvas bar
 * 1. Set fixed position
 * 2. Size and style
 * 3. Allow scrolling
 * 4. Side-out transition
 * 5. Deactivate scroll chaining in IE11
 */
.uk-offcanvas-bar {
  /* 1 */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  z-index: 1001;
  /* 2 */
  width: 250px;
  max-width: 100%;
  background: #161f2e;
  /* 3 */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  /* 4 */
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  /* 5 */
  -ms-scroll-chaining: none;
}
.uk-offcanvas.uk-active .uk-offcanvas-bar.uk-offcanvas-bar-show {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
/* Modifier `uk-offcanvas-bar-flip`
 ========================================================================== */
.uk-offcanvas-bar-flip {
  left: auto;
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
/* Panel in offcanvas
 ========================================================================== */
.uk-offcanvas .uk-panel {
  margin: 20px;
  color: #ffffff;
}
.uk-offcanvas .uk-panel-title {
  color: #ffffff;
}
.uk-offcanvas .uk-panel a:not([class]) {
  color: #258bce;
}
.uk-offcanvas .uk-panel a:not([class]):hover {
  color: rgba(37, 139, 206, 0.8);
}
.uk-nav-offcanvas ul.uk-nav-sub {
  background: rgba(255, 255, 255, 0.05);
}
.uk-offcanvas input.uk-search-field {
  padding: 0 0 0 40px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border-color: rgba(0, 0, 0, 0.2);
  font-size: 14px;
  color: #ffffff;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
/* ========================================================================
   Component: Switcher
 ========================================================================== */
/*
 * 1. Deactivate browser history navigation in IE11
 */
.uk-switcher {
  margin: 0;
  padding: 0;
  list-style: none;
  /* 1 */
  touch-action: cross-slide-y pinch-zoom double-tap-zoom;
}
/*
 * Items
 */
.uk-switcher > :not(.uk-active) {
  display: none;
}
/* ========================================================================
   Component: Text
 ========================================================================== */
/* Size modifiers
 ========================================================================== */
.uk-text-small {
  font-size: 12px;
  line-height: 17px;
}
.uk-text-large {
  font-size: 20px;
  line-height: 27px;
  font-weight: normal;
}
/* Weight modifiers
 ========================================================================== */
.uk-text-bold {
  font-weight: bold;
}
/* Color modifiers
 ========================================================================== */
.uk-text-muted {
  color: #a8aeb6 !important;
}
.uk-text-primary {
  color: #258bce !important;
}
.uk-text-success {
  color: #84c118 !important;
}
.uk-text-warning {
  color: #ff9926 !important;
}
.uk-text-danger {
  color: #da314b !important;
}
.uk-text-contrast {
  color: #ffffff !important;
}
/* Alignment modifiers
 ========================================================================== */
.uk-text-left {
  text-align: left !important;
}
.uk-text-right {
  text-align: right !important;
}
.uk-text-center {
  text-align: center !important;
}
.uk-text-justify {
  text-align: justify !important;
}
.uk-text-top {
  vertical-align: top !important;
}
.uk-text-middle {
  vertical-align: middle !important;
}
.uk-text-bottom {
  vertical-align: bottom !important;
}
/* Only tablets portrait and smaller */
@media (max-width: 959px) {
  .uk-text-center-medium {
    text-align: center !important;
  }
  .uk-text-left-medium {
    text-align: left !important;
  }
}
/* Phone landscape and smaller */
@media (max-width: 899px) {
  .uk-text-center-small {
    text-align: center !important;
  }
  .uk-text-left-small {
    text-align: left !important;
  }
}
/* Wrap modifiers
 ========================================================================== */
/*
 * Prevent text from wrapping onto multiple lines
 */
.uk-text-nowrap {
  white-space: nowrap;
}
/*
 * Prevent text from wrapping onto multiple lines, and truncate with an ellipsis
 */
.uk-text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*
 * Break strings if their length exceeds the width of their container
 */
.uk-text-break {
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
/* ========================================================================
   Component: Utility
 ========================================================================== */
/* Container
 ========================================================================== */
.uk-container {
  box-sizing: border-box;
  max-width: 1300px;
  padding: 0 30px;
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-container {
    max-width: 1300px;
    padding: 0 40px;
  }
}
/*
 * Micro clearfix
 */
.uk-container:before,
.uk-container:after {
  content: "";
  display: table;
}
.uk-container:after {
  clear: both;
}
/*
 * Center container
 */
.uk-container-center {
  margin-left: auto;
  margin-right: auto;
}
/* Clearing
 ========================================================================== */
/*
 * Micro clearfix
* `table-cell` is used with `:before` because `table` creates a 1px gap when it becomes a flex item, only in Webkit
 * `table` is used again with `:after` because `clear` only works with block elements.
 * Note: `display: block` with `overflow: hidden` is currently not working in the latest Safari
 */
.uk-clearfix:before {
  content: "";
  display: table-cell;
}
.uk-clearfix:after {
  content: "";
  display: table;
  clear: both;
}
/*
 *  Create a new block formatting context
 */
.uk-nbfc {
  overflow: hidden;
}
.uk-nbfc-alt {
  display: table-cell;
  width: 10000px;
}
/* Alignment of block elements
 ========================================================================== */
/*
 * Float blocks
 * 1. Prevent content overflow on small devices
 */
.uk-float-left {
  float: left;
}
.uk-float-right {
  float: right;
}
/* 1 */
[class*='uk-float-'] {
  max-width: 100%;
}
/* Alignment of images and objects
 ========================================================================== */
/*
 * Alignment
 */
[class*='uk-align-'] {
  display: block;
  margin-bottom: 20px;
}
.uk-align-left {
  margin-right: 20px;
  float: left;
}
.uk-align-right {
  margin-left: 20px;
  float: right;
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-align-medium-left {
    margin-right: 20px;
    float: left;
  }
  .uk-align-medium-right {
    margin-left: 20px;
    float: right;
  }
}
.uk-align-center {
  margin-left: auto;
  margin-right: auto;
}
/* Vertical alignment
 ========================================================================== */
/*
 * Remove whitespace between child elements when using `inline-block`
 */
.uk-vertical-align {
  font-size: 0.001px;
}
/*
 *  The `uk-vertical-align` container needs a specific height
 */
.uk-vertical-align:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/*
 * Sub-object which can have any height
 * 1. Reset whitespace hack
 */
.uk-vertical-align-middle,
.uk-vertical-align-bottom {
  display: inline-block;
  max-width: 100%;
  /* 1 */
  font-size: 1rem;
}
.uk-vertical-align-middle {
  vertical-align: middle;
}
.uk-vertical-align-bottom {
  vertical-align: bottom;
}
/* Height
 ========================================================================== */
/*
 * More robust if padding and border are used
 */
[class*='uk-height'] {
  box-sizing: border-box;
}
/*
 * Useful to extend the `html` and `body` element to the full height of the page.
 */
.uk-height-1-1 {
  height: 100%;
}
/*
 * Useful to create image teasers
 */
.uk-height-viewport {
  height: 100vh;
  min-height: 600px;
}
/* Responsive objects
 * Note: Images are already responsive by default, see Base component
 ========================================================================== */
/*
 * 1. Corrects `max-width` and `max-height` behavior if padding and border are used
 */
.uk-responsive-width,
.uk-responsive-height {
  box-sizing: border-box;
}
/*
 * Responsiveness: Sets a maximum width relative to the parent and auto scales the height
 * `important` needed to override `uk-img-preserve img`
 */
.uk-responsive-width {
  max-width: 100% !important;
  height: auto;
}
/*
 * Responsiveness: Sets a maximum height relative to the parent and auto scales the width
 * Only works if the parent element has a fixed height.
 */
.uk-responsive-height {
  max-height: 100%;
  width: auto;
}
/* Margin
 ========================================================================== */
/*
 * Create a block with the same margin of a paragraph
 * Add margin if adjacent element
 */
.uk-margin {
  margin-bottom: 20px;
}
* + .uk-margin {
  margin-top: 20px;
}
.uk-margin-top {
  margin-top: 20px !important;
}
.uk-margin-bottom {
  margin-bottom: 20px !important;
}
.uk-margin-left {
  margin-left: 20px !important;
}
.uk-margin-right {
  margin-right: 20px !important;
}
/*
 * Larger margins
 */
.uk-margin-large {
  margin-bottom: 50px;
}
* + .uk-margin-large {
  margin-top: 50px;
}
.uk-margin-large-top {
  margin-top: 50px !important;
}
.uk-margin-large-bottom {
  margin-bottom: 50px !important;
}
.uk-margin-large-left {
  margin-left: 50px !important;
}
.uk-margin-large-right {
  margin-right: 50px !important;
}
/*
 * Smaller margins
 */
.uk-margin-small {
  margin-bottom: 5px;
}
* + .uk-margin-small {
  margin-top: 5px;
}
.uk-margin-small-top {
  margin-top: 5px !important;
}
.uk-margin-small-bottom {
  margin-bottom: 5px !important;
}
.uk-margin-small-left {
  margin-left: 5px !important;
}
.uk-margin-small-right {
  margin-right: 5px !important;
}
/*
 * Remove margins
 */
.uk-margin-remove {
  margin: 0 !important;
}
.uk-margin-top-remove {
  margin-top: 0 !important;
}
.uk-margin-bottom-remove {
  margin-bottom: 0 !important;
}
/* Padding
 ========================================================================== */
.uk-padding-remove {
  padding: 0 !important;
}
.uk-padding-top-remove {
  padding-top: 0 !important;
}
.uk-padding-bottom-remove {
  padding-bottom: 0 !important;
}
.uk-padding-vertical-remove {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
/* Border
 ========================================================================== */
.uk-border-circle {
  border-radius: 50%;
}
.uk-border-rounded {
  border-radius: 5px;
}
/* Headings
 ========================================================================== */
.uk-heading-large {
  font-size: 36px;
  line-height: 42px;
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-heading-large {
    font-size: 59px;
    line-height: 73px;
  }
}
/* Link
 ========================================================================== */
/*
 * Let links appear in default text color
 */
.uk-link-muted,
.uk-link-muted a {
  color: #2a455d;
}
.uk-link-muted:hover,
.uk-link-muted a:hover {
  color: #2a455d;
}
/*
 * Reset link style
 */
.uk-link-reset,
.uk-link-reset a,
.uk-link-reset:hover,
.uk-link-reset a:hover,
.uk-link-reset:focus,
.uk-link-reset a:focus {
  color: inherit;
  text-decoration: none;
}
/* Scrollable
 ========================================================================== */
/*
 * Enable scrolling for preformatted text
 */
.uk-scrollable-text {
  height: 300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  resize: both;
}
/*
 * Box with scrolling enabled
 */
.uk-scrollable-box {
  box-sizing: border-box;
  height: 170px;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  resize: both;
}
.uk-scrollable-box > :last-child {
  margin-bottom: 0;
}
/* Overflow
 ========================================================================== */
.uk-overflow-hidden {
  overflow: hidden;
}
/*
 * Enable scrollbars if content is clipped
 */
.uk-overflow-container {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.uk-overflow-container > :last-child {
  margin-bottom: 0;
}
/* Position
 ========================================================================== */
.uk-position-absolute,
[class*='uk-position-top'],
[class*='uk-position-bottom'] {
  position: absolute !important;
}
/* Don't use `width: 100%` because it is wrong if the parent has padding. */
.uk-position-top {
  top: 0;
  left: 0;
  right: 0;
}
.uk-position-bottom {
  bottom: 0;
  left: 0;
  right: 0;
}
.uk-position-top-left {
  top: 0;
  left: 0;
}
.uk-position-top-right {
  top: 0;
  right: 0;
}
.uk-position-bottom-left {
  bottom: 0;
  left: 0;
}
.uk-position-bottom-right {
  bottom: 0;
  right: 0;
}
/*
 * Cover
 */
.uk-position-cover {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
/*
 * Relative
 */
.uk-position-relative {
  position: relative !important;
}
/*
 * Z-index
 */
.uk-position-z-index {
  z-index: 1;
}
/* Display
 ========================================================================== */
/*
 * Display
 * 1. Required if child is a responsive image
 */
.uk-display-block {
  display: block !important;
}
.uk-display-inline {
  display: inline !important;
}
.uk-display-inline-block {
  display: inline-block !important;
  /* 1 */
  max-width: 100%;
}
/*
 * Visibility
 * Avoids setting display to `block` so it works also with `inline-block` and `table`
 */
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-visible-small {
    display: none !important;
  }
  .uk-visible-medium {
    display: none !important;
  }
  .uk-hidden-large {
    display: none !important;
  }
}
/* Tablets portrait */
@media (min-width: 900px) and (max-width: 959px) {
  .uk-visible-small {
    display: none !important;
  }
  .uk-visible-large {
    display: none !important ;
  }
  .uk-hidden-medium {
    display: none !important;
  }
}
/* Phone landscape and smaller*/
@media (max-width: 899px) {
  .uk-visible-medium {
    display: none !important;
  }
  .uk-visible-large {
    display: none !important;
  }
  .uk-hidden-small {
    display: none !important;
  }
}
/* Remove from the flow and screen readers on any device */
.uk-hidden {
  display: none !important;
  visibility: hidden !important;
}
/* It's hidden, but still affects layout */
.uk-invisible {
  visibility: hidden !important;
}
/* Show on hover */
.uk-visible-hover:hover .uk-hidden,
.uk-visible-hover:hover .uk-invisible {
  display: block !important;
  visibility: visible !important;
}
.uk-visible-hover-inline:hover .uk-hidden,
.uk-visible-hover-inline:hover .uk-invisible {
  display: inline-block !important;
  visibility: visible !important;
}
/* Hide on touch */
.uk-touch .uk-hidden-touch,
.uk-notouch .uk-hidden-notouch {
  display: none !important;
}
/* ========================================================================
   Component: Flex
 ========================================================================== */
.uk-flex {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.uk-flex-inline {
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}
/*
 * Fixes initial flex-shrink value in IE10
 */
.uk-flex > *,
.uk-flex-inline > * {
  -ms-flex-negative: 1;
}
/* Alignment
 ========================================================================== */
/*
 * Vertical alignment
 * Default value is `stretch`
 */
.uk-flex-top {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}
.uk-flex-middle {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.uk-flex-bottom {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
/*
 * Horizontal alignment
 * Default value is `flex-start`
 */
.uk-flex-center {
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.uk-flex-right {
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.uk-flex-space-between {
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.uk-flex-space-around {
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
/* Direction
 ========================================================================== */
.uk-flex-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.uk-flex-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
.uk-flex-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}
/* Wrap
 ========================================================================== */
.uk-flex-nowrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.uk-flex-wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.uk-flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}
/*
 * Horizontal alignment
 * Default value is `stretch`
 */
.uk-flex-wrap-top {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}
.uk-flex-wrap-middle {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}
.uk-flex-wrap-bottom {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.uk-flex-wrap-space-between {
  -ms-flex-line-pack: justify;
  -webkit-align-content: space-between;
  align-content: space-between;
}
.uk-flex-wrap-space-around {
  -ms-flex-line-pack: distribute;
  -webkit-align-content: space-around;
  align-content: space-around;
}
/* Item ordering
 ========================================================================== */
/*
 * Default is 0
 */
.uk-flex-order-first {
  -ms-flex-order: -1;
  -webkit-order: -1;
  order: -1;
}
.uk-flex-order-last {
  -ms-flex-order: 99;
  -webkit-order: 99;
  order: 99;
}
/* Phone landscape and bigger */
@media (min-width: 480px) {
  .uk-flex-order-first-small {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-small {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
/* Tablet and bigger */
@media (min-width: 900px) {
  .uk-flex-order-first-medium {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-medium {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
/* Desktop and bigger */
@media (min-width: 960px) {
  .uk-flex-order-first-large {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-large {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
/* Large screen and bigger */
@media (min-width: 1220px) {
  .uk-flex-order-first-xlarge {
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .uk-flex-order-last-xlarge {
    -ms-flex-order: 99;
    -webkit-order: 99;
    order: 99;
  }
}
/* Item dimensions
 ========================================================================== */
/*
 * Initial: 0 1 auto
 * Content dimensions, but shrinks
 */
/*
 * No Flex: 0 0 auto
 * Content dimensions
 */
.uk-flex-item-none {
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
}
/*
 * Relative Flex: 1 1 auto
 * Space is allocated considering content
 * 1. Fixes flex-shrink value in IE10
 */
.uk-flex-item-auto {
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
  /* 1 */
  -ms-flex-negative: 1;
}
/*
 * Absolute Flex: 1 1 0%
 * Space is allocated solely based on flex
 */
.uk-flex-item-1 {
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
/* ========================================================================
   Component: Contrast
 ========================================================================== */
.uk-contrast {
  color: #ffffff;
  /* Active */
}
.uk-contrast a:not([class]),
.uk-contrast .uk-link {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.uk-contrast a:not([class]):hover,
.uk-contrast .uk-link:hover {
  color: #ffffff;
  text-decoration: underline;
}
.uk-contrast :not(pre) > code,
.uk-contrast :not(pre) > kbd,
.uk-contrast :not(pre) > samp {
  color: #ffffff;
}
.uk-contrast em {
  color: #ffffff;
}
.uk-contrast h1,
.uk-contrast h2,
.uk-contrast h3,
.uk-contrast h4,
.uk-contrast h5,
.uk-contrast h6 {
  color: #ffffff;
}
.uk-contrast hr {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-nav li > a,
.uk-contrast .uk-nav li > a:hover {
  text-decoration: none;
}
.uk-contrast .uk-nav-side > li > a {
  color: #ffffff;
}
.uk-contrast .uk-nav-side > li > a:hover,
.uk-contrast .uk-nav-side > li > a:focus {
  background: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}
.uk-contrast .uk-nav-side > li.uk-active > a {
  background: #ffffff;
  color: #ffffff;
}
.uk-contrast .uk-nav-side .uk-nav-header {
  color: #ffffff;
}
.uk-contrast .uk-nav-side .uk-nav-divider {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-nav-side ul a {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-nav-side ul a:hover {
  color: #ffffff;
}
.uk-contrast .uk-subnav > * > a {
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}
.uk-contrast .uk-subnav > * > a:hover,
.uk-contrast .uk-subnav > * > a:focus {
  color: #ffffff;
  text-decoration: none;
}
.uk-contrast .uk-subnav > .uk-active > a {
  color: #ffffff;
}
.uk-contrast .uk-subnav-line > :nth-child(n+2):before {
  border-left-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-subnav-pill > * > a:hover,
.uk-contrast .uk-subnav-pill > * > a:focus {
  background: rgba(255, 255, 255, 0.7);
  color: #ffffff;
  text-decoration: none;
}
.uk-contrast .uk-subnav-pill > .uk-active > a {
  background: #ffffff;
  color: #ffffff;
}
.uk-contrast .uk-tab {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-tab > li > a {
  border-color: transparent;
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-tab > li > a:hover,
.uk-contrast .uk-tab > li > a:focus,
.uk-contrast .uk-tab > li.uk-open > a {
  border-color: rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.7);
  color: #ffffff;
  text-decoration: none;
}
.uk-contrast .uk-tab > li.uk-active > a {
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom-color: transparent;
  background: #ffffff;
  color: #ffffff;
}
.uk-contrast .uk-tab-center {
  border-bottom-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-tab-grid:before {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-list-line > li:nth-child(n+2) {
  border-top-color: rgba(255, 255, 255, 0.2);
}
.uk-contrast .uk-form select,
.uk-contrast .uk-form textarea,
.uk-contrast .uk-form input:not([type]),
.uk-contrast .uk-form input[type="text"],
.uk-contrast .uk-form input[type="password"],
.uk-contrast .uk-form input[type="datetime"],
.uk-contrast .uk-form input[type="datetime-local"],
.uk-contrast .uk-form input[type="date"],
.uk-contrast .uk-form input[type="month"],
.uk-contrast .uk-form input[type="time"],
.uk-contrast .uk-form input[type="week"],
.uk-contrast .uk-form input[type="number"],
.uk-contrast .uk-form input[type="email"],
.uk-contrast .uk-form input[type="url"],
.uk-contrast .uk-form input[type="search"],
.uk-contrast .uk-form input[type="tel"],
.uk-contrast .uk-form input[type="color"] {
  border-color: rgba(255, 255, 255, 0.8);
  background: rgba(255, 255, 255, 0.8);
  color: #ffffff;
  background-clip: padding-box;
}
.uk-contrast .uk-form select:focus,
.uk-contrast .uk-form textarea:focus,
.uk-contrast .uk-form input:not([type]):focus,
.uk-contrast .uk-form input[type="text"]:focus,
.uk-contrast .uk-form input[type="password"]:focus,
.uk-contrast .uk-form input[type="datetime"]:focus,
.uk-contrast .uk-form input[type="datetime-local"]:focus,
.uk-contrast .uk-form input[type="date"]:focus,
.uk-contrast .uk-form input[type="month"]:focus,
.uk-contrast .uk-form input[type="time"]:focus,
.uk-contrast .uk-form input[type="week"]:focus,
.uk-contrast .uk-form input[type="number"]:focus,
.uk-contrast .uk-form input[type="email"]:focus,
.uk-contrast .uk-form input[type="url"]:focus,
.uk-contrast .uk-form input[type="search"]:focus,
.uk-contrast .uk-form input[type="tel"]:focus,
.uk-contrast .uk-form input[type="color"]:focus {
  border-color: #ffffff;
  background: #ffffff;
  color: #ffffff;
}
.uk-contrast .uk-form :-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}
.uk-contrast .uk-form ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-form ::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-button {
  color: #ffffff;
  background: #ffffff;
}
.uk-contrast .uk-button:hover,
.uk-contrast .uk-button:focus {
  background-color: rgba(255, 255, 255, 0.8);
  color: #ffffff;
}
.uk-contrast .uk-button:active,
.uk-contrast .uk-button.uk-active {
  background-color: rgba(255, 255, 255, 0.7);
  color: #ffffff;
}
.uk-contrast .uk-button-primary {
  background-color: #258bce;
  color: #ffffff;
}
.uk-contrast .uk-button-primary:hover,
.uk-contrast .uk-button-primary:focus {
  background-color: #2682be;
  color: #ffffff;
}
.uk-contrast .uk-button-primary:active,
.uk-contrast .uk-button-primary.uk-active {
  background-color: #2479b1;
  color: #ffffff;
}
.uk-contrast .uk-icon-hover {
  color: rgba(255, 255, 255, 0.7);
}
.uk-contrast .uk-icon-hover:hover {
  color: #ffffff;
}
.uk-contrast .uk-icon-button {
  background: rgba(0, 0, 0, 0);
  color: #ffffff;
  border-color: #ffffff;
}
.uk-contrast .uk-icon-button:hover,
.uk-contrast .uk-icon-button:focus {
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border-color: #ffffff;
}
.uk-contrast .uk-icon-button:active {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  border-color: #ffffff;
}
.uk-contrast .uk-text-muted {
  color: rgba(255, 255, 255, 0.6) !important;
}
.uk-contrast .uk-text-primary {
  color: #258bce !important;
}
.uk-contrast blockquote {
  color: #ffffff;
}
.uk-contrast .uk-article-lead {
  color: #ffffff;
}
.uk-contrast .uk-module-title:after {
  border-bottom-color: #ffffff;
}
.uk-contrast .uk-button-line,
.uk-contrast .uk-button.uk-button-line {
  border-color: #ffffff;
}
/* ========================================================================
   Component: Print
 ========================================================================== */
@media print {
  *,
  *:before,
  *:after {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}
/* ========================================================================
   Component: Dotnav
 ========================================================================== */
/*
 * 1. Gutter
 * 2. Remove default list style
 */
.uk-dotnav {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  /* 1 */
  margin-left: -12px;
  margin-top: -12px;
  /* 2 */
  padding: 0;
  list-style: none;
}
/*
 * 1. Space is allocated solely based on content dimensions
 * 2. Horizontal gutter is using `padding` so `uk-width-*` classes can be applied
 */
.uk-dotnav > * {
  /* 1 */
  -ms-flex: none;
  -webkit-flex: none;
  flex: none;
  /* 2 */
  padding-left: 12px;
  margin-top: 12px;
}
/*
 * DEPRECATED IE9 Support
 */
.uk-dotnav:before,
.uk-dotnav:after {
  content: "";
  display: block;
  overflow: hidden;
}
.uk-dotnav:after {
  clear: both;
}
.uk-dotnav > * {
  float: left;
}
/* Items
 ========================================================================== */
/*
 * Items
 * 1. Hide text if present
 */
.uk-dotnav > * > * {
  display: block;
  box-sizing: content-box;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(42, 69, 93, 0.2);
  /* 1 */
  text-indent: 100%;
  overflow: hidden;
  white-space: nowrap;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-dotnav > * > :hover,
.uk-dotnav > * > :focus {
  background: rgba(42, 69, 93, 0.5);
  /* 2 */
  outline: none;
}
/* OnClick */
.uk-dotnav > * > :active {
  background: #2a455d;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
/* Active */
.uk-dotnav > .uk-active > * {
  background: #258bce;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/* Modifier: `uk-dotnav-contrast`
 ========================================================================== */
.uk-dotnav-contrast > * > * {
  background: rgba(255, 255, 255, 0.4);
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.uk-dotnav-contrast > * > :hover,
.uk-dotnav-contrast > * > :focus {
  background: rgba(255, 255, 255, 0.7);
}
/* OnClick */
.uk-dotnav-contrast > * > :active {
  background: rgba(255, 255, 255, 0.9);
}
/* Active */
.uk-dotnav-contrast > .uk-active > * {
  background: rgba(255, 255, 255, 0.9);
}
/* Modifier: 'uk-dotnav-vertical'
 ========================================================================== */
/*
 * DEPRECATED
 */
.uk-dotnav-vertical {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
/*
 * DEPRECATED IE9 Support
 */
.uk-dotnav-vertical > * {
  float: none;
}
/* ========================================================================
   Component: Slidenav
 ========================================================================== */
/*
 * 1. Required for `a` elements
 * 2. Dimension
 * 3. Style
 */
.uk-slidenav {
  /* 1 */
  display: inline-block;
  /* 2 */
  box-sizing: border-box;
  width: 50px;
  height: 50px;
  /* 3 */
  line-height: 50px;
  color: rgba(42, 69, 93, 0.3);
  font-size: 50px;
  text-align: center;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 * 3. Required for `a` elements
 * 4. Style
 */
.uk-slidenav:hover,
.uk-slidenav:focus {
  /* 2 */
  outline: none;
  /* 3 */
  text-decoration: none;
  /* 4 */
  color: rgba(42, 69, 93, 0.5);
  cursor: pointer;
}
/* Active */
.uk-slidenav:active {
  color: #2a455d;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
}
/*
 * Icons
 */
.uk-slidenav-previous:before {
  content: "\f104";
  font-family: FontAwesome;
}
.uk-slidenav-next:before {
  content: "\f105";
  font-family: FontAwesome;
}
/* Sub-object: `uk-slidenav-position`
 ========================================================================== */
/*
 * Create position context
 */
.uk-slidenav-position {
  position: relative;
}
/*
 * Center vertically
 */
.uk-slidenav-position .uk-slidenav {
  display: none;
  position: absolute;
  top: 50%;
  z-index: 1;
  margin-top: -25px;
}
.uk-slidenav-position:hover .uk-slidenav {
  display: block;
}
.uk-slidenav-position .uk-slidenav-previous {
  left: 40px;
}
.uk-slidenav-position .uk-slidenav-next {
  right: 40px;
}
/* Modifier: `uk-slidenav-contrast`
 ========================================================================== */
.uk-slidenav-contrast {
  color: #ffffff;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 */
.uk-slidenav-contrast:hover,
.uk-slidenav-contrast:focus {
  color: rgba(42, 69, 93, 0.7);
}
/* Active */
.uk-slidenav-contrast:active {
  color: rgba(42, 69, 93, 0.9);
}
/* ========================================================================
   Component: Progress
 ========================================================================== */
/*
 * 1. Clearing
 * 2. Vertical alignment if text is used
 */
.uk-progress {
  box-sizing: border-box;
  height: 20px;
  margin-bottom: 20px;
  background: #eef4ff;
  /* 1 */
  overflow: hidden;
  /* 2 */
  line-height: 20px;
  border-radius: 20px;
  padding: 5px;
  height: 30px;
  box-shadow: inset 0 0 0 1px #dfeaff;
}
/*
 * Add margin if adjacent element
 */
* + .uk-progress {
  margin-top: 20px;
}
/* Sub-object: `uk-progress-bar`
 ========================================================================== */
/*
 * 1. Transition
 * 2. Needed for text
 */
.uk-progress-bar {
  width: 0;
  height: 100%;
  background: #258bce;
  float: left;
  /* 1 */
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
  /* 2 */
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.1);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.15)), color-stop(100%, rgba(0, 0, 0, 0))) #258bce;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.15) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  border-radius: 10px;
}
/* Size modifiers
 ========================================================================== */
/* Mini */
.uk-progress-mini {
  height: 6px;
}
/* Small */
.uk-progress-small {
  height: 12px;
}
/* Color modifiers
 ========================================================================== */
.uk-progress-success .uk-progress-bar {
  background-color: #84c118;
}
.uk-progress-warning .uk-progress-bar {
  background-color: #ff9926;
}
.uk-progress-danger .uk-progress-bar {
  background-color: #da314b;
}
/* Modifier: `uk-progress-striped`
 ========================================================================== */
.uk-progress-striped .uk-progress-bar {
  background-image: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 30px 30px;
}
/*
 * Animation
 */
.uk-progress-striped.uk-active .uk-progress-bar {
  -webkit-animation: uk-progress-bar-stripes 2s linear infinite;
  animation: uk-progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes uk-progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
@keyframes uk-progress-bar-stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 30px 0;
  }
}
.uk-progress.uk-progress-mini {
  padding: 1px;
}
.uk-progress.uk-progress-small {
  padding: 3px;
}
/* ========================================================================
   Component: Accordion
 ========================================================================== */
/* Sub-object: `uk-accordion-title`
 ========================================================================== */
.uk-accordion-title {
  margin-top: 0;
  margin-bottom: 13px;
  padding: 8px 13px;
  background: #eef4ff;
  font-size: 20px;
  line-height: 26px;
  cursor: pointer;
  border: 1px solid #dfeaff;
}
/* Sub-object: `uk-accordion-content`
 ========================================================================== */
.uk-accordion-content {
  padding: 0 13px 13px 13px;
}
/*
 * Micro clearfix to make panels more robust
 */
.uk-accordion-content:before,
.uk-accordion-content:after {
  content: "";
  display: table;
}
.uk-accordion-content:after {
  clear: both;
}
/*
 * Remove margin from the last-child
 */
.uk-accordion-content > :last-child {
  margin-bottom: 0;
}
.tm-accordion,
.tm-toggle {
  margin: 20px 0 10px;
}
.tm-accordion .uk-accordion-title,
.tm-toggle .uk-accordion-title {
  background-color: #eef4ff;
  padding: 20px 20px 20px 30px;
  color: #2a455d;
  border-color: #d5e3ff;
  margin: 20px 0 0;
  border-radius: 3px;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
}
.tm-accordion .uk-accordion-title.uk-active,
.tm-toggle .uk-accordion-title.uk-active {
  border-radius: 3px 3px 0 0;
  background-color: #258bce;
  color: #ffffff;
  border-color: #2382c1;
  -webkit-transition: all ease-in-out 0.25s;
  transition: all ease-in-out 0.25s;
}
.tm-accordion .uk-accordion-title i.tm-toggle-button,
.tm-toggle .uk-accordion-title i.tm-toggle-button {
  margin: 0 5px 0 20px;
  font-style: normal;
  font-family: "fontAwesome";
  color: #ffffff;
  background-color: #258bce;
  width: 26px;
  height: 26px;
  border-radius: 3px;
  text-align: center;
  position: relative;
  margin-bottom: 10px;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-accordion .uk-accordion-title i:before,
.tm-toggle .uk-accordion-title i:before,
.tm-accordion .uk-accordion-title i:after,
.tm-toggle .uk-accordion-title i:after {
  content: "";
  position: absolute;
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-accordion .uk-accordion-title i:before,
.tm-toggle .uk-accordion-title i:before {
  background-color: #ffffff;
  content: "";
  height: 3px;
  width: 15px;
  top: 11px;
  left: 5px;
}
.tm-accordion .uk-accordion-title i:after,
.tm-toggle .uk-accordion-title i:after {
  background-color: #ffffff;
  content: "";
  height: 15px;
  width: 3px;
  top: 5px;
  left: 11px;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.tm-accordion .uk-accordion-title.uk-active i:before,
.tm-toggle .uk-accordion-title.uk-active i:before {
  opacity: 0;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tm-accordion .uk-accordion-title.uk-active i:after,
.tm-toggle .uk-accordion-title.uk-active i:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tm-accordion .uk-accordion-content,
.tm-toggle .uk-accordion-content {
  padding: 30px 45px;
  border: 1px solid #d5e3ff;
  border-width: 0 1px 1px;
}
.tm-accordion .uk-accordion-content code,
.tm-toggle .uk-accordion-content code {
  white-space: pre-wrap;
  color: rgba(42, 69, 93, 0.6);
}
.tm-toggle-code .uk-accordion-content {
  background-color: rgba(0, 0, 0, 0);
}
/* ========================================================================
   Component: Datepicker
 ========================================================================== */
/*
 * 1. Highest z-index
 * 2. Reset dropdown width
 * 3. Set animation
 * 4. Needed for scale animation
 */
.uk-datepicker {
  /* 1 */
  z-index: 1050;
  /* 2 */
  width: auto;
  /* 3 */
  -webkit-animation: uk-fade 0.2s ease-in-out;
  animation: uk-fade 0.2s ease-in-out;
  /* 4 */
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Sub-object: `uk-datepicker-nav`
========================================================================== */
.uk-datepicker-nav {
  margin-bottom: 20px;
  text-align: center;
  line-height: 20px;
}
/*
 * Micro clearfix
 */
.uk-datepicker-nav:before,
.uk-datepicker-nav:after {
  content: "";
  display: table;
}
.uk-datepicker-nav:after {
  clear: both;
}
/*
 * Previous and next navigation
 */
.uk-datepicker-nav a {
  color: #2a455d;
  text-decoration: none;
}
.uk-datepicker-nav a:hover {
  color: #258bce;
}
.uk-datepicker-previous {
  float: left;
}
.uk-datepicker-next {
  float: right;
}
.uk-datepicker-previous:after,
.uk-datepicker-next:after {
  width: 20px;
  font-family: FontAwesome;
}
.uk-datepicker-previous:after {
  content: "\f053";
}
.uk-datepicker-next:after {
  content: "\f054";
}
/* Sub-object: `uk-datepicker-heading`
========================================================================== */
/* Sub-object: `uk-datepicker-table`
========================================================================== */
/* Block element behavior */
.uk-datepicker-table {
  width: 100%;
}
.uk-datepicker-table th,
.uk-datepicker-table td {
  padding: 2px;
}
.uk-datepicker-table th {
  font-size: 12px;
}
/*
 * Item
 */
.uk-datepicker-table a {
  display: block;
  width: 26px;
  line-height: 24px;
  color: #2a455d;
  text-decoration: none;
  border-radius: 3px;
  text-align: center;
}
/*
 * Sub-object: `uk-datepicker-table-muted`
 */
a.uk-datepicker-table-muted {
  color: #a8aeb6;
}
/*
 * Hover
 * 1. Apply hover style also to focus state
 * 2. Remove default focus style
 */
.uk-datepicker-table a:hover,
.uk-datepicker-table a:focus {
  background-color: rgba(37, 139, 206, 0.5);
  color: #ffffff;
  /* 2 */
  outline: none;
}
/* OnClick */
.uk-datepicker-table a:active {
  background-color: #258bce;
  color: #ffffff;
}
/*
 * Active
 */
.uk-datepicker-table a.uk-active {
  background: #258bce;
  color: #ffffff;
}
/* ========================================================================
   Component: Form advanced
   Note: Only works in Webkit at the moment
 ========================================================================== */
/*
 * 1. Style
 * 2. Makes box more robust so it clips the child element
 * 3. Vertical alignment
 * 4. Remove default style
 * 5. Fix black background on iOS
 */
.uk-form input[type="radio"],
.uk-form input[type="checkbox"] {
  /* 1 */
  display: inline-block;
  height: 14px;
  width: 14px;
  border: 1px solid #aaaaaa;
  /* 2 */
  overflow: hidden;
  /* 3 */
  margin-top: -4px;
  vertical-align: middle;
  /* 4 */
  -webkit-appearance: none;
  outline: 0;
  /* 5 */
  background: transparent;
}
/* Radio */
.uk-form input[type="radio"] {
  border-radius: 50%;
}
/*
 * Checked
 */
.uk-form input[type=radio]:before,
.uk-form input[type=checkbox]:before {
  display: block;
}
/* Radio */
.uk-form input[type=radio]:checked:before {
  content: '';
  width: 8px;
  height: 8px;
  margin: 2px auto 0;
  border-radius: 50%;
  background: #258bce;
}
/* Checkbox */
.uk-form input[type=checkbox]:checked:before,
.uk-form input[type=checkbox]:indeterminate:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 12px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  line-height: 12px;
  color: #258bce;
}
.uk-form input[type=checkbox]:indeterminate:before {
  content: "\f068";
}
/*
 * Disabled
 */
.uk-form input[type=radio]:disabled,
.uk-form input[type=checkbox]:disabled {
  border-color: #dddddd;
}
.uk-form input[type=radio]:disabled:checked:before {
  background-color: #aaaaaa;
}
.uk-form input[type=checkbox]:disabled:checked:before,
.uk-form input[type=checkbox]:disabled:indeterminate:before {
  color: #aaaaaa;
}
/* ========================================================================
   Component: Form file
 ========================================================================== */
/*
 * 1. Behave like form elements
 * 2. Create position context for dropdowns
 * 3. Clip content
 */
.uk-form-file {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
  /* 3 */
  overflow: hidden;
}
/*
 * 1. Required for Firefox
 * 2. Expand height and required for the cursor
 */
.uk-form-file input[type="file"] {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  opacity: 0;
  cursor: pointer;
  /* 1 */
  left: 0;
  /* 2 */
  font-size: 500px;
}
/* ========================================================================
   Component: Form password
 ========================================================================== */
/*
 * 1. Container width fits its content
 * 2. Create position context
 * 3. Prevent `inline-block` consequences
 */
.uk-form-password {
  /* 1 */
  display: inline-block;
  /* 2 */
  position: relative;
  /* 3 */
  max-width: 100%;
}
.uk-form-password-toggle {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -7px;
  font-size: 15px;
  line-height: 15px;
  color: #a8aeb6;
}
.uk-form-password-toggle:hover {
  color: #2a455d;
  text-decoration: none;
}
.uk-form-password > input {
  padding-right: 50px !important;
}
.uk-form-password-toggle {
  right: 20px;
}
/* ========================================================================
   Component: Form select
 ========================================================================== */
/*
 * 1. Behave like form elements
 * 2. Create position context for dropdowns
 * 3. Clip content
 */
.uk-form-select {
  /* 1 */
  display: inline-block;
  vertical-align: middle;
  /* 2 */
  position: relative;
  /* 3 */
  overflow: hidden;
}
/*
 * 1. Required for Firefox
 * 1. Required for Webkit to make `height` work
 */
.uk-form-select select {
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  /* 1 */
  left: 0;
  /* 2 */
  -webkit-appearance: none;
}
/* ========================================================================
   Component: Search
 ========================================================================== */
/*
 * 1. Create position context for dropdowns
 * 2. Needed for `form` element
 */
.uk-search {
  display: inline-block;
  /* 1 */
  position: relative;
  /* 2 */
  padding: 0 15px;
  margin: 0;
}
/*
 * Icon
 */
.uk-search:before {
  content: "\f002";
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  line-height: 35px;
  text-align: center;
  font-family: FontAwesome;
  font-size: 14px;
  color: rgba(42, 69, 93, 0.75);
  left: 18px;
  z-index: 3;
}
/* Sub-object `uk-search-field`
 ========================================================================== */
/*
 * Removes inner padding and border in Firefox 4+.
 */
.uk-search-field::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * Remove inner padding and search cancel button in Chrome, Safari and Opera on OS X.
 */
.uk-search-field::-webkit-search-cancel-button,
.uk-search-field::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
 * Removes cancel button in IE10
 */
.uk-search-field::-ms-clear {
  display: none;
}
/*
 * Removes placeholder transparency in Firefox.
 */
.uk-search-field::-moz-placeholder {
  opacity: 1;
}
/*
 * 1. Define consistent box sizing.
 * 2. Address margins set differently in Firefox/IE and Chrome/Safari/Opera.
 * 3. Remove `border-radius` in iOS.
 * 4. Correct `font` properties and `color` not being inherited.
 * 5. Remove default style in iOS.
 * 6. Style
 */
.uk-search-field {
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  margin: 0;
  /* 3 */
  border-radius: 0;
  /* 4 */
  font: inherit;
  color: #2a455d;
  /* 5 */
  -webkit-appearance: none;
  /* 6 */
  width: 200px;
  height: 35px;
  padding: 0 0 0 30px;
  background: rgba(0, 0, 0, 0);
  -webkit-transition: all linear 0.2s;
  transition: all linear 0.2s;
  vertical-align: middle;
  cursor: pointer;
  z-index: 1;
  position: relative;
  padding-left: 30px;
  border-radius: 3px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid rgba(42, 69, 93, 0.3);
}
/* Placeholder */
.uk-search-field:-ms-input-placeholder {
  color: rgba(42, 69, 93, 0.5) !important;
}
.uk-search-field::-moz-placeholder {
  color: rgba(42, 69, 93, 0.5);
}
.uk-search-field::-webkit-input-placeholder {
  color: rgba(42, 69, 93, 0.5);
}
/* Focus */
.uk-search-field:focus {
  outline: 0;
  border: 1px solid #258bce;
}
/* Focus + Active */
.uk-search-field:focus,
.uk-search.uk-active .uk-search-field {
  width: 200px;
}
/* Dropdown modifier: `uk-dropdown-search`
 ========================================================================== */
.uk-dropdown-search {
  width: 300px;
  margin-top: 30px;
  background: #ffffff;
  color: #2a455d;
  padding: 0;
  right: 15px;
}
.uk-open > .uk-dropdown-search {
  -webkit-animation: uk-slide-top-fixed 0.2s ease-in-out;
  animation: uk-slide-top-fixed 0.2s ease-in-out;
}
/*
 * Dependency `uk-navbar-flip`
 */
.uk-navbar-flip .uk-dropdown-search {
  margin-top: 10px;
  margin-right: 0;
}
/* Nav modifier `uk-nav-search`
 ========================================================================== */
/*
 * Items
 */
.uk-nav-search > li > a {
  color: #2a455d;
}
/*
 * Active
 * 1. Remove default focus style
 */
.uk-nav-search > li.uk-active > a {
  background: rgba(0, 0, 0, 0);
  color: #ffffff;
  /* 1 */
  outline: none;
  background-color: #258bce;
}
/*
 * Sub-object: `uk-nav-header`
 */
.uk-nav-search .uk-nav-header {
  color: #a8aeb6;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  color: #ffffff;
  background-color: #258bce;
  padding: 10px 15px;
}
/*
 * Sub-object: `uk-nav-divider`
 */
.uk-nav-search .uk-nav-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin: 0;
}
/*
 * Nested items
 */
.uk-nav-search ul a {
  color: #2a455d;
}
.uk-nav-search ul a:hover {
  color: rgba(37, 139, 206, 0.8);
}
/* Search in offcanvas
 ========================================================================== */
.uk-offcanvas .uk-search {
  display: block;
  margin: 0;
}
.uk-offcanvas .uk-search:before {
  color: #2a455d;
}
.uk-offcanvas .uk-search-field {
  width: 100%;
  border-color: rgba(0, 0, 0, 0.1);
  background: rgba(0, 0, 0, 0);
  color: #2a455d;
  padding: 0 30px;
  cursor: text;
}
.uk-offcanvas .uk-search-field:-ms-input-placeholder {
  color: #a8aeb6 !important;
}
.uk-offcanvas .uk-search-field::-moz-placeholder {
  color: #a8aeb6;
}
.uk-offcanvas .uk-search-field::-webkit-input-placeholder {
  color: #a8aeb6;
}
.uk-search-field:focus,
.uk-search.uk-active .uk-search-field {
  padding-left: 30px;
  cursor: text;
}
.uk-nav-search ul.uk-nav-sub {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.uk-navbar-flip .uk-dropdown-search {
  padding: 0;
}
.uk-nav-search .uk-nav-header {
  font-family: 'Exo 2';
  font-size: 18px;
}
.uk-nav-search > li > a {
  font-family: 'Exo 2';
  font-size: 18px;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transition: all linear 0.05s;
  transition: all linear 0.05s;
}
.uk-nav-search > li > a > div {
  font-family: 'Varela';
  line-height: 26px;
}
/* ========================================================================
   Component: Slider
 ========================================================================== */
/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Deactivate browser history navigation in IE11
 */
.uk-slider {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 0;
  /* 3 */
  touch-action: pan-y;
}
/*
 * 1. Reset list style without interfering with grid
 */
.uk-slider:not(.uk-grid) {
  /* 1 */
  margin: 0;
  padding: 0;
  list-style: none;
}
/*
 * Sub-object item
 * 1. Position items above each other
 */
.uk-slider > * {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
}
/*
 * Clip child elements
 */
.uk-slider-container {
  overflow: hidden;
}
/*
 * Dragged
 */
.uk-slider:not(.uk-drag) {
  -webkit-transition: -webkit-transform 200ms linear;
  transition: transform 200ms linear;
}
/*
 * 1. Makes text unselectable
 */
.uk-slider.uk-drag {
  cursor: col-resize;
  /* 1 */
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/*
 * 1. Prevents images and links from being dragged (default browser behavior)
 * 2. Disables the default callout shown when you touch and hold a touch target
 * Currently only works in Webkit
 */
.uk-slider a,
.uk-slider img {
  /* 1 */
  -webkit-user-drag: none;
  user-drag: none;
  /* 2 */
  -webkit-touch-callout: none;
}
/*
 * 1. Prevents images and links from being dragged in Firefox
 */
.uk-slider img {
  pointer-events: none;
}
/* Modifier: `uk-slider-fullscreen`
 ========================================================================== */
.uk-slider-fullscreen,
.uk-slider-fullscreen > li {
  height: 100vh;
}
/* ========================================================================
   Component: Slideshow
 ========================================================================== */
/*
 * 1. Create position context
 * 2. Create stacking context to prevent z-index issues with other components
 * 3. Set width because child elements are positioned absolute. Height is set via JS
 * 4. Reset list style
 * 5. Clip child elements
 * 6. Deactivate browser history navigation in IE11
 */
.uk-slideshow {
  /* 1 */
  position: relative;
  /* 2 */
  z-index: 0;
  /* 3 */
  width: 100%;
  /* 4 */
  margin: 0;
  padding: 0;
  list-style: none;
  /* 5 */
  overflow: hidden;
  /* 6 */
  touch-action: pan-y;
}
/*
 * Sub-object item
 * 1. Position items above each other
 * 2. Expand to parent container width
 * 3. Hide by default
 */
.uk-slideshow > li {
  /* 1 */
  position: absolute;
  top: 0;
  left: 0;
  /* 2 */
  width: 100%;
  /* 3 */
  opacity: 0;
}
/*
 * Active
 * 1. Stack at first
 * 2. Show slide
 */
.uk-slideshow > .uk-active {
  /* 1 */
  z-index: 10;
  /* 2 */
  opacity: 1;
}
/*
 * Hide default images which is only relevant to keep existing proportions
 */
.uk-slideshow > li > img {
  visibility: hidden;
}
/*
 * Pointer for controls
 */
[data-uk-slideshow-slide] {
  cursor: pointer;
}
/* Modifier: `uk-slideshow-fullscreen`
 ========================================================================== */
.uk-slideshow-fullscreen,
.uk-slideshow-fullscreen > li {
  height: 100vh;
}
/* Animations
 ========================================================================== */
/*
 * Fade
 */
.uk-slideshow-fade-in {
  -webkit-animation: uk-fade 0.5s linear;
  animation: uk-fade 0.5s linear;
}
.uk-slideshow-fade-out {
  -webkit-animation: uk-fade 0.5s linear reverse;
  animation: uk-fade 0.5s linear reverse;
}
/*
 * Scroll
 */
.uk-slideshow-scroll-forward-in {
  -webkit-animation: uk-slide-right 0.5s ease-in-out;
  animation: uk-slide-right 0.5s ease-in-out;
}
.uk-slideshow-scroll-forward-out {
  -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
  animation: uk-slide-left 0.5s ease-in-out reverse;
}
.uk-slideshow-scroll-backward-in {
  -webkit-animation: uk-slide-left 0.5s ease-in-out;
  animation: uk-slide-left 0.5s ease-in-out;
}
.uk-slideshow-scroll-backward-out {
  -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
  animation: uk-slide-right 0.5s ease-in-out reverse;
}
/*
 * Scale
 */
.uk-slideshow-scale-out {
  -webkit-animation: uk-fade-scale-15 0.5s ease-in-out reverse;
  animation: uk-fade-scale-15 0.5s ease-in-out reverse;
}
/*
 * Swipe
 */
.uk-slideshow-swipe-forward-in {
  -webkit-animation: uk-slide-left-33 0.5s ease-in-out;
  animation: uk-slide-left-33 0.5s ease-in-out;
}
.uk-slideshow-swipe-forward-out {
  -webkit-animation: uk-slide-left 0.5s ease-in-out reverse;
  animation: uk-slide-left 0.5s ease-in-out reverse;
}
.uk-slideshow-swipe-backward-in {
  -webkit-animation: uk-slide-right-33 0.5s ease-in-out;
  animation: uk-slide-right-33 0.5s ease-in-out;
}
.uk-slideshow-swipe-backward-out {
  -webkit-animation: uk-slide-right 0.5s ease-in-out reverse;
  animation: uk-slide-right 0.5s ease-in-out reverse;
}
.uk-slideshow-swipe-forward-in:before,
.uk-slideshow-swipe-backward-in:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.6);
  -webkit-animation: uk-fade 0.5s ease-in-out reverse;
  animation: uk-fade 0.5s ease-in-out reverse;
}
/* ========================================================================
   Component: Sticky
 ========================================================================== */
/*
 * 1. More robust if padding and border are used
 */
[data-uk-sticky].uk-active {
  z-index: 980;
  /* 1 */
  box-sizing: border-box;
}
/*
 * Faster animations
 */
[data-uk-sticky][class*='uk-animation-'] {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
[data-uk-sticky].uk-animation-reverse {
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
/* ========================================================================
   Component: Tooltip
 ========================================================================== */
/*
 * 1. Hide by default
 * 2. Set fixed position
 * 3. Set dimensions
 * 4. Set style
 */
.uk-tooltip {
  /* 1 */
  display: none;
  /* 2 */
  position: absolute;
  z-index: 1030;
  /* 3 */
  box-sizing: border-box;
  max-width: 200px;
  padding: 5px 8px;
  /* 4 */
  background: #258bce;
  color: #ffffff;
  font-size: 14px;
  line-height: 21px;
  border-radius: 3px;
  -webkit-animation: uk-fade 0.2s ease-in-out;
  animation: uk-fade 0.2s ease-in-out;
}
/* Triangle
 ========================================================================== */
/*
 * 1. Dashed is less antialised than solid
 */
.uk-tooltip:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  /* 1 */
  border: 5px dashed #258bce;
}
/* Direction modifiers
 ========================================================================== */
/*
 * Top
 */
.uk-tooltip-top:after,
.uk-tooltip-top-left:after,
.uk-tooltip-top-right:after {
  bottom: -5px;
  border-top-style: solid;
  border-bottom: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-top-color: #258bce;
}
/*
 * Bottom
 */
.uk-tooltip-bottom:after,
.uk-tooltip-bottom-left:after,
.uk-tooltip-bottom-right:after {
  top: -5px;
  border-bottom-style: solid;
  border-top: none;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #258bce;
}
/*
 * Top/Bottom center
 */
.uk-tooltip-top:after,
.uk-tooltip-bottom:after {
  left: 50%;
  margin-left: -5px;
}
/*
 * Top/Bottom left
 */
.uk-tooltip-top-left:after,
.uk-tooltip-bottom-left:after {
  left: 10px;
}
/*
 * Top/Bottom right
 */
.uk-tooltip-top-right:after,
.uk-tooltip-bottom-right:after {
  right: 10px;
}
/*
 * Left
 */
.uk-tooltip-left:after {
  right: -5px;
  top: 50%;
  margin-top: -5px;
  border-left-style: solid;
  border-right: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #258bce;
}
/*
 * Right
 */
.uk-tooltip-right:after {
  left: -5px;
  top: 50%;
  margin-top: -5px;
  border-right-style: solid;
  border-left: none;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right-color: #258bce;
}
.uk-animation-fade {
  -webkit-animation-duration: 0.15s;
  animation-duration: 0.15s;
}
@-webkit-keyframes tm-ripple {
  100% {
    opacity: 0;
    -webkit-transform: scale(2.5);
  }
}
@keyframes tm-ripple {
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}
@-webkit-keyframes tm-right-from-left {
  49% {
    -webkit-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes tm-right-from-left {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@-webkit-keyframes uk-fade-top {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-top {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes uk-fade-bottom {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes uk-fade-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-left {
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes uk-fade-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(10%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@keyframes uk-fade-right {
  0% {
    opacity: 0;
    transform: translateX(10%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes tm-floating {
  0% {
    -webkit-transform: translateY(0%);
  }
  50% {
    -webkit-transform: translateY(150%);
  }
  100% {
    -webkit-transform: translateY(0%);
  }
}
@keyframes tm-floating {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(150%);
  }
  100% {
    transform: translateY(0%);
  }
}
@-webkit-keyframes toLeftFromRight {
  49% {
    -webkit-transform: translate(-100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}
@-moz-keyframes toLeftFromRight {
  49% {
    -moz-transform: translate(-100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes toLeftFromRight {
  49% {
    transform: translate(-100%);
  }
  50% {
    opacity: 0;
    transform: translate(100%);
  }
  51% {
    opacity: 1;
  }
}
@-webkit-keyframes toRightFromLeft {
  49% {
    -webkit-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -webkit-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@-moz-keyframes toRightFromLeft {
  49% {
    -moz-transform: translate(100%);
  }
  50% {
    opacity: 0;
    -moz-transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@keyframes toRightFromLeft {
  49% {
    transform: translate(100%);
  }
  50% {
    opacity: 0;
    transform: translate(-100%);
  }
  51% {
    opacity: 1;
  }
}
@-webkit-keyframes tm-rotateplane {
  0% {
    -webkit-transform: perspective(120px);
  }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
@keyframes tm-rotateplane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    background-color: #223148;
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  45% {
    transform: scale(1.75);
    opacity: 0;
  }
}
@keyframes stop-pulsate {
  from {
    opacity: 0.4;
  }
  to {
    transform: scale(2);
    opacity: 0;
  }
}
@font-face {
  font-family: "sandal-icons";
  src: url('../../less/icons/sandal.eot?d7yf1v');
  src: url('../../less/icons/sandal.eot?#iefixd7yf1v') format('embedded-opentype'), url('../../less/icons/sandal.woff?d7yf1v') format('woff'), url('../../less/icons/sandal.ttf?d7yf1v') format('truetype'), url('../../less/icons/sandal.svg?d7yf1v#sandal') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="uk-icon-7s-"],
[class*=" uk-icon-7s-"] {
  display: inline-block;
  font-family: "sandal-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.uk-icon-7s-album:before {
  content: "\e6aa";
}
.uk-icon-7s-arc:before {
  content: "\e6ab";
}
.uk-icon-7s-back-2:before {
  content: "\e6ac";
}
.uk-icon-7s-bandaid:before {
  content: "\e6ad";
}
.uk-icon-7s-car:before {
  content: "\e6ae";
}
.uk-icon-7s-diamond:before {
  content: "\e6af";
}
.uk-icon-7s-door-lock:before {
  content: "\e6b0";
}
.uk-icon-7s-eyedropper:before {
  content: "\e6b1";
}
.uk-icon-7s-female:before {
  content: "\e6b2";
}
.uk-icon-7s-gym:before {
  content: "\e6b3";
}
.uk-icon-7s-hammer:before {
  content: "\e6b4";
}
.uk-icon-7s-headphones:before {
  content: "\e6b5";
}
.uk-icon-7s-helm:before {
  content: "\e6b6";
}
.uk-icon-7s-hourglass:before {
  content: "\e6b7";
}
.uk-icon-7s-leaf:before {
  content: "\e6b8";
}
.uk-icon-7s-magic-wand:before {
  content: "\e6b9";
}
.uk-icon-7s-male:before {
  content: "\e6ba";
}
.uk-icon-7s-map-2:before {
  content: "\e6bb";
}
.uk-icon-7s-next-2:before {
  content: "\e6bc";
}
.uk-icon-7s-paint-bucket:before {
  content: "\e6bd";
}
.uk-icon-7s-pendrive:before {
  content: "\e6be";
}
.uk-icon-7s-photo:before {
  content: "\e6bf";
}
.uk-icon-7s-piggy:before {
  content: "\e6c0";
}
.uk-icon-7s-plugin:before {
  content: "\e6c1";
}
.uk-icon-7s-refresh-2:before {
  content: "\e6c2";
}
.uk-icon-7s-rocket:before {
  content: "\e6c3";
}
.uk-icon-7s-settings:before {
  content: "\e6c4";
}
.uk-icon-7s-shield:before {
  content: "\e6c5";
}
.uk-icon-7s-smile:before {
  content: "\e6c6";
}
.uk-icon-7s-usb:before {
  content: "\e6c7";
}
.uk-icon-7s-vector:before {
  content: "\e6c8";
}
.uk-icon-7s-wine:before {
  content: "\e6c9";
}
.uk-icon-7s-cloud-upload:before {
  content: "\e68a";
}
.uk-icon-7s-cash:before {
  content: "\e68c";
}
.uk-icon-7s-close:before {
  content: "\e680";
}
.uk-icon-7s-bluetooth:before {
  content: "\e68d";
}
.uk-icon-7s-cloud-download:before {
  content: "\e68b";
}
.uk-icon-7s-way:before {
  content: "\e68e";
}
.uk-icon-7s-close-circle:before {
  content: "\e681";
}
.uk-icon-7s-id:before {
  content: "\e68f";
}
.uk-icon-7s-angle-up:before {
  content: "\e682";
}
.uk-icon-7s-wristwatch:before {
  content: "\e690";
}
.uk-icon-7s-angle-up-circle:before {
  content: "\e683";
}
.uk-icon-7s-world:before {
  content: "\e691";
}
.uk-icon-7s-angle-right:before {
  content: "\e684";
}
.uk-icon-7s-volume:before {
  content: "\e692";
}
.uk-icon-7s-angle-right-circle:before {
  content: "\e685";
}
.uk-icon-7s-users:before {
  content: "\e693";
}
.uk-icon-7s-angle-left:before {
  content: "\e686";
}
.uk-icon-7s-user-female:before {
  content: "\e694";
}
.uk-icon-7s-angle-left-circle:before {
  content: "\e687";
}
.uk-icon-7s-up-arrow:before {
  content: "\e695";
}
.uk-icon-7s-angle-down:before {
  content: "\e688";
}
.uk-icon-7s-switch:before {
  content: "\e696";
}
.uk-icon-7s-angle-down-circle:before {
  content: "\e689";
}
.uk-icon-7s-scissors:before {
  content: "\e697";
}
.uk-icon-7s-wallet:before {
  content: "\e600";
}
.uk-icon-7s-safe:before {
  content: "\e698";
}
.uk-icon-7s-volume2:before {
  content: "\e601";
}
.uk-icon-7s-volume1:before {
  content: "\e602";
}
.uk-icon-7s-voicemail:before {
  content: "\e603";
}
.uk-icon-7s-video:before {
  content: "\e604";
}
.uk-icon-7s-user:before {
  content: "\e605";
}
.uk-icon-7s-upload:before {
  content: "\e606";
}
.uk-icon-7s-unlock:before {
  content: "\e607";
}
.uk-icon-7s-umbrella:before {
  content: "\e608";
}
.uk-icon-7s-trash:before {
  content: "\e609";
}
.uk-icon-7s-tools:before {
  content: "\e60a";
}
.uk-icon-7s-timer:before {
  content: "\e60b";
}
.uk-icon-7s-ticket:before {
  content: "\e60c";
}
.uk-icon-7s-target:before {
  content: "\e60d";
}
.uk-icon-7s-sun:before {
  content: "\e60e";
}
.uk-icon-7s-study:before {
  content: "\e60f";
}
.uk-icon-7s-stopwatch:before {
  content: "\e610";
}
.uk-icon-7s-star:before {
  content: "\e611";
}
.uk-icon-7s-speaker:before {
  content: "\e612";
}
.uk-icon-7s-signal:before {
  content: "\e613";
}
.uk-icon-7s-shuffle:before {
  content: "\e614";
}
.uk-icon-7s-shopbag:before {
  content: "\e615";
}
.uk-icon-7s-share:before {
  content: "\e616";
}
.uk-icon-7s-server:before {
  content: "\e617";
}
.uk-icon-7s-search:before {
  content: "\e618";
}
.uk-icon-7s-film:before {
  content: "\e6a5";
}
.uk-icon-7s-science:before {
  content: "\e619";
}
.uk-icon-7s-disk:before {
  content: "\e6a6";
}
.uk-icon-7s-ribbon:before {
  content: "\e61a";
}
.uk-icon-7s-repeat:before {
  content: "\e61b";
}
.uk-icon-7s-refresh:before {
  content: "\e61c";
}
.uk-icon-7s-add-user:before {
  content: "\e6a9";
}
.uk-icon-7s-refresh-cloud:before {
  content: "\e61d";
}
.uk-icon-7s-paperclip:before {
  content: "\e69c";
}
.uk-icon-7s-radio:before {
  content: "\e61e";
}
.uk-icon-7s-note2:before {
  content: "\e69d";
}
.uk-icon-7s-print:before {
  content: "\e61f";
}
.uk-icon-7s-network:before {
  content: "\e69e";
}
.uk-icon-7s-prev:before {
  content: "\e620";
}
.uk-icon-7s-mute:before {
  content: "\e69f";
}
.uk-icon-7s-power:before {
  content: "\e621";
}
.uk-icon-7s-medal:before {
  content: "\e6a0";
}
.uk-icon-7s-portfolio:before {
  content: "\e622";
}
.uk-icon-7s-like2:before {
  content: "\e6a1";
}
.uk-icon-7s-plus:before {
  content: "\e623";
}
.uk-icon-7s-left-arrow:before {
  content: "\e6a2";
}
.uk-icon-7s-play:before {
  content: "\e624";
}
.uk-icon-7s-key:before {
  content: "\e6a3";
}
.uk-icon-7s-plane:before {
  content: "\e625";
}
.uk-icon-7s-joy:before {
  content: "\e6a4";
}
.uk-icon-7s-photo-gallery:before {
  content: "\e626";
}
.uk-icon-7s-pin:before {
  content: "\e69b";
}
.uk-icon-7s-phone:before {
  content: "\e627";
}
.uk-icon-7s-plug:before {
  content: "\e69a";
}
.uk-icon-7s-pen:before {
  content: "\e628";
}
.uk-icon-7s-right-arrow:before {
  content: "\e699";
}
.uk-icon-7s-paper-plane:before {
  content: "\e629";
}
.uk-icon-7s-delete-user:before {
  content: "\e6a7";
}
.uk-icon-7s-paint:before {
  content: "\e62a";
}
.uk-icon-7s-bottom-arrow:before {
  content: "\e6a8";
}
.uk-icon-7s-notebook:before {
  content: "\e62b";
}
.uk-icon-7s-note:before {
  content: "\e62c";
}
.uk-icon-7s-next:before {
  content: "\e62d";
}
.uk-icon-7s-news-paper:before {
  content: "\e62e";
}
.uk-icon-7s-musiclist:before {
  content: "\e62f";
}
.uk-icon-7s-music:before {
  content: "\e630";
}
.uk-icon-7s-mouse:before {
  content: "\e631";
}
.uk-icon-7s-more:before {
  content: "\e632";
}
.uk-icon-7s-moon:before {
  content: "\e633";
}
.uk-icon-7s-monitor:before {
  content: "\e634";
}
.uk-icon-7s-micro:before {
  content: "\e635";
}
.uk-icon-7s-menu:before {
  content: "\e636";
}
.uk-icon-7s-map:before {
  content: "\e637";
}
.uk-icon-7s-map-marker:before {
  content: "\e638";
}
.uk-icon-7s-mail:before {
  content: "\e639";
}
.uk-icon-7s-mail-open:before {
  content: "\e63a";
}
.uk-icon-7s-mail-open-file:before {
  content: "\e63b";
}
.uk-icon-7s-magnet:before {
  content: "\e63c";
}
.uk-icon-7s-loop:before {
  content: "\e63d";
}
.uk-icon-7s-look:before {
  content: "\e63e";
}
.uk-icon-7s-lock:before {
  content: "\e63f";
}
.uk-icon-7s-lintern:before {
  content: "\e640";
}
.uk-icon-7s-link:before {
  content: "\e641";
}
.uk-icon-7s-like:before {
  content: "\e642";
}
.uk-icon-7s-light:before {
  content: "\e643";
}
.uk-icon-7s-less:before {
  content: "\e644";
}
.uk-icon-7s-keypad:before {
  content: "\e645";
}
.uk-icon-7s-junk:before {
  content: "\e646";
}
.uk-icon-7s-info:before {
  content: "\e647";
}
.uk-icon-7s-home:before {
  content: "\e648";
}
.uk-icon-7s-help2:before {
  content: "\e649";
}
.uk-icon-7s-help1:before {
  content: "\e64a";
}
.uk-icon-7s-graph3:before {
  content: "\e64b";
}
.uk-icon-7s-graph2:before {
  content: "\e64c";
}
.uk-icon-7s-graph1:before {
  content: "\e64d";
}
.uk-icon-7s-graph:before {
  content: "\e64e";
}
.uk-icon-7s-global:before {
  content: "\e64f";
}
.uk-icon-7s-gleam:before {
  content: "\e650";
}
.uk-icon-7s-glasses:before {
  content: "\e651";
}
.uk-icon-7s-gift:before {
  content: "\e652";
}
.uk-icon-7s-folder:before {
  content: "\e653";
}
.uk-icon-7s-flag:before {
  content: "\e654";
}
.uk-icon-7s-filter:before {
  content: "\e655";
}
.uk-icon-7s-file:before {
  content: "\e656";
}
.uk-icon-7s-expand1:before {
  content: "\e657";
}
.uk-icon-7s-expand2:before {
  content: "\e658";
}
.uk-icon-7s-edit:before {
  content: "\e659";
}
.uk-icon-7s-drop:before {
  content: "\e65a";
}
.uk-icon-7s-drawer:before {
  content: "\e65b";
}
.uk-icon-7s-download:before {
  content: "\e65c";
}
.uk-icon-7s-display2:before {
  content: "\e65d";
}
.uk-icon-7s-display1:before {
  content: "\e65e";
}
.uk-icon-7s-diskette:before {
  content: "\e65f";
}
.uk-icon-7s-date:before {
  content: "\e660";
}
.uk-icon-7s-cup:before {
  content: "\e661";
}
.uk-icon-7s-culture:before {
  content: "\e662";
}
.uk-icon-7s-crop:before {
  content: "\e663";
}
.uk-icon-7s-credit:before {
  content: "\e664";
}
.uk-icon-7s-copy-file:before {
  content: "\e665";
}
.uk-icon-7s-config:before {
  content: "\e666";
}
.uk-icon-7s-compass:before {
  content: "\e667";
}
.uk-icon-7s-comment:before {
  content: "\e668";
}
.uk-icon-7s-coffee:before {
  content: "\e669";
}
.uk-icon-7s-cloud:before {
  content: "\e66a";
}
.uk-icon-7s-clock:before {
  content: "\e66b";
}
.uk-icon-7s-check:before {
  content: "\e66c";
}
.uk-icon-7s-chat:before {
  content: "\e66d";
}
.uk-icon-7s-cart:before {
  content: "\e66e";
}
.uk-icon-7s-camera:before {
  content: "\e66f";
}
.uk-icon-7s-call:before {
  content: "\e670";
}
.uk-icon-7s-calculator:before {
  content: "\e671";
}
.uk-icon-7s-browser:before {
  content: "\e672";
}
.uk-icon-7s-box2:before {
  content: "\e673";
}
.uk-icon-7s-box1:before {
  content: "\e674";
}
.uk-icon-7s-bookmarks:before {
  content: "\e675";
}
.uk-icon-7s-bicycle:before {
  content: "\e676";
}
.uk-icon-7s-bell:before {
  content: "\e677";
}
.uk-icon-7s-battery:before {
  content: "\e678";
}
.uk-icon-7s-ball:before {
  content: "\e679";
}
.uk-icon-7s-back:before {
  content: "\e67a";
}
.uk-icon-7s-attention:before {
  content: "\e67b";
}
.uk-icon-7s-anchor:before {
  content: "\e67c";
}
.uk-icon-7s-albums:before {
  content: "\e67d";
}
.uk-icon-7s-alarm:before {
  content: "\e67e";
}
.uk-icon-7s-airplay:before {
  content: "\e67f";
}
/* ========================================================================
// Warp theme
 ========================================================================== */
/* Layout
 ========================================================================== */
html {
  overflow-y: scroll;
  overflow: visible;
  background-color: #223148;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
body {
  overflow-x: hidden;
  position: relative;
}
.tm-container {
  background-color: #223148;
  border-top: 3px solid #2a9eea;
  border-bottom: 3px solid #2a9eea;
  min-height: 100vh;
}
.tm-preload {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.tm-preload .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  background-color: #258bce;
  margin-left: -20px;
  margin-top: -20px;
  -webkit-animation: tm-rotateplane 1.2s infinite ease-in-out;
  animation: tm-rotateplane 1.2s infinite ease-in-out;
}
.no-preload .tm-preload {
  display: none !important;
}
.tm-block > div .uk-grid {
  overflow: hidden;
}
.tm-block,
.tm-block-large-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.tm-block.tm-block-large-padding {
  padding: 60px;
}
.tm-block.tm-block-no-padding {
  padding: 0;
}
.tm-block-default {
  background: #ffffff;
}
.tm-block-padding {
  padding: 30px;
}
.tm-block-main,
#tm-spotlight,
#tm-header-hero,
#tm-hero {
  clear: both;
}
.tm-block-fullwidth {
  padding: 0;
}
.tm-block-fullwidth > .uk-container {
  max-width: 100%;
  padding: 0;
}
.tm-main-top > div > .uk-panel-box,
.tm-main-bottom > div > .uk-panel-box {
  padding: 40px;
}
.tm-breadcrumbs {
  background-color: #eef4ff;
  box-shadow: inset 0 -1px 0 #dfeaff, inset 0 1px 0 #dfeaff;
}
.tm-block-bottom-e {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.3), inset 0 2px 0 rgba(255, 255, 255, 0.1), inset 0 50px 100px rgba(0, 0, 0, 0.1);
}
.tm-block-bottom-e .uk-icon-button {
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0);
  line-height: 26px;
  height: 25px;
  font-weight: 400;
  width: 30px;
}
@media (max-width: 900px) {
  .tm-block-bottom-e {
    text-align: center;
  }
  .tm-block-bottom-e .uk-text-right {
    text-align: center !important;
  }
}
.tm-block-collapse,
.tm-block-collapse .uk-grid > div {
  padding: 0;
}
.tm-grid-collapse > .uk-container::before,
.tm-grid-collapse > .uk-container::after {
  display: none;
}
.tm-grid-collapse > .uk-container > .uk-grid {
  width: 100%;
  margin-left: 0;
}
.tm-grid-collapse > .uk-container > .uk-grid > * {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0;
}
.tm-container-boxed {
  background-color: #ffffff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 2px 2px 1px rgba(0, 0, 0, 0.05);
}
.tm-container-boxed.uk-container {
  margin: 40px auto 60px;
  padding: 0;
}
.tm-boxed-layout .tm-toolbar > .uk-container,
.tm-boxed-layout .tm-navbar > .uk-container {
  padding: 0;
}
.tm-boxed-layout .tm-header > .uk-container,
.tm-boxed-layout .uk-block.tm-block-main > .uk-container,
.tm-boxed-layout .uk-block:not(.tm-block-fullwidth) > .uk-container {
  padding: 0 30px;
}
.tm-float-box {
  max-width: 1300px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  box-shadow: inset 5px 0 0 rgba(255, 255, 255, 0.15), inset -5px 0 0 rgba(255, 255, 255, 0.15);
}
.tm-float-box:before {
  content: "";
  width: 25%;
  height: 192px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.075);
  position: absolute;
  right: -25px;
  z-index: 0;
  top: -30px;
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}
.tm-slideshow-sandal > .tm-float-padding {
  bottom: 54px !important;
}
#tm-spotlight.tm-float-box {
  margin: -64px auto 0;
}
#tm-spotlight.tm-float-box + div {
  margin-top: -64px;
  padding-top: 64px;
}
@media (max-width: 1220px) {
  .tm-container-boxed.uk-container {
    margin: 0 auto 0;
  }
  .tm-container-boxed .uk-block.tm-block-main > .uk-container,
  .tm-container-boxed .uk-block:not(.tm-block-fullwidth) > .uk-container {
    padding: 0 30px;
  }
  .tm-float-box:before {
    display: none;
  }
  #tm-spotlight.tm-float-box {
    margin: 0 auto 0;
  }
  #tm-spotlight.tm-float-box + div {
    margin: 0;
    padding-top: 0;
  }
}
@media (min-width: 1220px) {
  .tm-boxed-layout .uk-block:not(.tm-block-fullwidth) > .uk-container {
    padding: 0 40px;
  }
}
.tm-toolbar {
  background: #eef4ff;
  box-shadow: inset 0 -1px 0 #dfeaff;
  padding: 10px 0;
}
.tm-toolbar .uk-subnav {
  margin-top: 0;
}
.tm-toolbar .uk-subnav > li {
  line-height: 30px;
}
.tm-toolbar li,
.tm-toolbar p {
  color: #2a455d;
  margin: 0;
}
.tm-toolbar a,
.tm-toolbar .uk-subnav > li > a {
  color: #258bce;
  text-decoration: none;
}
.tm-toolbar a:hover,
.tm-toolbar .uk-subnav > li > a:hover {
  color: #2a455d;
}
.tm-toolbar .uk-button-dropdown {
  display: block;
}
.tm-toolbar .uk-button-dropdown .uk-dropdown .uk-nav {
  margin: 0;
}
.tm-toolbar .uk-dropdown {
  background-color: #eef4ff;
  padding: 0;
  box-shadow: inset -1px -1px 0 #dfeaff, inset 1px 0 0 #dfeaff;
}
.tm-toolbar .uk-dropdown .uk-nav-dropdown > li > a {
  color: #258bce;
  padding: 5px 15px;
}
.tm-toolbar .uk-dropdown .uk-nav-dropdown > li > a:hover {
  background-color: #d5e3ff;
}
.tm-toolbar .uk-button-link {
  line-height: 28px;
  min-height: 30px;
  padding: 0;
  border: 0;
  outline: none;
  text-decoration: none;
}
.tm-toolbar .uk-button-link:focus {
  color: #258bce;
}
.tm-toolbar .uk-button-link:after {
  display: none;
}
.tm-toolbar .uk-button-link i:before {
  color: #bbd3ff;
  text-shadow: 0 1px 0 #ffffff;
  font-size: 15px;
}
.tm-toolbar .list-icons {
  padding: 0;
  margin: 0;
}
.tm-toolbar .list-icons li {
  float: left;
  margin-right: 20px;
}
.tm-toolbar .list-icons li i {
  color: #2a455d;
}
.tm-toolbar .uk-form :-ms-input-placeholder {
  color: #2a455d;
}
.tm-toolbar .uk-form ::-moz-placeholder {
  color: #2a455d;
}
.tm-toolbar .uk-form ::-webkit-input-placeholder {
  color: #2a455d;
}
.tm-toolbar .uk-form input[type="text"] {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  line-height: 30px;
  height: 30px;
  color: #2a455d;
  border-radius: 5px;
  font-size: 14px;
}
.tm-toolbar .uk-form input[type="text"]:focus {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  color: #2a455d;
}
.tm-toolbar .uk-icon-button {
  font-size: 16px;
  line-height: 26px;
  height: 25px;
  font-weight: 400;
  width: 30px;
}
.tm-toolbar .uk-subnav-line > li:nth-child(n+2):before {
  border: 0;
  width: 5px;
  height: 5px;
  box-shadow: 0 1px 0 #ffffff;
  background: #bbd3ff;
  position: relative;
}
.tm-toolbar .uk-nav li > a > div {
  line-height: 24px;
  word-wrap: break-word;
  margin-top: 10px;
}
.tm-toolbar .uk-subnav {
  margin-bottom: 0;
}
.tm-toolbar .uk-subnav > * {
  padding-left: 15px;
}
.tm-toolbar .uk-float-left > div {
  float: left;
  margin-right: 30px;
}
.tm-toolbar .uk-float-right > div {
  float: right;
  margin-left: 30px;
}
.tm-logo {
  display: block;
}
.tm-logo-small p {
  display: inherit;
}
.tm-logo .tm-logo-sticky {
  display: none;
}
.tm-header.uk-active .tm-logo .tm-logo-sticky {
  display: block;
}
.tm-header.uk-active .tm-logo .tm-logo-sticky + img:not(.tm-logo-sticky) {
  display: none;
}
.tm-header {
  background-color: #ffffff;
  position: relative;
  padding: 30px 0;
}
.tm-header.tm-header-right {
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: inset 0 -1px 0 #e4edff;
}
.tm-header.tm-header-right.uk-active {
  box-shadow: inset 0 -1px 0 #f7f7f7;
}
.tm-header.tm-header-right .uk-navbar-nav > li > a {
  padding: 0 20px;
}
.tm-header .tm-navbar-more .uk-dropdown {
  background-color: #ffffff;
}
.tm-headerbar-right {
  clear: both;
}
.tm-headerbar {
  display: block;
  max-width: 70%;
  padding-right: 20px;
  background-color: #ffffff;
}
.tm-headerbar h1,
.tm-headerbar h2,
.tm-headerbar h3,
.tm-headerbar h4,
.tm-headerbar h5,
.tm-headerbar h6 {
  margin: 0;
  color: #2a455d;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  white-space: nowrap;
}
.tm-headerbar .uk-grid > * {
  padding-left: 10px;
}
.tm-headerbar .tm-block-icon {
  font-size: 26px;
  border: none;
  margin-right: 5px;
  color: #2a455d;
}
.tm-headerbar .tm-block-icon[class^="uk-icon-7s-"],
.tm-headerbar .tm-block-icon[class*=" uk-icon-7s-"] {
  font-size: 30px;
}
.tm-headerbar .tm-block-content {
  display: table;
  margin-right: 30px;
  color: #2a455d;
}
.tm-headerbar p {
  margin: 0;
  color: #2a455d;
  font-size: 14px;
  /* fix for firefox */
  display: -moz-inline-box;
}
.tm-headerbar:not(.tm-headerbar-center) > div {
  margin-left: 20px;
}
.tm-headerbar-container {
  background-color: #eef4ff;
  color: #2a455d;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.05);
  overflow: hidden;
}
.tm-headerbar-container .tm-headerbar {
  max-width: 100%;
  padding: 20px 0;
  position: relative;
  background-color: #eef4ff;
}
.tm-headerbar-container .tm-headerbar .tm-block-icon {
  color: #258bce;
}
.tm-headerbar-container .tm-headerbar h1,
.tm-headerbar-container .tm-headerbar h2,
.tm-headerbar-container .tm-headerbar h3,
.tm-headerbar-container .tm-headerbar h4,
.tm-headerbar-container .tm-headerbar h5,
.tm-headerbar-container .tm-headerbar h6,
.tm-headerbar-container .tm-headerbar p {
  color: #2a455d;
}
.tm-headerbar-container .tm-headerbar .uk-grid > div:before {
  content: "";
  width: 250px;
  height: 150%;
  position: absolute;
  background: -moz-linear-gradient(left, #eef4ff 0%, #e4eeff 100%);
  background: -webkit-linear-gradient(left, #eef4ff 0%, #e4eeff 100%);
  background: linear-gradient(to right, #eef4ff 0%, #e4eeff 100%);
  box-shadow: 1px 0 0 #d5e3ff, inset -1px 0 0 #f3f7ff;
  bottom: 0;
  -webkit-transform: rotate(10deg);
  -moz-transform: rotate(10deg);
  -ms-transform: rotate(10deg);
  transform: rotate(10deg);
}
.tm-headerbar-container .tm-headerbar div > .uk-flex {
  position: relative;
}
.tm-right-offcanvas {
  position: absolute;
  right: 10px;
  top: 25px;
}
.tm-right-offcanvas .uk-navbar-toggle:before {
  content: "";
}
@media (max-width: 900px) {
  .tm-headerbar-container .tm-headerbar .uk-grid > div:before {
    display: none;
  }
  .tm-headerbar:not(.tm-headerbar-center) > div {
    margin-left: 0;
  }
  .tm-header {
    padding: 20px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }
  .tm-header .uk-container {
    padding: 0 20px;
  }
  .tm-headerbar {
    max-width: 100%;
    padding-right: 0;
  }
  .tm-headerbar > div {
    padding: 30px;
    margin: 0;
  }
  .tm-headerbar-container .tm-headerbar {
    padding: 0;
  }
}
.tm-navbar-more {
  position: relative;
  padding: 0;
}
.tm-navbar-more > a {
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-left: -10px;
  border-radius: 3px;
  font-family: "fontAwesome";
  font-size: 18px;
  line-height: 32px;
  color: #258bce;
  border: 1px solid #258bce;
  text-align: center;
  -webkit-transition: background 0.1s ease-in-out;
  transition: background 0.1s ease-in-out;
}
.tm-navbar-more > a:before {
  content: "\f002";
  font-size: 15px;
}
.tm-navbar-more > a:hover {
  color: #258bce;
  background: #eef4ff;
}
.tm-navbar-more > a:active {
  color: #2a455d;
  background: #258bce;
}
.tm-navbar-more div.uk-dropdown {
  padding: 20px 10px;
}
.tm-navbar {
  background-color: #2d415f;
  position: relative;
  z-index: 10;
  clear: both;
  box-shadow: inset 0 -1px 0 #283a55, inset 0 1px 0 #283a55, inset 0 2px 0 #324869;
}
.tm-navbar.tm-navbar-transparent:not(.uk-active) {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}
.tm-navbar.tm-navbar-transparent:not(.uk-active) > .uk-container {
  padding-right: 0;
}
.tm-navbar.uk-active {
  background-color: #2d415f;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
}
.tm-navbar.uk-active .uk-navbar-nav > li > a {
  color: #ffffff;
}
.tm-navbar .uk-navbar-nav li a.uk-active,
.tm-navbar .tm-active-menu {
  background-color: #258bce !important;
  color: #ffffff !important;
}
.tm-navbar .uk-navbar-nav-subtitle > div {
  text-transform: lowercase;
  margin-top: -15px;
}
.tm-navbar .uk-navbar-nav > li > a i {
  padding-right: 5px;
}
.tm-navbar .uk-nav-side > li > a,
.tm-navbar .uk-navbar-nav > li > a,
.tm-navbar .uk-nav-side ul a {
  color: #ffffff;
}
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-nav-side > li > a,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-navbar-nav > li > a,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-nav-side ul a {
  color: #2a455d;
}
.tm-navbar .uk-navbar-nav > li:hover > a,
.tm-navbar .uk-navbar-nav > li.uk-open > a,
.tm-navbar .uk-navbar-nav > li.uk-active > a,
.tm-navbar .uk-nav-side > li.uk-active > a {
  background-color: #258bce;
  color: #ffffff;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-navbar-nav > li:hover > a,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-navbar-nav > li > a:focus,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-navbar-nav > li.uk-open > a,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-navbar-nav > li.uk-active > a,
.tm-navbar.tm-navbar-transparent:not(.uk-active) .uk-nav-side > li.uk-active > a {
  background-color: rgba(0, 0, 0, 0);
  color: #258bce;
  box-shadow: none;
}
.tm-navbar .uk-navbar-nav > li .uk-dropdown-navbar {
  background-color: #ffffff;
}
.tm-navbar > ul > li.uk-parent > a:before {
  position: absolute;
  content: "fontAwesome";
  right: 15px;
  font-family: "fontAwesome";
  font-size: 16px;
  line-height: 70px;
  color: #25354e;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.tm-navbar > ul > li:hover > a:before,
.tm-navbar > ul > li.uk-parent.uk-open > a:before,
.tm-navbar > ul > li.uk-parent.uk-active > a:before {
  color: #195f8d;
}
@media (max-width: 900px) {
  .tm-navbar .tm-navbar {
    background-color: #2d415f;
    min-height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.tm-navbar .uk-navbar-nav > li > a {
  color: #ffffff;
}
@media (max-width: 959px) {
  .tm-navbar .uk-container {
    padding: 0;
  }
}
.uk-navbar-toggle {
  height: 35px;
  color: #2a455d;
}
.uk-navbar-toggle:before {
  content: "menu";
  text-transform: uppercase;
  letter-spacing: 1px;
  height: 28px;
  line-height: 28px;
  margin-right: 10px;
  color: #2a455d;
}
@media (min-width: 899px) {
  .tm-navbar-container {
    box-sizing: border-box;
    min-height: 70px;
  }
}
.uk-navbar-nav li > a,
.uk-button,
.uk-subnav-pill > li > a,
.uk-nav-side > li > a,
.uk-nav-offcanvas > li > a {
  position: relative;
  overflow: hidden;
  z-index: 0;
  -webkit-transform-style: preserve-3d;
}
.tm-ripple {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.tm-animate-ripple {
  -webkit-animation: tm-ripple 0.65s linear;
  animation: tm-ripple 0.65s linear;
}
.tm-main > :nth-child(n+2) {
  margin-top: 30px;
}
.tm-content .uk-pagination > li > span,
.tm-content .uk-pagination > li > a {
  box-shadow: none;
}
.uk-article .uk-panel-box:not(.tm-panel-card),
.tm-content .uk-panel-box:not(.tm-panel-card) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.uk-article .uk-panel-header,
.tm-content .uk-panel-header {
  box-shadow: none;
}
.tm-content > :last-child {
  margin-bottom: 0;
}
.tm-highlight-block {
  color: #2a455d;
  font-family: 'Exo 2';
  font-size: 24px;
  font-weight: 500;
  line-height: 39.6px;
  margin: 10px 20px 10px 0;
  padding: 10px 0 15px;
  width: 45%;
  position: relative;
}
.tm-highlight-block:before,
.tm-highlight-block:after {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  z-index: 0;
  top: -10px;
  border-radius: 2px;
}
.tm-highlight-block:before {
  background-color: #258bce;
}
.tm-highlight-block:after {
  background-color: #223148;
}
.tm-highlight-block.tm-highlight-left {
  float: left;
  text-align: right;
  padding-right: 30px;
  margin-right: 30px;
  border-left: 3px solid #eef4ff;
}
.tm-highlight-block.tm-highlight-left:before {
  left: 0;
}
.tm-highlight-block.tm-highlight-left:after {
  left: 20px;
}
.tm-highlight-block.tm-highlight-right {
  float: right;
  text-align: left;
  padding-left: 30px;
  margin-left: 30px;
  border-left: 3px solid #eef4ff;
}
.tm-highlight-block.tm-highlight-right:before {
  right: 0;
}
.tm-highlight-block.tm-highlight-right:after {
  right: 20px;
}
@media (max-width: 900px) {
  .tm-highlight-block {
    width: 100%;
    border: 0 !important;
    margin: 10px 0;
  }
}
.pagenavcounter {
  float: right;
  font-size: 14px;
  letter-spacing: 1px;
}
.tm-grid-filters {
  margin-left: -20px;
}
.tm-grid-filters.uk-subnav > * {
  padding-left: 20px;
}
.tm-grid-filters.uk-subnav > li a {
  padding: 0 10px;
}
.tm-totop-scroller {
  display: none;
  position: fixed;
  right: 25px;
  bottom: -50px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 35px;
  -webkit-transition: all ease-in-out 0.05s;
  transition: all ease-in-out 0.05s;
}
.tm-totop-scroller:after {
  content: "\f106";
  font-family: "fontAwesome";
}
.tm-totop-scroller:hover,
.tm-totop-scroller:focus {
  outline: none;
  text-decoration: none;
}
.tm-totop-scroller:active {
  font-size: 26px;
}
.uk-active .tm-totop-scroller {
  display: block;
  bottom: 20px;
  opacity: 1;
}
.tm-grid-title {
  background: #223148;
  color: #ffffff;
  display: inline-block;
  padding: 5px 10px;
  text-transform: none;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
}
.tm-overlay-content,
.tm-grid-content {
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}
.tm-grid-content {
  z-index: 1;
  position: relative;
}
.tm-dynamic-grid figure img {
  width: 100%;
}
.tm-panel-box-primary-color {
  background: #223148;
  color: #ffffff;
}
.tm-panel-box-primary-color a {
  color: #ffffff;
}
.tm-panel-box-secondary-color {
  background: #258bce;
  color: #ffffff;
}
.tm-panel-box-secondary-color a {
  color: #ffffff;
}
.tm-navigation-bar {
  position: fixed;
  right: 20px;
  top: 45%;
}
.tm-navigation-bar .uk-dotnav {
  font-size: 0.001px;
  list-style: none outside none;
  padding: 0;
  width: 20px;
}
.tm-navigation-bar .uk-dotnav > li {
  border-radius: 100%;
  height: 15px;
  width: 15px;
  padding-left: 0;
  margin: 15px 0 0 !important;
}
.tm-navigation-bar .uk-dotnav > li > a {
  height: 10px;
  width: 10px;
  box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.15), 0 0 0 5px rgba(0, 0, 0, 0.05);
}
.tm-navigation-bar .uk-dotnav > li > a:hover,
.tm-navigation-bar .uk-dotnav > li > a:focus {
  background-color: rgba(50, 50, 50, 0.2);
  outline: medium none;
}
.tm-navigation-bar .uk-dotnav > li.uk-active > a {
  background-color: #258bce;
}
.uk-module-title {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
  text-transform: none;
}
.uk-module-title:after {
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  border-bottom: 1px solid #258bce;
}
.uk-module-title.uk-text-right:after {
  left: auto;
  right: 0;
}
.uk-module-title-alt {
  position: relative;
  display: table;
  letter-spacing: -0.5px;
  text-transform: none;
}
.uk-module-title-alt:before,
.uk-module-title-alt:after {
  content: "";
  position: absolute;
  bottom: 40%;
  height: 1px;
  width: 40px;
  margin: 0 -15px;
  background-color: #2a455d;
}
.uk-module-title-alt:before {
  left: -40px;
  height: 0;
}
.uk-module-title-alt:after {
  right: -40px;
}
.uk-contrast .uk-sub-title,
.uk-contrast .uk-module-title-alt {
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.uk-contrast .uk-module-title-alt:before,
.uk-contrast .uk-module-title-alt:after {
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.uk-contrast .uk-panel-title,
.uk-contrast .uk-module-title {
  border-bottom-color: #ffffff;
}
.uk-panel-box-secondary .uk-module-title-alt:before,
.tm-secondary-background .uk-module-title-alt:before,
.uk-panel-box-secondary .uk-module-title-alt:after,
.tm-secondary-background .uk-module-title-alt:after {
  background-color: #ffffff;
}
.uk-flex-center .uk-module-title-alt,
.uk-text-center .uk-module-title-alt {
  display: inline-block;
}
.uk-flex-center .uk-module-title-alt:before,
.uk-text-center .uk-module-title-alt:before {
  height: 1px;
}
.uk-sub-title,
.uk-sub-title-small {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0 0 15px 0;
  font-family: 'Varela';
  font-size: 16px;
  color: #258bce;
}
.uk-sub-title-small {
  font-size: 14px;
}
.tm-block-digit {
  text-align: center;
  margin: 5px 30px 0 0;
}
.tm-block-text {
  font-size: 32px;
  font-family: 'Exo 2';
  font-weight: 500;
  background-color: #223148;
  color: #ffffff;
  min-width: 50px;
  line-height: 50px;
  border-radius: 0 3px 3px 0;
}
.tm-block-time {
  background: #ffffff;
  color: #2a455d;
  text-align: center;
  margin: 5px 30px 15px 0;
}
.tm-block-left,
.tm-block-month {
  padding: 0 5px;
  border-radius: 3px 3px 0 0;
  background: #258bce;
  color: #ffffff;
  width: 40px;
  line-height: 26px;
  float: left;
}
.tm-block-month {
  letter-spacing: 1px;
  font-size: 14px;
  text-transform: uppercase;
  margin-left: -24px;
  -webkit-transform: rotateZ(-90deg) translateY(12px) translateX(-12px);
  transform: rotateZ(-90deg) translateY(12px) translateX(-12px);
}
.tm-block-left {
  min-height: 10px;
  margin-left: -40px;
  -webkit-transform: rotateZ(-90deg) translateY(20px) translateX(-20px);
  transform: rotateZ(-90deg) translateY(20px) translateX(-20px);
}
.tm-event-descr {
  margin: 0 0 15px;
}
.tm-block-icon {
  font-size: 32px;
  padding: 15px 15px 0 0;
  color: #258bce;
  border-right: 1px dashed #dfeaff;
  margin-right: 20px;
}
.tm-block-number {
  margin-bottom: 10px;
}
.shortcode-icons {
  padding-left: 0;
}
.shortcode-icons li {
  display: inline-block;
  list-style-type: none;
}
.shortcode-icons li a {
  font-size: 24px;
}
.tagspopular ul {
  padding-left: 0;
}
.tagspopular ul li {
  list-style-type: none;
  float: left;
}
.tm-tags-list {
  padding: 0 0 10px;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
}
.tm-tags-list ul li {
  display: inline-block;
}
.tm-tags-list a,
.tagspopular ul li a,
.tm-tag {
  line-height: 25px;
  min-height: 25px;
  font-size: 14px;
  font-family: 'Varela';
  margin: 5px 10px 10px 0;
  padding: 0 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  display: inline-block;
  border-radius: 3px;
  vertical-align: middle;
  background-color: #258bce;
  color: #ffffff;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
}
.tm-tags-list a:focus,
.tagspopular ul li a:focus,
.tm-tag:focus,
.tm-tags-list a:hover,
.tagspopular ul li a:hover,
.tm-tag:hover {
  text-decoration: none;
  color: #ffffff;
}
.uk-button-link {
  text-decoration: none;
  position: relative;
}
.uk-button-link:hover,
.uk-button-link.uk-link:hover {
  text-decoration: none;
}
.uk-button-link:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 5px;
  left: 0;
  background-color: rgba(37, 139, 206, 0.8);
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.uk-button-link:hover:after {
  text-decoration: none;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}
.tm-primary-background {
  background-color: #258bce !important;
  color: #ffffff !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
}
.tm-secondary-background {
  background-color: #223148 !important;
  color: #ffffff !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
}
.tm-primary-background h1,
.tm-secondary-background h1,
.tm-primary-background h2,
.tm-secondary-background h2,
.tm-primary-background h3,
.tm-secondary-background h3,
.tm-primary-background h4,
.tm-secondary-background h4,
.tm-primary-background h5,
.tm-secondary-background h5,
.tm-primary-background h6,
.tm-secondary-background h6 {
  color: #ffffff;
}
.tm-muted-background {
  background-color: #eef4ff;
}
.tm-primary-color {
  color: #258bce !important;
}
.tm-secondary-color {
  color: #ffffff !important;
}
.tm-rating {
  list-style-type: none;
  padding: 0;
  margin: 0 0 20px;
}
.tm-rating li {
  color: #258bce;
  padding-right: 1px;
  display: inline-block;
  line-height: 20px;
}
.tm-rating.tm-rating-small {
  margin: 0;
  line-height: 1;
}
.tm-rating.tm-rating-small li {
  font-size: 11px;
  line-height: 12px;
  padding: 0;
}
.tm-text-large {
  line-height: 1;
  font-size: 105px;
  font-weight: 300;
}
.tm-text-xlarge {
  line-height: 1 !important;
  font-size: 80px;
  font-weight: 300;
}
.tm-border-bottom {
  box-shadow: 0 2px 0 #258bce;
}
.tm-thin-font {
  font-weight: 300;
}
.tm-caps {
  text-transform: uppercase;
  letter-spacing: 2px;
}
.tm-caps > .uk-panel-title {
  font-size: 24px;
  letter-spacing: -0.5px;
  text-transform: uppercase;
  text-align: center;
  display: block;
  border-bottom: 0;
  margin-bottom: 30px;
}
.tm-small-caps {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 14px;
}
.tm-padding {
  padding: 30px;
}
.tm-padding.uk-panel-box {
  padding: 30px 60px;
}
.tm-background-cover .tm-inner-container {
  padding-top: 60px;
  padding-bottom: 60px;
}
.tm-inner-padding-large .tm-inner-container {
  padding-top: 90px;
  padding-bottom: 90px;
}
.tm-padding-large {
  padding-top: 60px;
  padding-bottom: 60px;
}
.tm-padding-sides {
  padding: 0 60px;
}
.tm-padding-small-sides {
  padding: 0 30px;
}
.tm-inner-shadow {
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.2);
}
p:empty {
  margin: 0;
}
.tm-padding-remove {
  padding: 0 !important;
}
.tm-padding-left-remove {
  padding-left: 0 !important;
}
.tm-overlay-slider:before,
.tm-overlay-primary:before,
.tm-overlay-secondary:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  padding: 0;
  top: 0;
  left: 0;
  display: block;
}
.tm-overlay-primary,
.tm-overlay-primary > div.tm-background-cover,
.tm-overlay-primary:before {
  background-color: rgba(37, 139, 206, 0.7);
}
.tm-overlay-secondary,
.tm-overlay-secondary > div.tm-background-cover,
.tm-overlay-secondary:before {
  background-color: rgba(34, 49, 72, 0.7);
}
.tm-overlay-primary > div.tm-background-cover,
.tm-overlay-secondary > div.tm-background-cover {
  background-blend-mode: hard-light;
}
@media (min-width: 900px) {
  .tm-margin-right-gutter-medium {
    margin-right: -30px;
  }
}
.tm-panel-box-teaser {
  margin: -30px !important;
}
.tm-panel-space-teaser {
  margin: -40px -40px 20px -40px;
}
.tm-panel-space-teaser:before,
.tm-panel-box-image-teaser:before {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  left: 0;
  z-index: 10;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (min-width: 1220px) {
  .tm-margin-right-gutter {
    margin-right: -40px;
  }
}
.tm-article-featured-image {
  position: relative;
  margin: 0 0 20px;
}
.tm-article-featured-image img {
  margin-top: 0;
  width: 100% !important;
}
.tm-article-content {
  margin-top: 20px;
}
.uk-article + .uk-article {
  padding-top: 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.tm-page-title {
  padding: 0 0 40px;
  margin-top: -20px;
}
.tm-dropcap-block > p:first-of-type:first-letter,
.tm-dropcap .tm-article > p:first-of-type:first-letter {
  margin: 10px 10px 0 0;
  float: left;
  line-height: 38px;
  font-size: 48px;
  color: #2a455d;
  font-family: 'Exo 2';
  font-weight: 500;
}
.tm-content > h2 {
  padding-bottom: 30px;
}
.tm-article-icons {
  border-bottom: 1px dashed rgba(42, 69, 93, 0.2);
  padding-bottom: 10px;
  margin: 0 0 10px;
  text-align: right;
}
.tm-article-icons a {
  margin-right: 20px;
}
.tm-article-icons .icon-print,
.tm-article-icons .icon-envelope {
  vertical-align: middle;
  padding-right: 5px;
  font-size: 15px;
}
.tm-article-icons .icon-print:before,
.tm-article-icons .icon-envelope:before {
  font-family: "fontAwesome";
}
.tm-article-icons .icon-print:before {
  content: "\f02f";
}
.tm-article-icons .icon-envelope:before {
  content: "\f003";
}
.category-module {
  list-style-type: none;
  padding-left: 0;
}
.contact h3 {
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: -0.5px;
  text-transform: none;
}
.contact h3:after {
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  position: absolute;
  border-bottom: 1px solid #258bce;
}
.contact h3.uk-text-right:after {
  left: auto;
  right: 0;
}
.contact .form-horizontal .controls {
  margin-right: 22px;
}
.contact .form-horizontal .control-group {
  margin-bottom: 10px;
}
.contact .form-horizontal .control-group .controls > input,
.contact .form-horizontal .control-group .controls > textarea {
  width: 100%;
}
.tm-error-icon {
  font-size: 250px;
}
.tm-error-headline {
  font-size: 100px;
}
.tm-offline {
  width: 300px;
}
.tm-socialbuttons {
  line-height: 1;
}
.tm-socialbuttons > div {
  margin-right: 10px;
  float: left;
}
.tm-price-column {
  box-shadow: 1px 1px 0 0 #eef4ff, inset 1px 0 #eef4ff;
}
.tm-price-column.tm-shift-top {
  margin-top: -20px;
}
.tm-price-column.tm-table-focus {
  z-index: 1;
}
.tm-price-column.tm-table-focus .tm-price-title,
.tm-price-column.tm-table-focus .tm-price-tag {
  background-color: #217cb8;
}
.tm-price-column .tm-price-bottom {
  position: relative;
  box-shadow: 0 2px 0 #eef4ff;
  display: table;
  width: 100%;
  color: #ffffff;
}
.tm-price-tag {
  padding: 10px 20px 15px;
  background-color: #258bce;
  border-bottom: 1px solid #2382c1;
  text-align: center;
}
.tm-price-tag h3 {
  color: #ffffff;
  margin: 0;
  font-family: 'Varela';
  font-weight: 400;
  font-size: 16px;
}
.tm-price-list {
  margin: 0;
  padding: 0;
}
.tm-price-list > li {
  list-style-type: none;
  padding: 0 30px;
  line-height: 40px;
  border-bottom: 1px solid #eef4ff;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.05);
}
.tm-price-list > li:nth-of-type(odd) {
  background-color: #f8fbff;
}
.tm-price-list > li:nth-of-type(odd) i {
  color: #bbd3ff;
}
.tm-price-list > li i {
  float: right;
  color: #bbd3ff;
  font-size: 18px;
  line-height: inherit;
}
.tm-price-title {
  padding: 15px 0 0;
  line-height: 32px;
  font-size: 32px;
  color: #ffffff;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.1)), color-stop(100%, rgba(0, 0, 0, 0))) #258bce;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0) 100%) #258bce;
  font-family: 'Exo 2';
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.5px;
  border-top: 1px solid #2382c1;
}
.tm-price-title sup {
  top: -1em;
}
.tm-price-title sup,
.tm-price-title sub {
  font-size: 24px;
}
.tm-price-bottom > * {
  margin: 25px;
}
@media (max-width: 899px) {
  .tm-price-column.tm-shift-top {
    margin-top: 0;
  }
}
.tm-rotate-text {
  position: relative;
  margin-bottom: 30px;
}
.tm-rotate-text > * {
  margin-bottom: 0;
}
.tm-rotate-text .tm-word {
  position: absolute;
  opacity: 0;
  top: 0;
  margin-left: 10px;
}
.tm-rotate-text .tm-letter {
  display: inline-block;
  position: relative;
  float: left;
  -webkit-transform: translateZ(20px);
  transform: translateZ(20px);
  -webkit-transform-origin: 50% 50% 20px;
  transform-origin: 50% 50% 20px;
}
.tm-rotate-text .tm-letter.out {
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: -webkit-transform 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.2s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.tm-rotate-text .tm-letter.behind {
  -webkit-transform: rotateX(-90deg);
  transform: rotateX(-90deg);
}
.tm-rotate-text .tm-letter.in {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@media (max-width: 900px) {
  .tm-rotate-text > h2 {
    font-size: 28px;
  }
}
.tm-overlay-1,
.tm-overlay-2,
.tm-overlay-3,
.tm-overlay-4,
.tm-overlay-5,
.tm-overlay-6,
.tm-overlay-7 {
  background-repeat: repeat;
  background-position: 0 0;
}
.tm-overlay-1 {
  background-image: url(../../images/patterns/1.png);
}
.tm-overlay-2 {
  background-image: url(../../images/patterns/2.png);
}
.tm-overlay-3 {
  background-image: url(../../images/patterns/3.png);
}
.tm-overlay-4 {
  background-image: url(../../images/patterns/4.png);
}
.tm-overlay-5 {
  background-image: url(../../images/patterns/5.png);
}
.tm-overlay-6 {
  background-image: url(../../images/patterns/6.png);
}
.tm-overlay-7 {
  background-image: url(../../images/patterns/7.png);
}
.tm-slideshow-sandal .uk-dotnav > *,
.tm-slideshow-panel-sandal .uk-dotnav > * {
  padding-left: 15px;
}
.tm-slideshow-sandal .uk-dotnav li a,
.tm-slideshow-panel-sandal .uk-dotnav li a {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 1px 1px 0 1px rgba(255, 255, 255, 0.15);
  border-radius: 1px;
  width: 13px;
  height: 13px;
  text-indent: -999em;
  padding: 1px;
  background-color: rgba(255, 255, 255, 0.15);
}
.tm-slideshow-sandal .uk-dotnav > .uk-active > *,
.tm-slideshow-panel-sandal .uk-dotnav > .uk-active > * {
  box-shadow: 0 0 0 2px #258bce;
  background-color: rgba(255, 255, 255, 0.15);
  transform: scale(1);
  width: 17px;
  height: 17px;
  margin-top: -2px;
}
.tm-slideshow-panel-sandal .uk-slidenav,
.tm-slideshow-sandal .uk-slidenav {
  top: 50%;
  bottom: 0;
  font-size: 30px;
  margin-top: -40px;
  background-color: rgba(255, 255, 255, 0.5);
  width: 60px;
  height: 80px;
  line-height: 75px;
  z-index: 0;
  overflow: hidden;
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.05), inset 1px 0 0 rgba(255, 255, 255, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.tm-slideshow-panel-sandal .uk-slidenav-next:before,
.tm-slideshow-sandal .uk-slidenav-next:before,
.tm-slideshow-panel-sandal .uk-slidenav-previous:before,
.tm-slideshow-sandal .uk-slidenav-previous:before {
  color: #1e435a;
  font-family: "fontAwesome";
  font-size: 40px;
  display: block;
}
.tm-slideshow-panel-sandal .uk-slidenav:hover,
.tm-slideshow-sandal .uk-slidenav:hover {
  background-color: #ffffff;
}
.tm-slideshow-panel-sandal .uk-slidenav:hover:before,
.tm-slideshow-sandal .uk-slidenav:hover:before {
  color: #258bce;
}
.tm-slideshow-panel-sandal .uk-slidenav-next,
.tm-slideshow-sandal .uk-slidenav-next {
  right: 0;
}
.tm-slideshow-panel-sandal .uk-slidenav-next:hover:before,
.tm-slideshow-sandal .uk-slidenav-next:hover:before {
  -webkit-animation: toRightFromLeft 0.3s forwards;
  -moz-animation: toRightFromLeft 0.3s forwards;
  animation: toRightFromLeft 0.3s forwards;
}
.tm-slideshow-panel-sandal .uk-slidenav-previous,
.tm-slideshow-sandal .uk-slidenav-previous {
  left: 0;
}
.tm-slideshow-panel-sandal .uk-slidenav-previous:hover:before,
.tm-slideshow-sandal .uk-slidenav-previous:hover:before {
  -webkit-animation: toLeftFromRight 0.3s forwards;
  -moz-animation: toLeftFromRight 0.3s forwards;
  animation: toLeftFromRight 0.3s forwards;
}
.tm-slideshow-panel-sandal .uk-slidenav-previous:before,
.tm-slideshow-sandal .uk-slidenav-previous:before {
  content: "\f104";
}
.tm-slideshow-panel-sandal .uk-slidenav-next:before,
.tm-slideshow-sandal .uk-slidenav-next:before {
  content: "\f105";
}
.tm-slideshow-sandal .uk-slidenav {
  width: 80px;
  -webkit-transform: skew(-10deg);
  -moz-transform: skew(-10deg);
  -o-transform: skew(-10deg);
}
.tm-slideshow-sandal .uk-slidenav-next:before,
.tm-slideshow-sandal .uk-slidenav-previous:before {
  -webkit-transform: skew(10deg);
  -moz-transform: skew(10deg);
  -o-transform: skew(10deg);
}
.tm-slideshow-sandal .uk-slidenav-next {
  right: -20px;
}
.tm-slideshow-sandal .uk-slidenav-next:before {
  text-indent: -15px;
}
.tm-slideshow-sandal .uk-slidenav-previous {
  left: -20px;
}
.tm-slideshow-sandal .uk-slidenav-previous:before {
  text-indent: 15px;
}
.tm-slideshow-scroller .uk-dotnav,
.tm-slideshow-default .uk-dotnav {
  margin: 10px;
}
.tm-slideshow-scroller .uk-dotnav > * > *,
.tm-slideshow-default .uk-dotnav > * > * {
  border-radius: 10px;
}
.tm-slideshow-scroller .uk-overlay-panel,
.tm-slideshow-default .uk-overlay-panel {
  background-color: rgba(0, 0, 0, 0.3);
}
.tm-slideshow-scroller .uk-slidenav,
.tm-slideshow-default .uk-slidenav {
  display: block;
  font-size: 32px;
  z-index: 0;
}
.tm-slideshow-scroller .uk-slidenav:before,
.tm-slideshow-default .uk-slidenav:before {
  font-size: 50px;
}
.tm-slideshow-scroller .uk-dotnav,
.tm-slideshow-default .uk-dotnav {
  margin-bottom: 20px;
}
.tm-slideshow-scroller .uk-dotnav > *,
.tm-slideshow-default .uk-dotnav > * {
  padding-left: 15px;
}
.tm-slideshow-scroller .uk-dotnav li a,
.tm-slideshow-default .uk-dotnav li a {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background: rgba(0, 0, 0, 0);
  width: 10px;
  height: 10px;
  padding: 1px;
  text-indent: -999em;
}
.tm-slideshow-scroller .uk-dotnav > .uk-active > *,
.tm-slideshow-default .uk-dotnav > .uk-active > * {
  box-shadow: 0 0 0 3px #258bce;
  background-color: rgba(0, 0, 0, 0);
}
.tm-slideshow-scroller .uk-slidenav,
.tm-slideshow-default .uk-slidenav {
  top: 45%;
  bottom: 0;
  font-size: 30px;
  display: inline-block;
  width: 50px;
  height: 80px;
  line-height: 75px;
  z-index: 0;
}
.tm-slideshow-scroller .uk-slidenav-next:before,
.tm-slideshow-default .uk-slidenav-next:before,
.tm-slideshow-scroller .uk-slidenav-previous:before,
.tm-slideshow-default .uk-slidenav-previous:before {
  color: #1e435a;
  font-family: "fontAwesome";
  font-size: 30px;
}
.tm-slideshow-scroller .uk-slidenav-next,
.tm-slideshow-default .uk-slidenav-next {
  right: 0;
}
.tm-slideshow-scroller .uk-slidenav-previous,
.tm-slideshow-default .uk-slidenav-previous {
  left: 0;
}
.tm-slideshow-scroller .uk-slidenav-previous:before,
.tm-slideshow-default .uk-slidenav-previous:before {
  content: "\f104";
}
.tm-slideshow-scroller .uk-slidenav-next:before,
.tm-slideshow-default .uk-slidenav-next:before {
  content: "\f105";
}
.tm-slideshow-scroller .tm-testimonial,
.tm-slideshow-default .tm-testimonial {
  margin: 0 45px;
  padding: 0 15px;
  border-width: 0;
}
.tm-slideshow-scroller .tm-testimonial {
  margin: 0 100px 0 0;
}
.tm-slideshow-scroller .uk-slidenav {
  width: 35px;
  height: 35px;
  line-height: 28px;
  border: 1px solid #217cb8;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 3px;
}
.tm-slideshow-scroller .uk-slidenav-next:before,
.tm-slideshow-scroller .uk-slidenav-previous:before {
  color: #258bce;
  font-size: 22px;
}
.tm-slideshow-scroller .uk-slidenav-next {
  right: 0;
}
.tm-slideshow-scroller .uk-slidenav-previous {
  left: auto;
  right: 45px;
}
.tm-slideshow-sandal {
  overflow: hidden;
  background-color: #ffffff;
}
.tm-slideshow-sandal .uk-slideshow {
  height: 0;
  max-height: 600px;
  -webkit-transition: height 0.25s;
  -moz-transition: height 0.25s;
  transition: height 0.25s;
  overflow: hidden;
}
.tm-slideshow-sandal .uk-slideshow > li {
  max-height: 600px;
}
.tm-slideshow-sandal .uk-slideshow > li > canvas {
  max-height: 600px;
}
.tm-slideshow-sandal .uk-slideshow-fullscreen {
  max-height: 100%;
  height: 100vh;
}
.tm-slideshow-sandal .uk-slideshow-fullscreen li {
  max-height: 100%;
}
.tm-slideshow-sandal .uk-slideshow-fullscreen li:after {
  height: 100%;
}
.tm-slideshow-sandal > div.uk-margin {
  position: absolute;
  left: 45%;
  bottom: 0;
}
.tm-slideshow-sandal .uk-button {
  margin-right: 20px;
}
.tm-slideshow-sandal .uk-overlay-panel {
  box-sizing: border-box;
  max-width: 1300px;
  padding: 0 30px;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0;
}
.tm-slideshow-sandal .uk-overlay-panel h1,
.tm-slideshow-sandal .uk-overlay-panel h2,
.tm-slideshow-sandal .uk-overlay-panel h3,
.tm-slideshow-sandal .uk-overlay-panel h4,
.tm-slideshow-sandal .uk-overlay-panel h5,
.tm-slideshow-sandal .uk-overlay-panel h6 {
  color: #1e435a;
  font-weight: 500;
}
.tm-slideshow-sandal .uk-overlay-panel h1:not([class]),
.tm-slideshow-sandal .uk-overlay-panel h2:not([class]),
.tm-slideshow-sandal .uk-overlay-panel h3:not([class]) {
  font-size: 38px;
  line-height: 55px;
}
.tm-slideshow-sandal .uk-overlay-panel.uk-contrast div > * {
  color: #ffffff !important;
}
.tm-slideshow-sandal .uk-overlay-panel > div {
  display: block;
  width: 50%;
}
.tm-slideshow-sandal .uk-overlay-panel > div .uk-button {
  float: left;
}
.tm-slideshow-sandal .uk-overlay-panel > div > div {
  text-align: left;
  font-size: 18px;
  line-height: 34px;
  color: #1e435a;
  margin-bottom: 30px;
}
.tm-slideshow-sandal li.uk-active .uk-overlay-panel > div > * {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: uk-fade-top;
  animation-name: uk-fade-top;
}
.tm-slideshow-sandal li.uk-active .uk-overlay-panel > div > *:nth-child(1) {
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.tm-slideshow-sandal li.uk-active .uk-overlay-panel > div > *:nth-child(2) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
.tm-slideshow-sandal li.uk-active .uk-overlay-panel > div > *:nth-child(3) {
  -webkit-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
.tm-scroll-arrow {
  height: 35px;
  width: 20px;
  position: absolute;
  left: 50%;
  bottom: 36px;
  display: block;
  border-radius: 15px;
  border: 2px solid #258bce;
  margin-left: -12px;
  z-index: 200;
}
.tm-scroll-arrow:before {
  content: "";
  display: block;
  width: 4px;
  height: 7px;
  margin: 7px auto 0;
  border-radius: 2px;
  background: #258bce;
  -webkit-animation: tm-floating 2s infinite;
  animation: tm-floating 2s infinite;
}
@media (min-width: 900px) {
  .tm-article .tm-slideshow-sandal .uk-overlay-panel,
  .tm-boxed-layout .tm-slideshow-sandal .uk-overlay-panel {
    padding-left: 100px;
  }
}
@media (max-width: 1220px) {
  .tm-slideshow-sandal .uk-overlay-panel {
    padding-left: 100px;
  }
}
.tm-slideshow-panel-sandal .uk-slidenav {
  width: 50px;
  height: 60px;
  line-height: 60px;
  top: auto;
  bottom: 0;
  background-color: #223148;
  box-shadow: none;
}
.tm-slideshow-panel-sandal .uk-slidenav-next:before,
.tm-slideshow-panel-sandal .uk-slidenav-previous:before {
  font-size: 32px;
  color: #ffffff;
}
.tm-slideshow-panel-sandal .uk-slidenav-next {
  right: 0;
}
.tm-slideshow-panel-sandal .uk-slidenav-previous {
  left: auto;
  right: 50px;
}
.tm-slideset-sandal .uk-subnav {
  margin-bottom: 50px;
}
.tm-slideset-sandal .uk-subnav li {
  padding: 0;
  margin: 0 10px;
}
.tm-slideset-sandal .uk-subnav li a {
  padding: 0px 10px 2px;
  box-shadow: inset 0 -2px 0 #dfeaff, inset 0 2px 0 #dfeaff;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-slideset-sandal .uk-subnav li a:hover {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: inset 0 -2px 0 #258bce;
}
.tm-slideset-sandal .uk-subnav li::before {
  content: "";
  position: absolute;
  width: 0;
  left: 0;
  bottom: 0;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  border-bottom: 2px solid rgba(0, 0, 0, 0);
}
.tm-slideset-sandal .uk-subnav li.uk-active a {
  border-radius: 3px;
  background-color: #258bce;
  color: #ffffff;
  box-shadow: none;
}
.tm-slideset-sandal .uk-subnav li.uk-active a::before {
  box-shadow: none;
}
.tm-slideset-sandal .uk-slideset {
  margin-bottom: 50px;
  padding: 0 80px;
}
.tm-slideset-sandal .uk-slideset .uk-panel-box {
  padding: 30px;
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15);
}
.tm-slideset-sandal .uk-slideset .uk-panel-box .uk-panel-teaser {
  margin: -30px -30px 0 -30px;
}
.tm-slideset-sandal .uk-slideset .uk-panel-box .uk-panel-teaser .uk-overlay {
  width: 100%;
}
.tm-slideset-sandal .uk-slideset .uk-panel-box .uk-panel-title {
  display: block;
  color: #ffffff;
  margin-left: -30px;
  margin-right: -30px;
  padding: 15px 30px 10px 30px;
  font-family: 'Exo 2';
  font-size: 24px;
  text-transform: capitalize;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.1))) #258bce;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%) #258bce;
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%) #258bce;
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%) #258bce;
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%) #258bce;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%) #258bce;
}
.tm-slideset-sandal .uk-slideset .uk-panel-box .uk-panel-title + div.uk-margin {
  position: relative;
}
.tm-slideset-sandal .uk-slideset .uk-panel-box .uk-panel-title:after {
  border-bottom: 0;
  content: "";
}
.tm-slideset-sandal .uk-slidenav-position .uk-slidenav-previous {
  left: 0;
}
.tm-slideset-sandal .uk-slidenav-position .uk-slidenav-next {
  right: 0;
}
.tm-slideset-sandal .uk-slideset-nav {
  padding-bottom: 10px;
}
.tm-slideset-sandal .uk-slideset-nav li a {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.15);
  width: 10px;
  height: 10px;
  margin-right: 5px;
  padding: 1px;
}
.tm-slideset-sandal .uk-slideset-nav > .uk-active > * {
  box-shadow: 0 0 0 3px #258bce;
  background-color: rgba(0, 0, 0, 0);
}
.tm-inner-shadow .tm-slideset-sandal .uk-panel-box {
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.15), 10px 10px 20px rgba(0, 0, 0, 0.2);
}
.tm-inner-shadow .uk-slidenav {
  color: #ffffff;
}
@media (max-width: 900px) {
  .tm-slideshow-sandal .uk-overlay-panel h1,
  .tm-slideshow-sandal .uk-overlay-panel h2,
  .tm-slideshow-sandal .uk-overlay-panel h3,
  .tm-slideshow-sandal .uk-overlay-panel h4,
  .tm-slideshow-sandal .uk-overlay-panel h5,
  .tm-slideshow-sandal .uk-overlay-panel h6 {
    font-size: 30px;
    line-height: 40px;
  }
  .tm-slideshow-sandal .uk-overlay-panel > div > div,
  .tm-slideshow-sandal .uk-overlay-panel div p {
    display: none;
  }
  .tm-slideshow-sandal .uk-slidenav {
    display: none !important;
  }
  .tm-slideshow-sandal .caption {
    font-size: 15px;
    line-height: 22px;
  }
  .tm-slideshow-sandal .tm-testimonial {
    padding: 0;
    margin: 0 10px;
  }
  .tm-slideset-sandal .uk-slideset {
    padding: 0;
  }
}
@media (max-width: 899px) {
  body.uk-container {
    padding: 0;
  }
  .tm-toolbar .uk-subnav > * {
    padding-left: 10px;
  }
  .tm-toolbar > div.uk-float-right > div {
    margin-left: 10px;
  }
  .tm-quote {
    line-height: 35px;
  }
  .uk-module-title-alt:before,
  .uk-module-title-alt:after {
    width: 20px;
    bottom: 15px;
  }
  .uk-module-title-alt:after {
    right: -20px;
  }
  .uk-module-title-alt:before {
    left: -20px;
  }
  .uk-panel-space {
    padding: 20px;
  }
  #tm-header-a {
    margin-top: 0;
  }
  #tm-header-a h1 {
    font-size: 32px;
  }
  #tm-header-a .tm-header-height > div,
  #tm-header-a .tm-header-small-height > div,
  #tm-header-a .tm-header-large-height > div,
  #tm-header-a .tm-header-full-height > div,
  #tm-header-a .tm-header-xlarge-height > div {
    padding-top: 0;
  }
  .tm-slideshow-sandal .uk-overlay-panel {
    padding: 0;
  }
  .tm-slideshow-sandal .uk-overlay-panel > div {
    width: 100%;
    text-align: center;
  }
  .tm-slideshow-sandal .uk-overlay-panel > div .uk-button {
    float: none;
    margin: 0 auto;
  }
  .tm-slideshow-sandal .uk-overlay-panel h1:not([class]),
  .tm-slideshow-sandal .uk-overlay-panel h2:not([class]),
  .tm-slideshow-sandal .uk-overlay-panel h3:not([class]),
  .tm-slideshow-sandal .uk-overlay-panel h1,
  .tm-slideshow-sandal .uk-overlay-panel h2,
  .tm-slideshow-sandal .uk-overlay-panel h3,
  .tm-slideshow-sandal .uk-overlay-panel h4,
  .tm-slideshow-sandal .uk-overlay-panel h5,
  .tm-slideshow-sandal .uk-overlay-panel h6 {
    font-size: 25px;
    line-height: 26px;
    padding: 10px 20px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.75);
  }
  .tm-slideshow-sandal .uk-dotnav {
    display: none;
  }
  .tm-slideshow-sandal > div.uk-margin {
    left: 50%;
    margin-left: -40px;
    right: auto;
    bottom: 10px;
  }
  .tm-slideshow-sandal .tm-scroll-arrow {
    display: none;
  }
}
@media (min-width: 900px) and (max-width: 960px) {
  .uk-navbar-nav > li > a {
    padding: 0 20px;
  }
}
@media (min-width: 900px) and (max-width: 959px) {
  .uk-navbar-nav > li > a {
    padding: 0 20px;
  }
}
@media (max-width: 899px) {
  .uk-panel-box,
  .tm-block {
    padding: 30px;
  }
  .tm-navbar {
    background: #ffffff;
  }
  .tm-toolbar {
    padding-right: 20px;
    padding-left: 20px;
  }
  .tm-padding-top-remove-small {
    padding-top: 0 !important;
  }
  .tm-margin-bottom-small {
    margin-bottom: 40px !important;
  }
  .uk-nav-offcanvas .uk-nav-sub li {
    line-height: 32px;
  }
}
@media (max-width: 479px) {
  .tm-article-content p {
    clear: both;
  }
}
svg.uk-circlechart {
  display: block;
  margin: auto;
}
svg.uk-circlechart path {
  fill: #258bce;
  stroke: #2382c1;
}
svg.uk-circlechart circle {
  fill: #ffffff;
  stroke: #eef4ff;
}
svg.uk-circlechart text {
  font-weight: bold;
  font-size: 30px;
  fill: #2a455d;
  dominant-baseline: central;
  text-anchor: middle;
}
.tm-chart-success svg.uk-circlechart path {
  fill: #84c118;
  stroke: #7bb316;
}
.tm-chart-warning svg.uk-circlechart path {
  fill: #ff9926;
  stroke: #ff9217;
}
.tm-chart-danger svg.uk-circlechart path {
  fill: #da314b;
  stroke: #d52641;
}
.tm-counter {
  margin: 30px 0;
}
.tm-counter-icon {
  font-size: 55px;
  padding-right: 30px;
}
.tm-counter-icon i {
  color: #258bce;
}
.uk-contrast .tm-counter-icon i {
  color: #ffffff;
}
.tm-counter-content .tm-counter-number {
  font-size: 40px;
  font-weight: 300;
  margin: 0;
}
.tm-counter-percent:after {
  content: "%";
}
.tm-counter-title {
  font-size: 18px;
  margin: 10px 0 0;
  color: rgba(42, 69, 93, 0.5);
}
#tm-timeline {
  position: relative;
  padding: 0 0 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}
#tm-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #eef4ff;
}
.tm-timeline-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #258bce;
  background-color: #ffffff;
  border: 1px solid #258bce;
  text-align: center;
  font-size: 22px;
  line-height: 40px;
}
.tm-timeline-icon:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background: #258bce;
  border-radius: 50%;
  left: 5px;
  top: 5px;
}
.tm-timeline-icon:after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid #258bce;
  left: -1px;
  top: -1px;
  -webkit-animation: pulsate 2.5s ease-out infinite;
  animation: pulsate 2.5s ease-out infinite;
  opacity: 0;
}
.tm-timeline-block {
  position: relative;
  margin: 2em 0;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
}
.tm-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.tm-timeline-block:first-child {
  margin-top: 0;
}
.tm-timeline-block:last-child {
  margin-bottom: 0;
}
.tm-timeline-img {
  margin: -20px -20px 20px;
  border-radius: 3px 3px 0 0;
}
.tm-timeline-content {
  position: relative;
  margin-left: 60px;
  border-radius: 3px;
  padding: 20px 20px;
}
.tm-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.tm-timeline-content::before {
  content: '';
  position: absolute;
  top: 15px;
  right: 100%;
  height: 0;
  width: 25%;
  z-index: -1;
  border: 1px solid #eef4ff;
}
.tm-timeline-content .uk-module-title {
  margin-top: 20px;
}
.tm-timeline-content p {
  margin: 0;
}
.tm-timeline-content p + a {
  margin-top: 20px;
}
.tm-timeline-content .tm-timeline-date {
  display: inline-block;
  padding: 0 10px;
  float: right;
  font-weight: bold;
  border-radius: 0 0 3px 3px;
  border-width: 0 1px 1px;
  margin-top: -20px;
  border-style: solid;
  background-color: #2382c1;
  border-color: #2382c1;
  color: #ffffff;
}
.tm-timeline-default .tm-timeline-content {
  background-color: #ffffff;
  box-shadow: 0 0 0 1px #d5e3ff, inset 1px 1px 0 rgba(255, 255, 255, 0.05);
}
.tm-timeline-light .tm-timeline-content {
  background-color: #eef4ff;
  box-shadow: 0 0 0 1px #d5e3ff, inset 1px 1px 0 rgba(255, 255, 255, 0.05);
}
.tm-timeline-light .tm-timeline-content h1.uk-module-title:after,
.tm-timeline-light .tm-timeline-content h2.uk-module-title:after,
.tm-timeline-light .tm-timeline-content h3.uk-module-title:after,
.tm-timeline-light .tm-timeline-content h4.uk-module-title:after,
.tm-timeline-light .tm-timeline-content h5.uk-module-title:after,
.tm-timeline-light .tm-timeline-content h6.uk-module-title:after {
  border-bottom-color: inherit;
}
.tm-timeline-primary .tm-timeline-content {
  color: #ffffff;
  background-color: #258bce;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.tm-timeline-primary .tm-timeline-content h1,
.tm-timeline-primary .tm-timeline-content h2,
.tm-timeline-primary .tm-timeline-content h3,
.tm-timeline-primary .tm-timeline-content h4,
.tm-timeline-primary .tm-timeline-content h5,
.tm-timeline-primary .tm-timeline-content h6 {
  color: #ffffff;
}
.tm-timeline-primary .tm-timeline-content h1.uk-module-title:after,
.tm-timeline-primary .tm-timeline-content h2.uk-module-title:after,
.tm-timeline-primary .tm-timeline-content h3.uk-module-title:after,
.tm-timeline-primary .tm-timeline-content h4.uk-module-title:after,
.tm-timeline-primary .tm-timeline-content h5.uk-module-title:after,
.tm-timeline-primary .tm-timeline-content h6.uk-module-title:after {
  border-bottom-color: #ffffff;
}
.tm-timeline-primary .tm-timeline-content .tm-timeline-date {
  background-color: #223148;
  border-color: #1f2c41;
  color: #ffffff;
}
.tm-timeline-primary .tm-timeline-content .uk-button.uk-button-simple {
  color: #ffffff;
  border-color: #ffffff;
}
.tm-timeline-secondary .tm-timeline-content {
  background-color: #223148;
  color: #ffffff;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
}
.tm-timeline-secondary .tm-timeline-content h1,
.tm-timeline-secondary .tm-timeline-content h2,
.tm-timeline-secondary .tm-timeline-content h3,
.tm-timeline-secondary .tm-timeline-content h4,
.tm-timeline-secondary .tm-timeline-content h5,
.tm-timeline-secondary .tm-timeline-content h6 {
  color: #ffffff;
}
@media only screen and (max-width: 900px) {
  .tm-timeline-content h2 {
    font-size: 20px;
  }
  .tm-timeline-content .tm-timeline-date {
    font-size: 14px;
  }
  .tm-timeline-content:before {
    width: 40px;
  }
}
@media only screen and (min-width: 960px) {
  #tm-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #tm-timeline::before {
    left: 50%;
  }
  .tm-timeline-block {
    margin: 4em 0;
  }
  .tm-timeline-block:first-child {
    margin-top: 0;
  }
  .tm-timeline-block:last-child {
    margin-bottom: 0;
  }
  .tm-timeline-block:not(:first-child) {
    margin-top: -100px !important;
  }
  .tm-timeline-block:nth-child(even) .tm-timeline-content {
    float: right;
  }
  .tm-timeline-block:nth-child(even) .tm-timeline-content::before {
    left: auto;
    right: 98%;
  }
  .tm-timeline-block:nth-child(even) .tm-timeline-content .tm-timeline-date {
    left: auto;
    right: 6%;
    text-align: right;
  }
  .tm-timeline-img {
    margin: -20px -30px 30px;
  }
  .tm-timeline-icon {
    width: 30px;
    height: 30px;
    left: 50.5%;
    font-size: 22px;
    margin-left: -20px;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .tm-timeline-content {
    margin-left: 0;
    padding: 20px 30px;
    width: 35%;
  }
  .tm-timeline-content::before {
    top: 15px;
    left: 98%;
  }
  .tm-timeline-content .tm-timeline-date {
    margin-top: -20px;
  }
}
#calendar .fc-event {
  background-color: #258bce;
  border-color: #217cb8;
}
#calendar .fc-event,
#calendar .fc-event:hover,
#calendar .ui-widget .fc-event {
  color: #ffffff;
}
#calendar .fc-day-header {
  background-color: #eef4ff;
  color: #2a455d;
  font-weight: 400;
}
#calendar.fc-unthemed .fc-divider,
#calendar.fc-unthemed .fc-popover,
#calendar.fc-unthemed .fc-row,
#calendar.fc-unthemed tbody,
#calendar.fc-unthemed td,
#calendar.fc-unthemed th,
#calendar.fc-unthemed thead {
  border-color: #eef4ff;
}
#calendar.fc-unthemed .fc-today {
  background-color: rgba(37, 139, 206, 0.05);
}
#script-warning {
  display: none;
  padding: 0 10px;
  line-height: 40px;
  text-align: center;
}
#loading {
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
}
.plyr {
  font-family: 'Varela';
}
.plyr video {
  cursor: pointer;
}
.plyr__video-wrapper:before {
  content: "\f04b";
  position: absolute;
  font-family: "fontAwesome";
  width: 60px;
  height: 60px;
  background: #258bce;
  left: 50%;
  top: 50%;
  color: #ffffff;
  margin-left: -30px;
  margin-top: -20px;
  border-radius: 50%;
  font-size: 26px;
  line-height: 60px;
  z-index: 0;
  text-align: center;
  text-indent: 5px;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1), 0 0 0 6px rgba(255, 255, 255, 0.25);
  -webkit-transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.plyr.plyr--playing .plyr__video-wrapper:before {
  opacity: 0;
}
.plyr__tooltip {
  color: #2a455d;
}
.plyr__controls {
  background: #223148;
  border-radius: 0 0 3px 3px;
}
.plyr__controls .plyr__time,
.plyr__controls button,
.plyr__controls button svg use {
  color: #ffffff;
}
.plyr__controls button svg {
  fill: #ffffff;
}
.plyr__controls button.tab-focus:focus,
.plyr__controls button:hover {
  background: #258bce;
}
.plyr--audio .plyr__progress {
  background: #121925;
  border-radius: 3px;
}
.plyr__progress--played[value] {
  color: #258bce;
}
.plyr__volume[type=range]:focus::-webkit-slider-thumb {
  background: #258bce;
}
.plyr__volume[type=range]:focus::-moz-range-thumb {
  background: #258bce;
}
.plyr__volume[type=range]:focus::-ms-thumb {
  background: #258bce;
}
.plyr__volume[type=range]::-webkit-slider-runnable-track {
  background: #121925;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.plyr__volume[type=range]::-moz-range-track {
  background: #121925;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.plyr__volume[type=range]::-ms-fill-lower,
.plyr__volume[type=range]::-ms-fill-upper {
  background: #121925;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
}
.tm-item-block ul {
  padding-left: 0;
}
.tm-item-block ul li {
  list-style: none;
  margin: 0 0 12px;
  padding: 12px 20px 12px 0;
}
.tm-item-block ul li a {
  font-size: 18px;
  line-height: 22px;
}
.tm-shop .tm-shop-img-responsive {
  max-width: 100%;
  height: auto;
}
.tm-shop .tm-product {
  text-align: center;
}
.tm-shop .tm-product-title {
  text-align: center;
  font-size: 18px;
  line-height: 32px;
  margin: 10px 0 10px;
}
.tm-shop .tm-product-title a {
  color: #2a455d;
}
.tm-shop .tm-product-img {
  position: relative;
  border: 1px solid #eef4ff;
  border-radius: 5px;
}
.tm-shop .tm-product-img > a {
  display: block;
  border-radius: 4px;
  transition: all cubic-bezier(0.62, 0.28, 0.23, 0.99) 250ms;
}
.tm-shop .tm-product-img > a:hover {
  background-color: #eef4ff;
  box-shadow: 0 0 0 1px #e4edff;
}
.tm-shop .tm-product-img span.onsale {
  min-height: 32px;
  min-width: 32px;
  padding: 4px;
  position: absolute;
  text-align: center;
  line-height: 32px;
  margin: 0;
  z-index: 5;
  top: 0;
  left: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: #ffffff;
  background: #258bce;
  font-size: 11px;
  font-weight: normal;
  margin: -16px;
}
.tm-shop .tm-product-img span.onsale.large-onsale {
  padding: 10px;
  font-size: 14px;
}
.tm-shop del.tm-base-price {
  display: inline-block;
  margin-right: 10px;
}
.tm-shop .tm-sale-price {
  font-size: 18px;
  margin-bottom: 10px;
  display: inline-block;
}
.tm-shop .tm-add-to-cart {
  margin-left: 5px;
  display: inline;
}
.tm-shop .tm-add-to-cart .tm-product-qty {
  display: inline;
}
.tm-shop .tm-add-to-cart .tm-product-qty input[type="text"],
.tm-shop .tm-add-to-cart .tm-product-qty input[type="number"] {
  display: inline;
  height: 100%;
  padding: 7px !important;
  width: 40px !important;
  text-align: center;
  margin: 0px;
}
.tm-shop .tm-cart-table {
  margin-top: 20px;
}
.tm-shop .cart-thumb-image img {
  width: 80px;
  float: left;
  margin-right: 10px;
}
.tm-remove-item-button {
  color: #258bce;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}
.tm-remove-item-button i:before {
  font-size: 18px;
  margin-left: 0;
}
.cart-footer th,
.cart-footer td {
  text-align: right;
}
.tm-shop-items-count,
.tm-shop-ordering select {
  font-size: 13px;
}
.tm-product-top-rated {
  list-style: none outside;
  padding: 0;
  margin: 0;
}
.tm-product-top-rated li {
  padding: 0 0 5px;
  font-size: 15px;
}
.tm-product-top-rated li a {
  display: block;
  padding-top: 10px;
  border-top: 1px solid #eef4ff;
}
.tm-product-top-rated li:first-child > a {
  border-top-width: 0;
}
.tm-product-top-rated-img {
  float: right;
  width: 60px;
  margin-left: 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.cart-order {
  border: none;
}
.cart-order thead {
  background-color: #ffffff;
  color: #2a455d;
}
.tm-review .tm-rating.uk-active a:hover i:before {
  content: "\f005";
}
/* ========================================================================
   Use this file to add custom less easily
 ========================================================================== */
